import { DatePipe, Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Subscription } from 'rxjs';
import { BudgetService } from 'src/app/_services/budget.service';
import { RateazioniService } from 'src/app/_services/rateazioni.service';
import { ToolsService } from 'src/app/_services/tools.service';
import { Filtro } from 'src/app/models/filtriModel';
import { Rateazioni, Budget } from 'src/app/models/rateazioniModel';

@Component({
  selector: 'app-nuovo-budget',
  templateUrl: './nuovo-budget.component.html',
  styleUrls: ['./nuovo-budget.component.scss']
})
export class NuovoBudgetComponent implements OnInit, OnDestroy {

  location!: Location;
  loading = false;
  budgetStanziato: number | undefined;
  totaleSelezionate: number = 0;
  rateSelezionate: Rateazioni[] = [];
  date = [new Date(), new Date()];
  dtFormat = 'dd/MM/yyyy';

  totalPage = 0;
  index_page = 0;
  rowNumber = 10;
  public filtro: Filtro = {
    cartellaFilter: {
      codiceCartella: null,
      codiceEnte: null,
      codiceRata: null,
      dataScadenza: null,
      idStatoCarella: 0,
      nomeEnte: null,
      order: null,
      page: 0,
      rowNumber: 10,
      tipoCartella: null,
      tipoRata: null,
    },
    enteFilter: {
      codice: null,
      nome: null,
      sede: null,
      page: 0,
      rowNumber: 10,
    },
    idAzienda: 1,
    rateazioneFilter: {
      codiceCartella: null,
      codiceEnte: null,
      codiceRata: null,
      dataPagamento: null,
      nomeEnte: null,
      numeroRata: 0,
      order: null,
      page: 0,
      rowNumber: 10,
      sedeEnte: null,
      tipoCartella: null,
      tipoRata: null,
      idStatoCartella: null,
      dataScadenza: null,
      pagato: null,
      scaduta: null,
    },
    avvisoFilter: {
      nomeEnte: null,
      codiceEnte: null,
      stato: null,
      dataScadenza: null,
      order: null,
      page: 0,
      rowNumber: 10,
    },
    budgetFilter: {
      dataInizio: null,
      dataFine: null,
      order: null,
      page: 0,
      rowNumber: 10,
    },
  };

  listaRateazioni: any = [];

  // subscriptions
  reateazioneInitSub?: Subscription;
  rateazioneSearchSub?: Subscription;
  saveSub?: Subscription;

  constructor(
    private rateazioniService: RateazioniService,
    private budgetService: BudgetService,
    private datepipe: DatePipe,
    private message: NzMessageService,
    private toolsService: ToolsService,
  ) { }

  async ngOnInit() {
    // this.searchRateazioni();
  }

  onQueryParamsChange(e: NzTableQueryParams) {
    //this.loading = true;
    this.filtro.rateazioneFilter.page = e.pageIndex - 1;
    if (this.filtro.rateazioneFilter.page == -1) {
      this.filtro.rateazioneFilter.page = 0;
    }
    setTimeout(() => {
    }, 1000);
    this.searchRateazioni();
  }

  searchRateazioni() {
    this.rateazioneSearchSub = this.rateazioniService.getRateazioneSearch(this.filtro).subscribe({
      next: (data) => {
        if (data) {
          console.log(data);
          this.loading = false;
          
          this.listaRateazioni = data;
          this.totalPage = this.listaRateazioni.numberPage;
          this.index_page = this.listaRateazioni.filter.rateazioneFilter.page! + 1;
          this.rowNumber = this.listaRateazioni.filter.rateazioneFilter.rowNumber;

          this.listaRateazioni = data;
          // this.selectedCodiceRata = this.listaRateazioni.filter.rateazioneFilter.codiceRata;
          // this.selectedTipoRata = this.listaRateazioni.filter.rateazioneFilter.tipoRata;
          // this.selectedNumeroRata = this.listaRateazioni.filter.rateazioneFilter.numeroRata;
          // this.selectedDataScadenza = this.listaRateazioni.filter.rateazioneFilter.dataScadenza;
          // this.selectedCodiceCartella = this.listaRateazioni.filter.rateazioneFilter.codiceCartella;
          // this.selectedTipoCartella = this.listaRateazioni.filter.rateazioneFilter.tipoCartella;
          // this.selectedStato = this.listaRateazioni.filter.rateazioneFilter.idStatoCartella;
          // this.selectedDataEffettivoPagamento = this.listaRateazioni.filter.rateazioneFilter.dataPagamento;
          // this.selectedCodiceEnte = this.listaRateazioni.filter.rateazioneFilter.codiceEnte;
          // this.selectedNomeEnte = this.listaRateazioni.filter.rateazioneFilter.nomeEnte;
          // this.selectedSedeEnte = this.listaRateazioni.filter.rateazioneFilter.sedeEnte;
          // this.orderBy = this.listaRateazioni.filter.rateazioneFilter.order;          
          this.filtro = this.listaRateazioni.filter;
          
          this.totalPage = this.listaRateazioni.numberPage;
          this.index_page = this.listaRateazioni.filter.rateazioneFilter.page! + 1;
          this.rowNumber = this.listaRateazioni.filter.rateazioneFilter.rowNumber;
        }
      },
      error: (err) => console.error(err),
      complete: () => {
        //this.loading = false;
      }
    });
  }

  
  // cercaFiltri() {
  //   this.filtro.budgetFilter.page = 0;
  //   this.filtro.budgetFilter.dataInizio
  //   this.filtro.budgetFilter.dataFine
  //   this.filtro.budgetFilter.order = this.orderBy;
  //   this.filtro.budgetFilter.rowNumber = this.rowNumber;
  //   this.searchRateazioni();
  // }

  onBack() {
    this.location.back();
  }


  onChange(result: Date[]): void {
    // todo: filtrare elenco per le date inizio fine
    console.log('onChange: ', result);
    this.date = result;
    // this.searchRateazioni();
  }

  selectRow(rataRiga: any){
    // const rata = this.listaRateazioni.results[index];
    // console.log(rata.selected);
    // console.log(rata.importo)
    // if(rata.selected) {
    //   this.totaleSelezionate += rata.importo;
    // } else {
    //   this.totaleSelezionate -= rata.importo;
    // }
    // console.log(this.totaleSelezionate);
    if(!this.isSelected(rataRiga)){
      this.rateSelezionate.push(rataRiga);
    } else {
      this.rateSelezionate = this.rateSelezionate.filter( (rataSelezionata) => rataRiga.idRata !== rataSelezionata.idRata )
    }
    this.calcolaTotale();
  }

  calcolaTotale() {
    this.totaleSelezionate = (<any[]> this.rateSelezionate).reduce((partialSum, rata) => {
      if(this.isSelected(rata)) {
        // console.log(rata.importo)
        return partialSum + rata.importo;
      } else {
        return partialSum;
      }
    }, 0);
    // console.log(this.totaleSelezionate)
  }

  isSelected(rata: Rateazioni) {
    // console.log(this.rateSelezionate);
    return !!this.rateSelezionate.find( (rataSel: Rateazioni) => rataSel.idRata === rata.idRata);
    // return this.rateSelezionate.includes(rata);
  }

  save(){
    if(!this.budgetStanziato){
      this.message.error('Inserire budget');
      return;
    }
    let dataInizio: string = this.datepipe.transform(this.date[0], this.dtFormat) || '';
    let dataFine: string = this.datepipe.transform(this.date[1], this.dtFormat) || '';
    let datiBudget: Budget = {
      dataInizio,
      dataFine,
      idAzienda: Number(sessionStorage.getItem("selectedStore")),
      idRate: this.rateSelezionate.map( (rateazione: Rateazioni) => rateazione.idRata),
      importoSelezionato: this.totaleSelezionate,
      importoStanziato: this.budgetStanziato!,
    }
    this.saveSub = this.budgetService.saveBudget(datiBudget).subscribe({
      next: (result) => { this.message.success('Salvataggio completato') },
      error: (error) => {},
      complete: () => {}
    })
  }

  ngOnDestroy(): void {
    this.reateazioneInitSub?.unsubscribe();
    this.rateazioneSearchSub?.unsubscribe();
    this.saveSub?.unsubscribe();
  }

  // resetFiltri() {
  //   this.toolsService.resetFilter(this.filterName).subscribe({
  //     next: (data) => {
  //       if (data) {
  //         //this.listaRateazioni = data;
  //         // this.getRateazioniInit();
  //         this.searchRateazioni();
  //       }
  //     },
  //     error: (err) => console.error(err),
  //     complete: () => {}
  // });
  // }
}
