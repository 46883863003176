<ng-container>
    <nz-collapse class="panel" [nzExpandIconPosition]="'right'">
        <nz-collapse-panel nzHeader="Rate Prioritarie">
            <nz-table #rate1 [nzData]="rate" [nzSize]="'small'">
                <thead>
                    <tr>
                        <th>Rata</th>
                        <th>Ente</th>
                        <th [nzWidth]="'80px'">N° Rata</th>
                        <th [nzWidth]="'100px'">Scadenza</th>
                        <th>Importo</th>
                        <th>Scaduto</th>
                        <th>Stato</th>

                    </tr>
                </thead>
                <tbody>

                    <tr *ngFor="let rata of rate" [routerLink]="['/rateazioni/dettaglio', {id:rata.idRata}]" class="{{rata?.colorRow}} ">
                        <td>{{rata.idRata}} <br> {{rata.tipoRata}}</td>
                        <td>{{rata.codiceEnte}} <br> {{rata.nomeEnte}} <br> {{rata.sedeEnte}}</td>
                        <td>{{rata.numeroRata}} di {{rata.numeroRate}} </td>
                        <td class="text-center text-lowercase">{{rata.scadenza}}
                            <div class="{{rata.colorScadenza}} dettaglioScadenza">{{rata.scadenzaLabel}}</div>
                            <td>{{rata.importo}} €</td>
                            <td>{{rata.scadute}} </td>
                            <td id="stato">{{rata.stato}}</td>
                    </tr>
                </tbody>
            </nz-table>


        </nz-collapse-panel>
    </nz-collapse>
</ng-container>