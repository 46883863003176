import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Customer } from '../../models/customersModel';

@Injectable({
    providedIn: 'root'
})
export class AnagraphicService {

    baseUrl: any = environment.apiPath;

    constructor(private http: HttpClient) {
    }

    getCustomers(): Observable<Customer[]> {
        return this.http.post<any>(this.baseUrl + '/anagrafica/listaAnagrafica', {});
    }

    getCustomerDetail(id: number): Observable<Customer> {
        return this.http.post<any>(this.baseUrl + '/anagrafica/getDetail', { tipo: 'CLIENTE', id: id, extended: true });
    }

    getNumberClients(): Observable<any> {
        return this.http.post<any>(this.baseUrl + '/anagrafica/countAnagrafica', { extended: true })
    }

    getRepresentative(idCustomer: number) {
        return this.http.post<any>(environment.apiPath + '/anagrafica/selectReferenti', { idSede: idCustomer })
    }

    // getSellingPointDetail(id: number): Observable<SellingPoint[]> {
    //     return this.http.post<any>(environment.apiPath + '/anagrafica/getDetail', { tipo: 'PUNTOVENDITA', id: id, extended: true });
    // }

}