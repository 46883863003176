import { Component, OnInit, OnDestroy, HostListener, DoCheck } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/_services/user.service';
import { User } from 'src/app/models/userModel';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, DoCheck {

    currentUser!: User;
    showToTopButton = false;
    topPosToStartShowing = 300;
    isDashboard = false;

    constructor(
        private router: Router,
        private userService: UserService,
    ) { }

    @HostListener('window:scroll')
    onScroll(): void {
      const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      if (scrollPosition >= this.topPosToStartShowing) {
        this.showToTopButton = true;
      } else {
        this.showToTopButton = false;
      }
    }

    ngOnInit() {
    }

    ngDoCheck() {
      if(this.userService.getUser() !== null){
        this.currentUser = this.userService.getUser();
      }
      const curRoute = this.router.url;
      if(curRoute.includes("dashboard")){
        document.body.classList.add("bg-gray");
        this.isDashboard = curRoute.includes("dashboard");
      }
    }

    onlogout(event: any){
      this.currentUser = event;
    }

    goToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }

    ngOnDestroy(): void {}

    logout() {
        this.userService.logout();
        this.router.navigate(['/login']);
    }
}
