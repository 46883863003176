import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Save } from '../models/entiModel';
import { Filtro, } from '../models/filtriModel';

@Injectable({
  providedIn: 'root'
})
export class EntiService {

  constructor(private http: HttpClient) { }

  
  getEnteInit() {
    return this.http.get<any>(environment.apiPath + '/ente/init')
      .pipe(map(results => {
        return results;
      }));
  }
  getEnteSearch(filtro:Filtro) {
    return this.http.post<any>(environment.apiPath + '/ente/search', filtro)
      .pipe(map(results => {
        return results;
      }));
  }
  getPopulateDropDown(filtro:Filtro) {
    return this.http.post<any>(environment.apiPath + '/ente/populate', filtro)
      .pipe(map(results => {
        return results;
      }));
  }
  getEnteSave(params:Save) {
    return this.http.post<any>(environment.apiPath + '/ente/save', params)
      .pipe(map(results => {
        return results;
      }));
  }
}
