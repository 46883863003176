<app-loader *ngIf="loading"></app-loader>
<nz-affix *ngIf="!loading" [nzOffsetTop]="60">
    <div class="row titleFilterHeader">
        <div class="col-12">
            <!--Page header: title, back, others actions-->
            <nz-page-header class="site-page-header px-2" (click)="onBack()" nzBackIcon nzTitle="Budget">
                <nz-page-header-extra>
                    <!--       <button nz-button nzType="primary" nzSize="large" [routerLink]="['/cartelle/nuova-cartella']">
            <i nz-icon nzType="plus" nzTheme="outline"></i>
            Nuova cartella
          </button>-->
                </nz-page-header-extra>
            </nz-page-header>
        </div>

        <div class="row mb-3 d-flex justify-content-center">

            <div class="col-8">
                <nz-range-picker [(ngModel)]="date" (ngModelChange)="onChange($event)"
                    [nzFormat]="dtFormat"></nz-range-picker>
            </div>
            <div class="col-2 justify-content-end">
                <button nz-button nzType="primary" nzSize="large" (click)="cercaFiltri()">
                    <i nz-icon nzType="search" nzTheme="outline"></i>
                    Cerca
                </button>
            </div>
            <div class="col-2 justify-content-end">
                <button nz-button nzType="default" nzSize="large" (click)="resetFiltri()">
                    Reset filtri
                </button>
            </div>
        </div>
    </div>
</nz-affix>

<div *ngIf="!loading" class="row w-100">
    <div class="col-12">
        <nz-table #budgetFilter [nzData]="listaBudget.results" [nzSize]="'small'" [nzFrontPagination]="false"
            [nzTotal]="totalPage" [nzPageIndex]="index_page" [nzPageSize]="1"
            (nzQueryParams)="onQueryParamsChange($event)">
            <thead>
                <tr>
                    <th>dataInizio</th>
                    <th>dataFine</th>
                    <th>importoSelezionato</th>
                    <th>importoStanziato</th>
                    <th>ratePagate</th>
                    <th>rateinserite</th>
                </tr>
            </thead>
            <tbody>
                <tr></tr>
                <tr 
                    *ngFor="let budget of budgetFilter.data let i = index"
                    [routerLink]="['/budget/dettaglio', { id: budget.idBudget }]"
                >
                    <td>{{ budget.dataInizio }}</td>
                    <td>{{ budget.dataFine }}</td>
                    <td>{{ budget.importoSelezionato }}</td>
                    <td>{{ budget.importoStanziato }}</td>
                    <td>{{ budget.ratePagate }}</td>
                    <td>{{ budget.rateinserite }}</td>
                </tr>
            </tbody>
        </nz-table>
    </div>
</div>
<ng-container>
</ng-container>


<!-- </div> -->