import { Location, formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { AvvisoService } from 'src/app/_services/avviso.service';
import { ToolsService } from 'src/app/_services/tools.service';
import { Avviso } from 'src/app/models/avvisoModel';
import { Allegato } from 'src/app/models/commonModel';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dettaglio-avviso',
  templateUrl: './dettaglio-avviso.component.html',
  styleUrls: ['./dettaglio-avviso.component.scss']
})
export class DettaglioAvvisoComponent implements OnInit, OnDestroy {
  isVisible = false;
  isOkLoading = false;
  dataInizioModal:any;
  noteModal:any;
  location!: Location;
  

  // dati avviso
  idAvviso: any;
  
  // responsabileProcedimento: any;
  // dataScadenza: any;
  // stato: any;

  // tipologia: any;
  // selectedEnte: any;
  // sedeEnte: any;
  // riferimentoAnno: any;
  // codice: any;
  // dataRicezione: any;
  // giorniScadenza: any;
  // importo: any;
  // note: any;
  // flagPagato = false;
  // dataPagamento: any;

  modelFile: any;
  modelFilePag: any;

  dettaglioAvviso = false;
  checkTipo = true;


  avviso: Avviso = {
    idAvviso: 0,
    idEnte: 0,
    codiceEnte: '',
    sedeEnte: '',
    codice: '',
    dataRicezione: '',
    giorniScadenza: 0,
    importo: 0,
    responsabileProcedimento: '',
    note: '',
    riferimentoAnno: '',
    tipologiaEnte: '',
    flagPagato: false,
    dataPagamento: '',
    dataScadenza: '',
    stato: '',
    idAdempimento: 0,
    dettagli: [],
    documenti: [],
    nomeEnte: '',
    documentiPagamento: [],
    editable: false
  }
  
  private detailSub: Subscription | undefined;
  private downdloadSub?: Subscription;
  private subConvertFile?: Subscription;
  private updateSub?: Subscription;
  private saveEventoSub?: Subscription;


  constructor(
    private avvisoService: AvvisoService,
    private route: ActivatedRoute,
    private router: Router,
    private toolsService: ToolsService,
    private message: NzMessageService,
    @Inject(LOCALE_ID) public locale: string
  ) { }

  ngOnInit(): void {
    this.detailSub = this.avvisoService.detail(this.route.snapshot.paramMap.get('id')).subscribe({
      next: (data: Avviso) => {
        if(data) {
          this.avviso = data;
          this.checkTipologia();
        }

      },
      error: (error) => { },
    });
  }

  // chiamato dopo aver recuperato il dettaglio
  checkTipologia(){
    if(this.avviso.tipologiaEnte === '1 - Ente Singolo'){
      this.checkTipo = true;
      this.dettaglioAvviso = false;
    }else{
      this.checkTipo = false;
      this.dettaglioAvviso = true;
    }
  }

  onBack(){
    this.location.back();
  }

  saveRateazione() {
    this.router.navigate(['/cartelle/nuova-cartella', { id: this.avviso.idAvviso }]);
  }

  showRateazione() {
    this.router.navigate(['/cartelle/dettaglio', { id: this.avviso.idAdempimento }])
  }

  aggiornaAvviso() {
    this.updateSub = this.avvisoService.update(this.avviso).subscribe({
      next: (data) => this.router.navigate(['/avvisi']),
    })
  }

  ngOnDestroy(): void {
    this.detailSub?.unsubscribe();
    this.downdloadSub?.unsubscribe();
    this.subConvertFile?.unsubscribe();
    this.updateSub?.unsubscribe();
    this.saveEventoSub?.unsubscribe();
  }

  
  convertFile(file : File) : Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => 
    result.next(btoa(event.target!.result!.toString()));
    return result;
  }
  

  handleFileInput(event: Event, tipo:any) {
    const element = event.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;
    if (fileList) {
      this.subConvertFile = this.convertFile(fileList[0]).subscribe(base64 => {
        let allegato:Allegato={
          content: base64,
          tipo: tipo,
          fileName: fileList![0].name,
          nome:'',
        }
        if(tipo==='documento'){
          this.modelFile = null;
          this.avviso.documenti.push(allegato);
        }
        if(tipo==='ricevuta'){
          this.modelFilePag = null;
          this.avviso.documentiPagamento.push(allegato);
        }
      });
    }
  }


  deleteAllegato(fileName:any, tipo:any) {
    if(tipo==='documento'){
      this.avviso.documenti.forEach((element,index)=>{
        if(element.fileName==fileName) this.avviso.documenti.splice(index, 1);
     });
    }
    if(tipo==='ricevuta'){
      this.avviso.documentiPagamento.forEach((element,index)=>{
        if(element.fileName==fileName) this.avviso.documentiPagamento.splice(index, 1);
     });
    }
  }

  download(allegato: Allegato) {
    this.downdloadSub = this.toolsService.download(allegato.id).subscribe({
      next: (data: Blob) => {
        const a = document.createElement('a');
        document.body.appendChild(a);
        var url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = allegato.fileName;
        a.click();
        window.URL.revokeObjectURL(url);
      },
      error: (err) => console.log(err),
      complete: () => { }
    })
  }

  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    this.isOkLoading = true;
    const selectedIdAzienda = Number(sessionStorage.getItem("selectedStore"));
    const formattedDataInizio = formatDate(this.dataInizioModal, 'dd/MM/yyyy' ,this.locale);
    this.saveEventoSub = this.toolsService.saveEventoCalendario({
      dataInizio: formattedDataInizio,
      idAzienda: selectedIdAzienda,
      note: this.noteModal ? this.noteModal : '',
      tipologia: 'AV'
    }).subscribe({
        next: (data) => {
          if (data.esito)
            this.message.success(data.message);
          else
            this.message.error('Errore durante il salvataggio dell\'evento:' + data?.message);
          this.isVisible = false;
          this.isOkLoading = false;
          this.dataInizioModal = null;
          this.noteModal = null;
        },
        error: (error) => { 
          this.isVisible = false;
          this.isOkLoading = false;
          this.dataInizioModal = null;
          this.noteModal = null;
        }
      })
  }

  handleCancel(): void {
    this.dataInizioModal = null;
    this.noteModal = null;
    this.isVisible = false;
  }

}