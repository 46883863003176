<ng-container>
  <div class="row titleFilterHeader">
    <div class="col-12">
      <!--Page header: title, back, others actions-->
      <nz-page-header
        class="site-page-header px-2"
        (click)="onBack()"
        nzBackIcon
        nzTitle="Elenco Enti"
      >
        <nz-page-header-extra>
          <button
            nz-button
            nzType="primary"
            nzSize="large"
            [routerLink]="['/enti/nuovo-ente']"
          >
            <i nz-icon nzType="plus" nzTheme="outline"></i>
            Nuovo Ente
          </button>
        </nz-page-header-extra>
      </nz-page-header>
    </div>
    <div class="col-12 mb-3">
      <!--Filters-->
      <nz-collapse nzGhost class="filtersPanel">
        <nz-collapse-panel
          [nzActive]="false"
          [nzExpandedIcon]="expandedIcon"
        >
          <ng-template #expandedIcon let-active>
            <button
                            nz-button
                            nzType="primary"
                            nzSize="large"
                            class="filtersToggleButton"
                          >
                            <i nz-icon nzType="filter" nzTheme="outline"></i>
                            Filtra
                          </button>
          </ng-template>
          <div class="row">
            <!-- <div class="col-4">
              <nz-radio-group
                [(ngModel)]="selectedTipoVista"
                nzSize="large"
                nzButtonStyle="solid"
                (ngModelChange)="goTo()"
              >
                <label
                  nz-radio-button
                  *ngFor="let vista of tipoVista"
                  class="text-capitalize"
                  [nzValue]="vista.nome"
                  >{{ vista.nome }}</label
                >
              </nz-radio-group>
            </div> -->
            <div class="col">
              <nz-select
                [nzAllowClear]="true"
                [(ngModel)]="selectedActiveCodiceEnte"
                nzSize="large"
                nzPlaceHolder="Codice Ente"
                (ngModelChange)="onChangeCodiceEnte()"
              >
                <nz-option
                  *ngFor="let codice of listaCodici"
                  [nzValue]="codice.code"
                  [nzLabel]="codice.name"
                ></nz-option>
              </nz-select>
            </div>
            <div class="col">
              <nz-select
                [nzAllowClear]="true"
                [(ngModel)]="selectedActiveNomeEnte"
                nzSize="large"
                nzPlaceHolder="Nome Ente"
                (ngModelChange)="onChangeNomeEnte()"
              >
                <nz-option
                  *ngFor="let nome of listaNomi"
                  [nzValue]="nome.code"
                  [nzLabel]="nome.name"
                ></nz-option>
              </nz-select>
            </div>
            <div class="col">
              <nz-select
                [nzAllowClear]="true"
                [(ngModel)]="selectedActiveSedeEnte"
                nzSize="large"
                nzPlaceHolder="Sede Ente"
                (ngModelChange)="onChangeNomeSede()"
              >
                <nz-option
                  *ngFor="let sede of listaSede"
                  [nzValue]="sede.code"
                  [nzLabel]="sede.name"
                ></nz-option>
              </nz-select>
            </div>
          </div>
          <br />
          <div class="row justify-content-end">
            <div class="col-2">
              <button
                nz-button
                nzType="primary"
                nzSize="large"
                (click)="cercaFiltri()"
              >
                <i nz-icon nzType="search" nzTheme="outline"></i>
                Cerca
              </button>
            </div>
            <div class="col-2">
              <button
                nz-button
                nzType="default"
                nzSize="large"
                (click)="resetFilter()"
              >
                Reset filtri
              </button>
            </div>
          </div>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
  </div>

  <div class="col-12">
    <nz-table
      #lista
      [nzData]="listaEnti.results"
      [nzSize]="'default'"
      [nzFrontPagination]="false"
      [nzTotal]="totalPage"
      [nzPageIndex]="index_page"
      [nzPageSize]="1"
      [nzScroll]="{ y: '360px' }"
      (nzQueryParams)="onQueryParamsChange($event)"
    >
      <thead>
        <tr>
          <th>Codice Ente</th>
          <th>Nome Ente</th>
          <th>Sede</th>
          <th>Numero Rateazioni Rateizzate</th>
          <th>Numero Rateazioni Non Rateizzate</th>
          <th>Numero Rate</th>
          <th>Importo Totale</th>
          <th>Scaduti Totale</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of listaEnti.results; let i = index">
          <td>{{ item.codice }}</td>
          <td>{{ item.nome }}</td>
          <td>{{ item.sede }}</td>
          <td>{{ item.nCartelleRate }}</td>
          <td>{{ item.nCartelleNonRate }}</td>
          <td>{{ item.totRate }}</td>
          <td>{{ item.importoTotale }}</td>
          <td>{{ item.scadutoTotale }}</td>
          <td>
            <button
              nz-button
              nzType="default"
              nzSize="small"
              (click)="
                edit(
                  item.codice,
                  item.nome,
                  item.sede,
                  item.indirizzo,
                  item.idEnte,
                  item.aggiuntivi
                )
              "
            >
              Modifica
            </button>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</ng-container>
