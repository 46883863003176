import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { Filtro } from "src/app/models/filtriModel";
// import { EntiService } from "src/app/_services/enti.service";
import { ToolsService } from "src/app/_services/tools.service";
import { Location } from "@angular/common";
import { NzTableQueryParams } from "ng-zorro-antd/table";
import { NzModalService } from "ng-zorro-antd/modal";
import { AdempimentiService } from "../../../_services/adempimenti.service";

@Component({
  selector: "app-lista-adempimenti",
  templateUrl: "./lista-adempimenti.component.html",
  styleUrls: ["./lista-adempimenti.component.scss"],
})
export class ListaAdempimentiComponent implements OnInit {
  constructor(private adempimentiService: AdempimentiService,
            private toolsService: ToolsService,
                  private router: Router,
                  private nzModalService: NzModalService) {}

  location!: Location;
  listaAdempimenti: any = [];
  listaCodici: any = [];
  listaNomi: any = [];
  listaSede: any = [];
  selectedActiveCodiceAdempimento: any = null;
  selectedActiveNomeAdempimento: any = null;
  selectedActiveSedeAdempimento: any = null;
  totalPage = 0;
  index_page = 0;
  rowNumber = 10;
  selectedTipoVista = "adempimenti";
  filterName = "ADEMPIMENTO_FILTER";
  tipoVista = [
    { id: 1, nome: "adempimenti" },
    { id: 2, nome: "rateazioni" },
    { id: 3, nome: "enti" },
  ];

  // public filtro: Filtro = {    
  // cartellaFilter: {
  //   codiceCartella: null,
  //   codiceEnte: null,
  //   codiceRata: null,
  //   dataScadenza: null,
  //   idStatoCarella: 0,
  //   nomeEnte: null,
  //   order: null,
  //   page: 0,
  //   rowNumber: 10,
  //   tipoCartella: null,
  //   tipoRata: null
  // },
  //   enteFilter: {
  //     codice: null,
  //     nome: null,
  //     sede: null,
  //     page: 0,
  //     rowNumber: 10,
  //   },
  //   idAzienda: 1,
  //   rateazioneFilter: {
  //     codiceCartella: null,
  //     codiceEnte: null,
  //     codiceRata: null,
  //     dataPagamento: null,
  //     nomeEnte: null,
  //     numeroRata: 0,
  //     order: null,
  //     page: 0,
  //     rowNumber: 10,
  //     sedeEnte: null,
  //     tipoCartella: null,
  //     tipoRata: null,
	//     idStatoCartella: null,
	// 		dataScadenza: null,
	// 		pagato: null,
	// 		scaduta: null,
  //   }
  // };

  ngOnInit(): void {
    this.getAdempimentiInit(); 
  }

  goTo() {
    this.router.navigate(["/" + this.selectedTipoVista]);
  }

  onBack() {
    this.location.back();
  }

  onChangeCodiceAdempimento() {
    this.selectedActiveNomeAdempimento = null;
    this.selectedActiveNomeAdempimento = null;
    this.populate();
  }
  onChangeNomeAdempimento() {
    this.selectedActiveNomeAdempimento = null;
    this.populate();
  }
  onChangeNomeSede() {
    this.populate();
  }
  cercaFiltri() {
    // this.filtro.enteFilter.page = 0;
    // this.filtro.enteFilter.codice = this.selectedActiveCodiceEnte;
    // this.filtro.enteFilter.nome = this.selectedActiveNomeEnte;
    // this.filtro.enteFilter.sede = this.selectedActiveSedeEnte;
    // this.filtro.enteFilter.rowNumber = this.rowNumber;
    // this.entiService.getEnteSearch(this.filtro).subscribe(
    //   (data) => {
    //     if (data) {
    //       this.listaEnti = data;
    //       this.selectedActiveCodiceEnte =
    //         this.listaEnti.filter.enteFilter.codice;
    //       this.selectedActiveNomeEnte = this.listaEnti.filter.enteFilter.nome;
    //       this.selectedActiveSedeEnte = this.listaEnti.filter.enteFilter.sede;                    
    //       this.filtro= this.listaEnti.filter;
    //       this.totalPage = this.listaEnti.numberPage;
    //       this.index_page = this.listaEnti.filter.enteFilter.page! + 1;
    //       this.rowNumber = this.listaEnti.filter.enteFilter.rowNumber;
    //     }
    //   },
    //   (err) => console.error(err),
    //   () => {
    //     //this.loading = false;
    //   }
    // );
    // this.populate();
  }

  populate() {
    // this.filtro.enteFilter.page = 0;
    // this.filtro.enteFilter.codice = this.selectedActiveCodiceEnte;
    // this.filtro.enteFilter.nome = this.selectedActiveNomeEnte;
    // this.filtro.enteFilter.sede = this.selectedActiveSedeEnte;
    // this.entiService.getPopulateDropDown(this.filtro).subscribe(
    //   (data) => {
    //     if (data) {
    //       this.listaCodici = data.listaCodici;
    //       this.listaNomi = data.listaNomi;
    //       this.listaSede = data.listaSede;                    
    //       this.filtro= data.filter;
    //     }
    //   },
    //   (err) => console.error(err),
    //   () => {
    //     //this.loading = false;
    //   }
    // );
  }

  edit(id: any, a: any, b: any, c: any) {
    this.router.navigateByUrl("tipo-adempimenti/nuovo-tipo-adempimento", {
      state: { idTipologiaAdempimenti: id, codice: a, label: b, clonabile: c },
    });
  }

  delete(idTipologiaAdempimenti: any) {
    this.nzModalService.confirm({
      nzTitle: 'Elimina adempimento',
      nzContent: 'Sei sicuro di voler eliminare questo adempimento?',
      nzOkText: 'Si elimina',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => {
        this.adempimentiService.delete(idTipologiaAdempimenti).subscribe((response) => {
          this.getAdempimentiInit();
        });
      },
      nzCancelText: 'Annulla',
      nzOnCancel: () => {}
    });
  }

  onQueryParamsChange(e: NzTableQueryParams) {
  //   this.filtro.enteFilter.page = e.pageIndex - 1;
  //   if (this.filtro.enteFilter.page == -1) {
  //     this.filtro.enteFilter.page = 0;
  //   }
  //   setTimeout (() => {
  //  }, 1000);
  //   this.entiService.getEnteSearch(this.filtro).subscribe(
  //     (data) => {
  //       if (data) {
  //         this.listaEnti = data;
  //         this.selectedActiveCodiceEnte =
  //           this.listaEnti.filter.enteFilter.codice;
  //         this.selectedActiveNomeEnte = this.listaEnti.filter.enteFilter.nome;
  //         this.selectedActiveSedeEnte = this.listaEnti.filter.enteFilter.sede;          
  //         this.filtro = this.listaEnti.filter;
  //         this.totalPage = this.listaEnti.numberPage;
  //         this.index_page = this.listaEnti.filter.enteFilter.page! + 1;
  //         this.rowNumber = this.listaEnti.filter.enteFilter.rowNumber;
  //       }
  //     },
  //     (err) => console.error(err),
  //     () => {
  //       //this.loading = false;
  //     }
  //   );
  //   this.populate();
  }

  resetFilter() {
    // this.toolsService.resetFilter(this.filterName).subscribe(
    //   (data) => {
    //     if (data) {
    //       this.listaEnti = data;
    //       this.selectedActiveCodiceEnte = null;
    //       this.selectedActiveNomeEnte = null;
    //       this.selectedActiveSedeEnte = null;
    //       this.filtro = this.listaEnti.filter;
    //       this.getEntiInit();
    //       this.populate();
    //     }
    //   },
    //   (err) => console.error(err),
    //   () => {
    //     //this.loading = false;
    //   }
    // );
  }

  getAdempimentiInit() {
    this.adempimentiService.getAdempimentoInit().subscribe(
      (data) => {
        if (data) {
          this.listaAdempimenti = data;
          // this.selectedActiveCodiceEnte =
          // this.listaEnti.filter.enteFilter.codice;
          // this.selectedActiveNomeEnte = this.listaEnti.filter.enteFilter.nome;
          // this.selectedActiveSedeEnte = this.listaEnti.filter.enteFilter.sede;
          // this.filtro= this.listaEnti.filter;
          this.totalPage = this.listaAdempimenti.numberPage;
          // this.index_page = this.listaEnti.filter.enteFilter.page! + 1;
          // this.rowNumber = this.listaEnti.filter.enteFilter.rowNumber;
          // this.listaCodici = this.listaEnti.listaCodici;
          // this.listaNomi = this.listaEnti.listaNomi;
          // this.listaSede = this.listaEnti.listaSede;
         this.populate();
        }
      },
      (err) => console.error(err),
      () => {
        //this.loading = false;
      }
    );
  }
}
