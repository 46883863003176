// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiPath:     'https://app.fiscalagend.com/af-be',
  openApiPath: 'https://app.fiscalagend.com/af-be',
  imagesPath:  'https://app.fiscalagend.com/af-be'
  
  // firebaseConfig: {
  //   apiKey: "AIzaSyDND3FAIUl2HvKuJY9nI9pvyjrZQii7IzE",
  //   authDomain: "igfbi-dev.firebaseapp.com",
  //   projectId: "igfbi-dev",
  //   storageBucket: "igfbi-dev.appspot.com",
  //   messagingSenderId: "1096194542434",
  //   appId: "1:1096194542434:web:00e46a3dbc390a5cc33a3b"
  // }  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
