import { NavTreeNode, nav_tree } from '../../../_menus/nav-routes';
import { Component, OnInit, DoCheck, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
// import { ArticlesService } from '../../_services/articles.service';
import { UserService } from 'src/app/_services/user.service';
import { User } from 'src/app/models/userModel';
import { DropdownList } from 'src/utils/utils';

interface Path {
  found: boolean;
  items: any[];
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, DoCheck {

  @Output() change = new EventEmitter();

  public allowedUrls = [];
  public navTree!: NavTreeNode[];
  private branchPath = { found: false, items: [] };
  private canRetrievePath = false;
  private lastRoutePath: string;
  public active: boolean;
  public username!: string;
  public currentUser!: User;
  public name!: string;
  public currentYear = new Date().getFullYear();
  public selectedYear!: DropdownList;
  public years!: DropdownList[];
  public selectedStore!: DropdownList;
  public stores!: DropdownList[];

  constructor(
    public router: Router,
    private userService: UserService,
  ) {
    this.lastRoutePath = '/';
    this.active = false;
  }

  ngDoCheck() {
    if (this.router.url === this.lastRoutePath) {
      this.canRetrievePath = false;
    } else {
      this.canRetrievePath = true;
      this.lastRoutePath = this.router.url;
    }
  }

  ngOnInit() {
    //this.allowedUrls = this.userService.allowedUrls;
    this.navTree = nav_tree;
    this.currentUser = this.userService.getUser();
    if(this.currentUser !== null){
      this.name = this.currentUser.username;
    }
    this.listStores();
    if(sessionStorage.getItem('selectedStore') !== null){
      let idselectedStore: string = sessionStorage.getItem('selectedStore')!;
      for (let i = 0; i < this.stores.length; i++) {
        if (this.stores[i].code == idselectedStore) {
          this.selectedStore = this.stores[i];
        }
      }
    }
    this.updateStore(false);
    this.updateYear(false);
  }

  listStores(){
    this.stores = [];
    for (let i = 0; i < this.currentUser.aziende.length; i++) {
      let azienda = this.currentUser.aziende[i];
      this.stores.push({
        name: azienda.name,
        code: azienda.code.toString()
      });
    }
    if (!this.selectedStore) {
      this.selectedStore = this.stores[0];
    }
  }

  handlerNavMob() {
    this.active = !this.active;
  }

  logout(){
    const sub = this.userService.logout().subscribe(() => {
      sub.unsubscribe();
      const currentUser: any = sessionStorage.getItem('currentUser');
      this.change.emit(currentUser);
      this.router.navigate(['/login']);
    });
  }

  retrievePath(match_path: string, myData: NavTreeNode[]): any {
    if (this.canRetrievePath) {
      const result = this.recursiveRoutePath(match_path, myData);
      if (result.found) {
        result.items = result.items.reverse();
        this.canRetrievePath = false;
        this.branchPath = result;
      }
    }
    return this.branchPath.items;
  }

  recursiveRoutePath(match_path: string, myData: NavTreeNode[]): any {
    for (let i = 0; i < myData.length; i++) {
      const myDataElement = myData[i];
      if (myDataElement && myDataElement.type === 'route' && myDataElement.path === match_path) {
        const path: Path = { 
          found: true, 
          items: [] 
        };
        path.items.push(
          { 
            label: myDataElement.label, 
            name: myDataElement.item + ':' + myDataElement.type,
            href: myDataElement.path 
          }
        );
        return path;
      }
      if (myDataElement && myDataElement.children) {
        const path = this.recursiveRoutePath(match_path, myDataElement.children);
        if (path.found) {
          path.items.push({ label: myDataElement.label, name: myDataElement.item + ':' + myDataElement.type, href: myDataElement.path });
          return path;
        }
      }
    }
    return { found: false, items: [] };
  }

  updateYear(withRedirect = true) {
    //sessionStorage.setItem("selectedYear", this.selectedYear.code);
    if (withRedirect) {
      this.router.navigate(['/home']);
    }
  }

  updateStore(withRedirect = true) {
    sessionStorage.setItem("selectedStore", this.selectedStore.code + "");
    this.userService.setAzienda(Number(this.selectedStore.code)).subscribe(
      (data) => {
        if (withRedirect) {
          this.router.navigate(['/home']);
        }
      },
      (err) => console.error(err),
      () => {

      }
    );

  }

  onBack(): void {
    console.log('onBack');
  }
}
