import { DatePipe, Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { RateazioniService } from 'src/app/_services/rateazioni.service';
import { Filtro } from 'src/app/models/filtriModel';
import { Subscription } from 'rxjs';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Budget, Rateazioni } from 'src/app/models/rateazioniModel';
import { BudgetService } from 'src/app/_services/budget.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ToolsService } from 'src/app/_services/tools.service';
import { DropdownList } from 'src/utils/utils';

@Component({
  selector: 'app-budget',
  templateUrl: './budget.component.html',
  styleUrls: ['./budget.component.scss']
})
export class BudgetComponent implements OnInit, OnDestroy {

  location!: Location;
  loading = false;
  date = [new Date(), new Date()];
  dtFormat = 'dd/MM/yyyy';

  filterName = "BUDGET_FILTER";
  orderBy!: DropdownList[];
  
  totalPage = 0;
  index_page = 0;
  rowNumber = 10;
  public filtro: Filtro = {
    cartellaFilter: {
      codiceCartella: null,
      codiceEnte: null,
      codiceRata: null,
      dataScadenza: null,
      idStatoCarella: 0,
      nomeEnte: null,
      order: null,
      page: 0,
      rowNumber: 10,
      tipoCartella: null,
      tipoRata: null,
    },
    enteFilter: {
      codice: null,
      nome: null,
      sede: null,
      page: 0,
      rowNumber: 10,
    },
    idAzienda: 1,
    rateazioneFilter: {
      codiceCartella: null,
      codiceEnte: null,
      codiceRata: null,
      dataPagamento: null,
      nomeEnte: null,
      numeroRata: 0,
      order: null,
      page: 0,
      rowNumber: 10,
      sedeEnte: null,
      tipoCartella: null,
      tipoRata: null,
      idStatoCartella: null,
      dataScadenza: null,
      pagato: null,
      scaduta: null,
    },
    avvisoFilter: {
      nomeEnte: null,
      codiceEnte: null,
      stato: null,
      dataScadenza: null,
      order: null,
      page: 0,
      rowNumber: 10,
    },
    budgetFilter: {
      dataInizio: null,
      dataFine: null,
      order: null,
      page: 0,
      rowNumber: 10,
    },
  };

  listaBudget: any = [];

  // subscriptions
  // reateazioneInitSub?: Subscription;
  budgetSearchSub?: Subscription;

  constructor(
    private budgetService: BudgetService,
    private datepipe: DatePipe,
    private message: NzMessageService,
    private toolsService: ToolsService,
  ) { }

  async ngOnInit() {
    // this.searchRateazioni();
  }

  onQueryParamsChange(e: NzTableQueryParams) {
    //this.loading = true;
    this.filtro.budgetFilter.page = e.pageIndex - 1;
    if (this.filtro.budgetFilter.page == -1) {
      this.filtro.budgetFilter.page = 0;
    }
    setTimeout(() => {
    }, 1000);
    this.searchBudget();
  }

  searchBudget() {
    this.budgetSearchSub = this.budgetService.searchBudget(this.filtro).subscribe({
      next: (data) => {
        if (data) {
          console.log(data);
          this.loading = false;
          
          this.listaBudget = data;
          this.totalPage = this.listaBudget.numberPage;
          this.index_page = this.listaBudget.filter.budgetFilter.page! + 1;
          this.rowNumber = this.listaBudget.filter.budgetFilter.rowNumber;
          
          const _dataInizio: string[] = this.listaBudget.filter.budgetFilter.dataInizio?.split('/');
          const _dataFine: string[] = this.listaBudget.filter.budgetFilter.dataFine?.split('/');

          let dataInizio = _dataInizio ? new Date(+_dataInizio[2], +_dataInizio[1], +_dataInizio[0]) : new Date();
          let dataFine = _dataFine ? new Date(+_dataFine[2], +_dataFine[1], +_dataFine[0]) : new Date();
          this.date = [dataInizio, dataFine];
          console.log('date caricate = ', this.date)

          this.orderBy = this.listaBudget.filter.budgetFilter.order;          
          this.filtro = this.listaBudget.filter;
        }
      },
      error: (err) => console.error(err),
      complete: () => {
        //this.loading = false;
      }
    });
  }

  
  cercaFiltri() {
    this.filtro.budgetFilter.page = 0;
    this.filtro.budgetFilter.dataInizio
    this.filtro.budgetFilter.dataFine
    this.filtro.budgetFilter.order = this.orderBy;
    this.filtro.budgetFilter.rowNumber = this.rowNumber;
    this.searchBudget();
  }

  onBack() {
    this.location.back();
  }


  onChange(result: Date[]): void {
    // todo: filtrare elenco per le date inizio fine
    console.log('onChange: ', result);
    this.date = result;
    this.filtro.budgetFilter.dataInizio = this.datepipe.transform(this.date[0], this.dtFormat) || '';
    this.filtro.budgetFilter.dataFine = this.datepipe.transform(this.date[1], this.dtFormat) || '';

    // this.searchRateazioni();
  }
  
  ngOnDestroy(): void {
    this.budgetSearchSub?.unsubscribe();
  }

  resetFiltri() {
    this.toolsService.resetFilter(this.filterName).subscribe({
      next: (data) => {
        if (data) {
          //this.listaRateazioni = data;
          // this.getRateazioniInit();
          this.searchBudget();
        }
      },
      error: (err) => console.error(err),
      complete: () => {}
    });
  }
}
