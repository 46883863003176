import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) { }

  
  getCartelleNonRateizzate() {
    return this.http.get<any>(environment.apiPath + '/dashboard/cartelle-non-rateizzate')
      .pipe(map(results => {
        return results;
      }));
  }

  getCartelleRateizzate() {
    return this.http.get<any>(environment.apiPath + '/dashboard/cartelle-rateizzate')
      .pipe(map(results => {
        return results;
      }));
  }

  getListaRate() {
    return this.http.get<any>(environment.apiPath + '/dashboard/lista-rate')
      .pipe(map(results => {
        return results;
      }));
  }

  getListaRatePrioritarie() {
    return this.http.get<any>(environment.apiPath + '/dashboard/rate-prioritarie')
      .pipe(map(results => {
        return results;
      }));
  }

  getCalendar() {
    return this.http.get<any>(environment.apiPath + '/dashboard/calendar')
      .pipe(map(results => {
        return results;
      }));
  }

}
