import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { UserService } from '../_services/user.service';
import { Router } from '@angular/router';
import { User } from '../models/userModel';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(public router: Router, private http: HttpClient, private userService: UserService) {}
  
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = this.userService.getUser();
    let selectedYear = '';
    if(sessionStorage.getItem("selectedYear") !== null){
      selectedYear = sessionStorage.getItem("selectedYear")!.toString();
    }
    if(currentUser && currentUser !== null && currentUser.token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.token}`,
          Anno: selectedYear,
        }
      });
    }
    return next.handle(request);
  }

}
