import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from "src/app/_services/dashboard.service"

@Component({
  selector: 'app-lista-rate',
  templateUrl: './lista-rate.component.html',
  styleUrls: ['./lista-rate.component.scss']
})
export class ListaRateComponent implements OnInit {

  rate: any = [];


  constructor(
    private router: Router,
    private dashboardService: DashboardService) { }

  ngOnInit(): void {
    this.dashboardService.getListaRate().subscribe(
      (data) => {
        if (data) {
          this.rate = data;
        }
      },
      (err) => console.error(err),
      () => {
    
      }
      );
  }


}
