
export class NavTreeNode {
    children?: NavTreeNode[];
    path?: any;
    type!: string;
    label!: string;
    item!: string;
}

export const nav_tree: NavTreeNode[] = [

    {
      item: 'home',
      label: 'home',
      type: 'route',
      path: 'home'
    },
   
    
    {
        item: 'elenco-cartelle',
        label: 'Rateazioni',
        type: 'route',
        path: '/cartelle',
        children: [
            {
                item: 'dettaglio',
                label: 'Dettaglio rateazioni non rateizzate',
                type: 'route',
                path: '/cartelle/dettaglio',

            },
            {
                item: 'inserisci-cartella',
                label: 'Nuova rateazione',
                type: 'route',
                path: '/cartella/inserisci-cartella'
            },
           
        ]
    },
    {
        item: 'elenco-rateazioni',
        label: 'Rateazioni',
        type: 'route',
        path: '/rateazioni',
        children: [
            {
                item: 'dettaglio-rateazione',
                label: 'Dettaglio rateazioni',
                type: 'route',
                path: '/rateazioni/dettaglio',

            },
            
            {
                item: 'inserisci-rateazione',
                label: 'Nuova rateazione',
                type: 'route',
                path: '/rateazione/inserisci-rateazione'
            },
           
        ]
    },
    
    
];
