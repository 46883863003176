<app-loader *ngIf="loading"></app-loader>
<nz-affix *ngIf="!loading" [nzOffsetTop]="60">
    <div class="row titleFilterHeader">
        <div class="col-12">
            <!--Page header: title, back, others actions-->
            <nz-page-header class="site-page-header px-2" (click)="onBack()" nzBackIcon nzTitle="Rateazioni">
                <nz-page-header-extra>
             <!--       <button nz-button nzType="primary" nzSize="large" [routerLink]="['/cartelle/nuova-cartella']">
            <i nz-icon nzType="plus" nzTheme="outline"></i>
            Nuova cartella
          </button>-->
                </nz-page-header-extra>
            </nz-page-header>
        </div>

        <div class="col-12">
            <!--Filters-->
            <nz-collapse nzGhost class="filtersPanel">
                <nz-collapse-panel [nzExpandedIcon]="expandedIcon" [nzActive]="false">
                    <ng-template #expandedIcon let-active>
                        <button
                            nz-button
                            nzType="primary"
                            nzSize="large"
                            class="filtersToggleButton"
                          >
                            <i nz-icon nzType="filter" nzTheme="outline"></i>
                            Filtra
                          </button>
                    </ng-template>

                    <div class="row">
                        <div class="col-4">
                            <nz-radio-group [(ngModel)]="selectedTipoVista" nzSize="large" nzButtonStyle="solid" (ngModelChange)="goTo()">
                                <label nz-radio-button *ngFor="let vista of tipoVista" class="text-capitalize" [nzValue]="vista.link">{{ vista.nome }}</label>
                            </nz-radio-group>
                        </div>
                        <div class="col-2">
                            <nz-select [(ngModel)]="selectedDateScadenza" nzSize="large" [nzAllowClear]="true" (ngModelChange)="updateList() " nzPlaceHolder="Date Scadenza">
                                <nz-option *ngFor="let ente of listaDateScadenza" [nzValue]="ente.code" [nzLabel]="ente.name"></nz-option>
                            </nz-select>
                        </div>
                        <div class="col-2">
                            <nz-select [(ngModel)]="selectedCodiceCartella" nzSize="large" [nzAllowClear]="true" (ngModelChange)="updateList() " nzPlaceHolder="Codice Rateazione ">
                                <nz-option *ngFor="let ente of listaCodiciCartella" [nzValue]="ente.code" [nzLabel]="ente.name"></nz-option>
                            </nz-select>
                        </div>
                        <div class="col-2">
                            <nz-select [(ngModel)]="selectedCodiceEnte" nzSize="large" [nzAllowClear]="true" (ngModelChange)="updateList() " nzPlaceHolder="Codice Ente ">
                                <nz-option *ngFor="let ente of listaCodiciEnti" [nzValue]="ente.code" [nzLabel]="ente.name"></nz-option>
                            </nz-select>
                        </div>
                        <br><br><br>
                        <div class="col-3 ">
                            <nz-select [(ngModel)]="orderBy" nzSize="large" (ngModelChange)="updateList() " nzPlaceHolder="Ordina per">
                                <nz-option *ngFor="let ordinamento of ordinamenti" [nzValue]="ordinamento" [nzLabel]="ordinamento"></nz-option>
                            </nz-select>
                        </div>

                        <div class="col-2 offset-1">
                            <nz-select [(ngModel)]="selectedTipoRata" nzSize="large" [nzAllowClear]="true" (ngModelChange)="updateList() " nzPlaceHolder="Codice Rata ">
                                <nz-option *ngFor="let tipoRata of tipiRata" [nzValue]="tipoRata.code" [nzLabel]="tipoRata.name "></nz-option>
                            </nz-select>
                        </div>
                        <div class="col-2 ">
                            <nz-select [(ngModel)]="selectedTipoCartella" nzSize="large" [nzAllowClear]="true" (ngModelChange)="updateList()" nzPlaceHolder="Tipo Rateazione">
                                <nz-option *ngFor="let tipoCartella of tipiCartella" [nzValue]="tipoCartella.code" [nzLabel]="tipoCartella.name"></nz-option>
                            </nz-select>
                        </div>
                        <div class="col-2">
                            <nz-select [(ngModel)]="selectedNomeEnte" nzSize="large" [nzAllowClear]="true" (ngModelChange)="updateList() " nzPlaceHolder="Nome Ente ">
                                <nz-option *ngFor="let ente of listaEnti" [nzValue]="ente.code" [nzLabel]="ente.name"></nz-option>
                            </nz-select>
                        </div>
                        <div class="row mt-3">

                        </div>
                        <div class="row mt-3">
                            <div class="col-3">

                            </div>
                        </div>

                    </div>
                    <div class="row justify-content-end ">
                        <div class="col-2">
                            <button nz-button nzType="primary" nzSize="large"  (click)="search()">
                        <i nz-icon nzType="search" nzTheme="outline"></i>
                        Cerca
                      </button>
                        </div>
                        <div class="col-2">
                            <button nz-button nzType="default" nzSize="large" (click)="resetFiltri()">
                              Reset filtri
                                </button>
                        </div>
                    </div>
                </nz-collapse-panel>
            </nz-collapse>

        </div>
    </div>
</nz-affix>
<br>
<div *ngIf="!loading " class="row ">
    <div class="col-12 ">
        <nz-table
            #cartelleFilter
            [nzData]="cartelle "
            [nzSize]=" 'small' "
            
            [nzFrontPagination]="false"
            [nzTotal]="totalPage"
            [nzPageIndex]="index_page"
            [nzPageSize]="1"
            (nzQueryParams)="onQueryParamsChange($event)"
        >
            <thead>
                <tr>
                    <th>TIPOLOGIA OPERAZIONE</th>
                    <th>CREDITORE</th>
                    <th>Codice Univoco</th>
                    <th>Importo Effettivo</th>
                    <th>Stato</th>
                    <th>N.Rate</th>
                    <th>REGOLARITA'</th>
                    <th>N.Rate ritardabili</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <tr *ngFor="let cartella of cartelleFilter.data " (click)="goToDetail(cartella.idAdempimento, cartella.tipo)">
                        <td>{{cartella.tipo}}</td>
                        <td>{{cartella.creditore}}</td>
                        <td>{{cartella.codice}}</td>
                        <td>{{cartella.importoEffettivo}} €</td>
                        <td>{{cartella.stato}}</td>
                        <td>{{cartella.numeroRateTotali}}</td>
                        <td class="text-center text-lowercase ">{{cartella.numeroRatePagate}} rate pagate su {{cartella.numeroRateTotali}}
                            <div *ngIf="cartella.numerRateScadute" class="{{cartella.colorStato}} dettaglioScadenza mt-1 mx-4 "> {{cartella.numerRateScadute}} Scadute </div>
                        </td>
                        <td>{{cartella.maxScadute}}</td>
                        <td>
                            <i class="p-2 fas fa-circle" 
                                [ngClass]="getIconProperties('ball', cartella).className"
                                [title]="getIconProperties('ball', cartella).title"></i>
                            <i class="p-2 far fa-calendar-alt" 
                                [ngClass]="getIconProperties('calendar', cartella).className"
                                [title]="getIconProperties('calendar', cartella).title"></i>
                            <i class="p-2 fas fa-dollar-sign" 
                                [ngClass]="getIconProperties('dollar', cartella).className"
                                [title]="getIconProperties('dollar', cartella).title"></i>
                        </td>
                    </tr>
            </tbody>
        </nz-table>
    </div>
</div>