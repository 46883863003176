<ng-container>
    <nz-collapse class="panel" [nzExpandIconPosition]="'right'">
        <nz-collapse-panel nzHeader="Rateazioni rateizzate">
            <nz-table #cartelle [nzData]="cartelleRateizzate" [nzSize]="'small'">
                <thead>
                    <tr>
                        <th>Rateazione</th>
                        <th>Ente</th>
                        <th>Stato pagamenti</th>
                        <th>Pagato su complessivo</th>

                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <tr *ngFor="let cartella of cartelle.data" [routerLink]="['/cartelle/dettaglio', {id:cartella.idAdempimento}]">
                            <td>{{cartella.idAzienda}} <br> {{cartella.tipo}}</td>
                            <td>{{cartella.codiceEnte}} <br> {{cartella.nomeEnte}} <br> sede</td>
                            <td class="text-center text-lowercase">{{cartella.numeroRatePagate}} rate pagate su {{cartella.numeroRateTotali}}
                                <div *ngIf="cartella.numerRateScadute" class="{{cartella.colorPagamenti}} dettaglioScadenza mt-1 mx-4"> {{cartella.numerRateScadute}} Scadute </div>
                            </td>
                            <td class="text-center text-lowercase">{{cartella.importoPagato}} € di {{cartella.importoTotale}}
                                <div *ngIf="cartella.importoScaduto" class="{{cartella.pacolorPagamenti}} dettaglioScadenza mt-1 mx-4"> {{cartella.importoScaduto}} € Scaduti </div>
                            </td>

                        </tr>
                </tbody>
            </nz-table>


        </nz-collapse-panel>
    </nz-collapse>
</ng-container>