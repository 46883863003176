import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { SimpleAvviso } from '../../models/avvisoModel';
import { Router } from '@angular/router';
import { Filtro } from 'src/app/models/filtriModel';
import { AvvisoService } from 'src/app/_services/avviso.service';
import { ToolsService } from 'src/app/_services/tools.service';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Configurations } from 'src/app/models/iconConfigModel';

@Component({
  selector: 'app-avvisi',
  templateUrl: './avvisi.component.html',
  styleUrls: ['./avvisi.component.scss']
})
export class AvvisiComponent implements OnInit {
  location!: Location;
  loading = false;

  avvisi: SimpleAvviso[] = [];


  tipoVista = [
    { id: 1, link: 'cartelle', nome: 'rateazioni/adempimenti' },
    { id: 2, link: 'rateazioni', nome: 'rate' },
    { id: 3, link: 'avvisi', nome: 'avvisi' }
  ];
  selectedTipoVista = 'avvisi';

  // liste opzioni select
  listaCodiciEnti: any;
  listaEnti: any;
  listaStati: any;
  listDataScadenza: any;
  ordinamenti= ['codice adempimento', 'tipo adempimento', 'stato'];

  // filtri
  selectedNomeEnte: any;
  selectedCodiceEnte: any;
  selectedStato: any;
  selectedDataScadenza: any;
  // selectedOrder: any;
  orderBy= '';
  filterName = "AVVISO_FILTER";
  totalPage = 0;
  index_page = 0;

  public filtro: Filtro = {
    cartellaFilter: {
      codiceCartella: null,
      codiceEnte: null,
      codiceRata: null,
      dataScadenza: null,
      idStatoCarella: 0,
      nomeEnte: null,
      order: null,
      page: 0,
      rowNumber: 0,
      tipoCartella: null,
      tipoRata: null
    },
    enteFilter: {
      codice: null,
      nome: null,
      sede: null,
      page: 0,
      rowNumber: 10,
    },
    idAzienda: 1,

    rateazioneFilter: {
      codiceCartella: null,
      codiceEnte: null,
      codiceRata: null,
      dataPagamento: null,
      nomeEnte: null,
      numeroRata: 0,
      order: null,
      page: 0,
      rowNumber: 0,
      sedeEnte: null,
      tipoCartella: null,
      tipoRata: null,
      idStatoCartella: null,
      dataScadenza: null,
      pagato: null,
      scaduta: null,
    },
    avvisoFilter: {
      nomeEnte: null,
      codiceEnte: null,
      stato: null,
      dataScadenza: null,
      order: null,
      page: 0,
      rowNumber: 10,
    },
    budgetFilter: {
      dataInizio: null,
      dataFine: null,
      order: null,
      page: 0,
      rowNumber: 10,
    },
  };

  constructor(
    private avvisoService: AvvisoService,
    private toolsService: ToolsService,
    private router: Router,
  ) { }
  
  async ngOnInit() {
    this.getListaAvvisi();
  }

  getIconProperties(type: string, avviso: SimpleAvviso): any {
    const configurations: Configurations = {
      ball: {
        className: avviso.ballColor ? `icon-${avviso.ballColor}` : 'icon-grey',
        titleMapping: {
          grey: 'Ricevuto e non gestito',
          blue: 'Gestito e richiesta rateizzazione'
        },
        defaultTitle: 'Stato sconosciuto'
      },
      dollar: {
        className: avviso.dollarColor ? `icon-${avviso.dollarColor}` : 'icon-grey',
        titleMapping: {
          orange: 'In corso di rateazione',
          grey: 'Non pagato',
          green: 'Pagato'
        },
        defaultTitle: 'Stato sconosciuto'
      },
      calendar: {
        className: avviso.calendarColor ? `icon-${avviso.calendarColor}` : 'icon-grey',
        titleMapping: {
          grey: 'Pagamento non calendarizzato',
          blue: 'Pagamento programmato',
          green: 'Pagamento programmato'
        },
        defaultTitle: 'Pagamento non calendarizzato'
      }
    };
  
    const config = configurations[type];
  
    if (config) {
      const colorProperty = `${type}Color`; 
      const color = avviso[colorProperty as keyof SimpleAvviso];
      return {
        className: config.className,
        title: config.titleMapping[color] || config.defaultTitle
      };
    }
  
    return {
      className: 'icon-gray',
      title: 'Not specified'
    };
  }

  getListaAvvisi() {
    this.avvisoService.getAvvisiInit().subscribe({
      next: data => {
        this.caricaAvvisi(data);
        
        // opzioni filtri
        this.listaEnti = data?.entiLabels;
        this.listaCodiciEnti = data?.listCodiceEnte;
        this.listaStati = data?.listStati;
        this.listDataScadenza = data?.listScadenze;
      },
      error: err => console.log(err),
    })
  }
  
  updateList() {
    this.filtro.avvisoFilter.nomeEnte = this.selectedNomeEnte;
      this.filtro.avvisoFilter.codiceEnte = this.selectedCodiceEnte;
      this.filtro.avvisoFilter.stato = this.selectedStato;
      this.filtro.avvisoFilter.dataScadenza = this.selectedDataScadenza;
      // this.avvisoService.getPopulateDropDown(this.filtro).subscribe({
      //   next: (data) => {
      //     if(data) {
      //       this.listaCodiciEnti = data.listaCodiciEnti;
      //       this.listaEnti = data.listaEnti;
      //       this.listaStati = data.listaStati;
      //       this.listDataScadenza = data.listScadenze;
      //     }
      //   },
      //   error: (err) => console.error(err),
      // })
  }

  resetFiltri() {
    this.toolsService.resetFilter(this.filterName).subscribe({
      next: (data) => {
        if(data){
          this.selectedNomeEnte = '';
          this.selectedCodiceEnte = '';
          this.selectedStato = '';
          this.selectedDataScadenza = '';
          this.getListaAvvisi();
        }
      },
      error: (error) => console.log(error),
    });
  }

  search() {
    this.avvisoService.search(this.filtro).subscribe({
      next: data => this.caricaAvvisi(data),
      error: error => console.log(error),
    })
  }

  
  onQueryParamsChange(e: NzTableQueryParams) {
    this.filtro.avvisoFilter.page = e.pageIndex - 1;
    if (this.filtro.avvisoFilter.page == -1) {
      this.filtro.avvisoFilter.page = 0;
    }
    setTimeout (() => { }, 1000);
    this.avvisoService.search(this.filtro).subscribe({
      next: data => this.caricaAvvisi(data),
      error: err => console.log(err),
      complete: () => { },
    })
  }

  caricaAvvisi(data: any) {
    if(data) {
      // lista avvisi
      this.avvisi = data.results;
      
      // filtri selezionati
      this.filtro = data.filter;
      this.selectedNomeEnte = (<Filtro>data.filter).avvisoFilter.nomeEnte;
      this.selectedCodiceEnte = (<Filtro>data.filter).avvisoFilter.codiceEnte;
      this.selectedStato = (<Filtro>data.filter).avvisoFilter.stato;
      this.selectedDataScadenza = (<Filtro>data.filter).avvisoFilter.dataScadenza;
      
      this.totalPage = data.numberPage;
      this.index_page = (<Filtro>data.filter).avvisoFilter.page + 1;
    }
  }

  goTo() {
    this.router.navigate(['/' + this.selectedTipoVista])
  }
  
  goToDetail(idAvviso: number) {
    this.router.navigate(['/avvisi/dettaglio', { id: idAvviso }])
  }

  onBack() {
    this.location.back();
  }
  
}
