import { Component, OnInit } from "@angular/core";
import { DropdownList } from "src/utils/utils";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { RateazioniService } from "src/app/_services/rateazioni.service";
import { ToolsService } from "src/app/_services/tools.service";
import { NzTableQueryParams } from "ng-zorro-antd/table";
import { Filtro } from "src/app/models/filtriModel";
import { Configurations } from 'src/app/models/iconConfigModel';
import { Rateazioni } from "src/app/models/rateazioniModel";

@Component({
  selector: "app-elenco-rateazione",
  templateUrl: "./elenco-rateazione.component.html",
  styleUrls: ["./elenco-rateazione.component.scss"],
})
export class ElencoRateazioneComponent implements OnInit {
  location!: Location;

  loading = false;

  listTipoRata!: DropdownList[];
  listTipoCartella!: DropdownList[];
  listCodiceCartella!: DropdownList[];
  listCodiceEnte!: DropdownList[];
  listNomeEnte!: DropdownList[];
  listSedeEnte!: DropdownList[];
  listCodiceRata!: DropdownList[];
  listNumeroRata!: DropdownList[];
  listDatePagamento!: DropdownList[];
  listScadenze!: DropdownList[];
  listStatiCartella!: DropdownList[];
  listDataScadenza!: DropdownList[];
  listDataEffettivoPagamento!: DropdownList[];
  orderBy!: DropdownList[];
  tipoVista=[
    {id:1, link: 'cartelle', nome: 'rateazioni/adempimenti'}, 
    {id:2, link: 'rateazioni', nome: 'rate'},
    {id:3, link: 'avvisi', nome: 'avvisi'}
  ];
  selectedTipoVista = "rateazioni";
  selectedTipoRata: any;
  selectedNumeroRata:any;
  selectedCodiceCartella: any;
  selectedCodiceRata: any;
  selectedTipoCartella: any;
  selectedStato: any;
  selectedCodiceEnte: any;
  selectedNomeEnte: any;
  selectedSedeEnte: any;
  selectedDataScadenza: any;
  selectedDataEffettivoPagamento: any;
  checkPagate: boolean = false;
  checkNonPagate: boolean = false;
  filterName = "RATEAZIONE_FILTER";
  totalPage = 0;
  index_page = 0;
  rowNumber = 10;

  listaRateazioni: any = [];
  filtro: Filtro = {
    cartellaFilter: {
      codiceCartella: null,
      codiceEnte: null,
      codiceRata: null,
      dataScadenza: null,
      idStatoCarella: 0,
      nomeEnte: null,
      order: null,
      page: 0,
      rowNumber: 10,
      tipoCartella: null,
      tipoRata: null,
    },
    enteFilter: {
      codice: null,
      nome: null,
      sede: null,
      page: 0,
      rowNumber: 10,
    },
    idAzienda: 1,
    rateazioneFilter: {
      codiceCartella: null,
      codiceEnte: null,
      codiceRata: null,
      dataPagamento: null,
      nomeEnte: null,
      numeroRata: 0,
      order: null,
      page: 0,
      rowNumber: 10,
      sedeEnte: null,
      tipoCartella: null,
      tipoRata: null,
      idStatoCartella: null,
      dataScadenza: null,
      pagato: null,
      scaduta: null,
    },
    avvisoFilter: {
      nomeEnte: null,
      codiceEnte: null,
      stato: null,
      dataScadenza: null,
      order: null,
      page: 0,
      rowNumber: 10,
    },
    budgetFilter: {
      dataInizio: null,
      dataFine: null,
      order: null,
      page: 0,
      rowNumber: 10,
    },
  };
  filters = true;

  constructor(
    private rateazioniService: RateazioniService,
    private toolsService: ToolsService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.route.paramMap.subscribe(params => {
      if(params.get('id') && params.get('id') !== null){
        this.filters = false;
        this.getAmounts();
      }
      else
      {
        this.filters = true;
        this.getRateazioniInit();
      }
    });
    
  }

  onChange(result: Date): void {
    console.log("onChange: ", result);
  }

  resetFiltri() {
    this.toolsService.resetFilter(this.filterName).subscribe(
      (data) => {
        if (data) {
          //this.listaRateazioni = data;
          this.getRateazioniInit();
        }
      },
      (err) => console.error(err),
      () => {}
    );
  }

  goTo() {
    this.router.navigate(["/" + this.selectedTipoVista]);
  }

  onBack() {
    this.location.back();
  }
  onQueryParamsChange(e: NzTableQueryParams) {
    this.filtro.rateazioneFilter.page = e.pageIndex - 1;
    if (this.filtro.rateazioneFilter.page == -1) {
      this.filtro.rateazioneFilter.page = 0;
    }
    setTimeout (() => {
   }, 1000);
   this.rateazioniService.getRateazioneSearch(this.filtro).subscribe(
     (data) => {
       if (data) {
         this.listaRateazioni = data;
         this.selectedCodiceRata =
           this.listaRateazioni.filter.rateazioneFilter.codiceRata;
         this.selectedTipoRata =
           this.listaRateazioni.filter.rateazioneFilter.tipoRata;
         this.selectedNumeroRata =
           this.listaRateazioni.filter.rateazioneFilter.numeroRata;
         this.selectedDataScadenza =
           this.listaRateazioni.filter.rateazioneFilter.dataScadenza;
         this.selectedCodiceCartella =
           this.listaRateazioni.filter.rateazioneFilter.codiceCartella;
         this.selectedTipoCartella =
           this.listaRateazioni.filter.rateazioneFilter.tipoCartella;
         this.selectedStato =
           this.listaRateazioni.filter.rateazioneFilter.idStatoCartella;
         this.selectedDataEffettivoPagamento =
           this.listaRateazioni.filter.rateazioneFilter.dataPagamento;
         this.selectedCodiceEnte =
           this.listaRateazioni.filter.rateazioneFilter.codiceEnte;
         this.selectedNomeEnte =
           this.listaRateazioni.filter.rateazioneFilter.nomeEnte;
         this.selectedSedeEnte =
           this.listaRateazioni.filter.rateazioneFilter.sedeEnte;
         this.orderBy = this.listaRateazioni.filter.rateazioneFilter.order;          
         this.totalPage = this.listaRateazioni.numberPage;
         this.index_page =
           this.listaRateazioni.filter.rateazioneFilter.page! + 1;
         this.rowNumber =
           this.listaRateazioni.filter.rateazioneFilter.rowNumber;
           this.filtro = this.listaRateazioni.filter;
       }
     },
     (err) => console.error(err),
     () => {
       //this.loading = false;
     }
   );
   this.populate();
  }

  cercaFiltri() {
    this.filtro.rateazioneFilter.page = 0;
    this.filtro.rateazioneFilter.codiceRata = this.selectedCodiceRata;
    this.filtro.rateazioneFilter.tipoRata = this.selectedTipoRata;
    this.filtro.rateazioneFilter.numeroRata = this.selectedNumeroRata;
    this.filtro.rateazioneFilter.dataScadenza = this.selectedDataScadenza;
    this.filtro.rateazioneFilter.codiceCartella = this.selectedCodiceCartella;
    this.filtro.rateazioneFilter.tipoCartella = this.selectedTipoCartella;
    this.filtro.rateazioneFilter.idStatoCartella = this.selectedStato;    
    this.filtro.rateazioneFilter.dataPagamento = this.selectedDataEffettivoPagamento;
    this.filtro.rateazioneFilter.codiceEnte = this.selectedCodiceEnte;
    this.filtro.rateazioneFilter.nomeEnte = this.selectedNomeEnte;
    this.filtro.rateazioneFilter.sedeEnte = this.selectedSedeEnte;
    this.filtro.rateazioneFilter.order = this.orderBy;
    this.filtro.enteFilter.rowNumber = this.rowNumber;
    this.rateazioniService.getRateazioneSearch(this.filtro).subscribe(
      (data) => {
        if (data) {
          this.listaRateazioni = data;
          this.selectedCodiceRata =
            this.listaRateazioni.filter.rateazioneFilter.codiceRata;
          this.selectedTipoRata =
            this.listaRateazioni.filter.rateazioneFilter.tipoRata;
          this.selectedNumeroRata =
            this.listaRateazioni.filter.rateazioneFilter.numeroRata;
          this.selectedDataScadenza =
            this.listaRateazioni.filter.rateazioneFilter.dataScadenza;
          this.selectedCodiceCartella =
            this.listaRateazioni.filter.rateazioneFilter.codiceCartella;
          this.selectedTipoCartella =
            this.listaRateazioni.filter.rateazioneFilter.tipoCartella;
          this.selectedStato =
            this.listaRateazioni.filter.rateazioneFilter.idStatoCartella;
          this.selectedDataEffettivoPagamento =
            this.listaRateazioni.filter.rateazioneFilter.dataPagamento;
          this.selectedCodiceEnte =
            this.listaRateazioni.filter.rateazioneFilter.codiceEnte;
          this.selectedNomeEnte =
            this.listaRateazioni.filter.rateazioneFilter.nomeEnte;
          this.selectedSedeEnte =
            this.listaRateazioni.filter.rateazioneFilter.sedeEnte;
          this.orderBy = this.listaRateazioni.filter.rateazioneFilter.order;          
          this.totalPage = this.listaRateazioni.numberPage;
          this.index_page =
            this.listaRateazioni.filter.rateazioneFilter.page! + 1;
          this.rowNumber =
            this.listaRateazioni.filter.rateazioneFilter.rowNumber;
            this.filtro = this.listaRateazioni.filter;
        }
      },
      (err) => console.error(err),
      () => {
        //this.loading = false;
      }
    );
    this.populate();
  }
  getRateazioniInit() {
    this.rateazioniService.getRateazioneInit().subscribe(
      (data) => {
        if (data) {
          this.listaRateazioni = data;
          this.selectedCodiceRata =
            this.listaRateazioni.filter.rateazioneFilter.codiceRata;
          this.selectedTipoRata =
            this.listaRateazioni.filter.rateazioneFilter.tipoRata;
          this.selectedNumeroRata =
            this.listaRateazioni.filter.rateazioneFilter.numeroRata;
          this.selectedDataScadenza =
            this.listaRateazioni.filter.rateazioneFilter.dataScadenza;
          this.selectedCodiceCartella =
            this.listaRateazioni.filter.rateazioneFilter.codiceCartella;
          this.selectedTipoCartella =
            this.listaRateazioni.filter.rateazioneFilter.tipoCartella;
          this.selectedStato =
            this.listaRateazioni.filter.rateazioneFilter.idStatoCartella;
          this.selectedDataEffettivoPagamento =
            this.listaRateazioni.filter.rateazioneFilter.dataPagamento;
          this.selectedCodiceEnte =
            this.listaRateazioni.filter.rateazioneFilter.codiceEnte;
          this.selectedNomeEnte =
            this.listaRateazioni.filter.rateazioneFilter.nomeEnte;
          this.selectedSedeEnte =
            this.listaRateazioni.filter.rateazioneFilter.sedeEnte; 
          this.filtro = this.listaRateazioni.filter;
          this.orderBy = this.listaRateazioni.filter.rateazioneFilter.order;          
          this.totalPage = this.listaRateazioni.numberPage;
          this.index_page =
            this.listaRateazioni.filter.rateazioneFilter.page! + 1;
          this.rowNumber =
            this.listaRateazioni.filter.rateazioneFilter.rowNumber;
          this.populate();
        }
      },
      (err) => console.error(err),
      () => {
        //this.loading = false;
      }
    );
  }

  getAmounts() {
    this.rateazioniService.getRateazioneInit().subscribe(
      (data) => {
        if (data) {
          this.listaRateazioni = data;
        }
      },
      (err) => console.error(err),
      () => {}
    );
  }

  populate() {
    this.filtro.rateazioneFilter.page = 0;
    this.filtro.rateazioneFilter.codiceEnte = this.selectedCodiceEnte;
    this.filtro.rateazioneFilter.nomeEnte = this.selectedNomeEnte;
    this.filtro.rateazioneFilter.sedeEnte = this.selectedSedeEnte;
    this.filtro.rateazioneFilter.codiceEnte = this.selectedCodiceEnte;
    this.filtro.rateazioneFilter.tipoRata = this.selectedTipoRata;
    this.filtro.rateazioneFilter.numeroRata = this.selectedNumeroRata;
    this.filtro.rateazioneFilter.codiceCartella = this.selectedCodiceCartella;
    this.filtro.rateazioneFilter.tipoCartella = this.selectedTipoCartella;
    this.filtro.rateazioneFilter.idStatoCartella = this.selectedStato;
    this.filtro.rateazioneFilter.codiceEnte = this.selectedCodiceEnte;
    this.filtro.rateazioneFilter.nomeEnte = this.selectedNomeEnte;
    this.filtro.rateazioneFilter.sedeEnte = this.selectedSedeEnte;
    this.filtro.rateazioneFilter.order = this.orderBy;
    this.filtro.rateazioneFilter.dataScadenza = this.selectedDataScadenza;
    this.rateazioniService.getPopulateDropDown(this.filtro).subscribe(
      (data) => {
        if (data) {
          this.listNomeEnte = data.entiLabels;
          this.listCodiceCartella = data.listCodiceCartella;
          this.listCodiceEnte = data.listCodiceEnte;
          this.listCodiceRata = data.listCodiceRata;
          this.listNumeroRata = data.listNumeroRata; //manca da mockup VERIFICARE
          this.listDatePagamento = data.listDatePagamento;
          this.listScadenze = data.listScadenze;
          this.listStatiCartella = data.listStatiCartella;
          this.listTipoCartella = data.tipoCartella;
          this.listTipoRata = data.tipoRata;
          this.listDataScadenza = data.listScadenze;
          this.listDataEffettivoPagamento = data.listDatePagamento;
          this.filtro = data.filter;
        }
      },
      (err) => console.error(err),
      () => {
        //this.loading = false;
      }
    );
  }

  onChangeCodiceRata() {
    this.selectedTipoRata = null;
    this.selectedNumeroRata = null;
    this.populate();
  }
  onChangeTipoRata() {
    this.selectedNumeroRata = null;
    this.populate();
  }
  onChangeNumeroRata() {
    this.populate();
  }
  
  onChangeCodiceCartella() {
    this.selectedTipoCartella = null;
    this.selectedStato = null;
    this.populate();
  }
  onChangeTipoCartella() {
    this.selectedStato= null;
    this.populate();
  }
  onChangeStatoCartella() {
    this.populate();
  }
  
  onChangeCodiceEnte() {
    this.selectedNomeEnte = null;
    this.selectedSedeEnte = null;
    this.populate();
  }
  onChangeNomeEnte() {
    this.selectedSedeEnte = null;
    this.populate();
  }
  onChangeSedeEnte() {
    this.populate();
  }
  getIconProperties(type: string, rate: any): any {
    const configurations: Configurations = {
      ball: {
        className: rate.ballColor ? `icon-${rate.ballColor}` : 'icon-grey',
        titleMapping: {
          grey: 'Stato sconosciuto',
          orange: 'Rata in scadenza',
          red: 'Rata scaduta',
          blue: 'Rata in corso'
        },
        defaultTitle: 'Stato sconosciuto'
      },
      dollar: {
        className: rate.dollarColor ? `icon-${rate.dollarColor}` : 'icon-grey',
        titleMapping: {
          orange: 'Rata in scadenza',
          red: 'Non pagato',
          grey: 'Non pagato',
          green: 'Pagato'
        },
        defaultTitle: 'Stato sconosciuto'
      },
      calendar: {
        className: rate.calendarColor ? `icon-${rate.calendarColor}` : 'icon-grey',
        titleMapping: {
          grey: 'Non calendarizzato',
          blue: 'Pagamento programmato',
          green: 'Pagamento programmato'
        },
        defaultTitle: 'Non calendarizzato'
      }
    };
  
    const config = configurations[type];
  
    if (config) {
      const colorProperty = `${type}Color`; 
      const color = rate[colorProperty];
      return {
        className: config.className,
        title: config.titleMapping[color] || config.defaultTitle
      };
    }
  
    return {
      className: 'icon-gray',
      title: 'Not specified'
    };
  }

}
