<app-loader *ngIf="loading"></app-loader>
<nz-affix *ngIf="!loading" [nzOffsetTop]="60">
    <div class="row titleFilterHeader">
        <div class="col-12">
            <!--Page header: title, back, others actions-->
            <nz-page-header class="site-page-header px-2" (click)="onBack()" nzBackIcon nzTitle="Avvisi">
            </nz-page-header>
        </div>

        <div class="col-12">
            <!--Filters-->
            <nz-collapse nzGhost class="filtersPanel">
                <nz-collapse-panel [nzExpandedIcon]="expandedIcon" [nzActive]="false">
                    <ng-template #expandedIcon let-active>
                        <button
                            nz-button
                            nzType="primary"
                            nzSize="large"
                            class="filtersToggleButton"
                          >
                            <i nz-icon nzType="filter" nzTheme="outline"></i>
                            Filtra
                          </button>
                    </ng-template>
                
                    
                    <div class="row mb-3">
                        <div class="col-4">
                            <nz-radio-group [(ngModel)]="selectedTipoVista" nzSize="large" nzButtonStyle="solid" (ngModelChange)="goTo()">
                                <label nz-radio-button *ngFor="let vista of tipoVista" class="text-capitalize" [nzValue]="vista.link">{{ vista.nome }}</label>
                            </nz-radio-group>
                        </div>
                        <div class="col-4">
                            <nz-select [(ngModel)]="selectedCodiceEnte" nzSize="large" [nzAllowClear]="true" (ngModelChange)="updateList() " nzPlaceHolder="Codice Ente ">
                                <nz-option *ngFor="let ente of listaCodiciEnti" [nzValue]="ente.code" [nzLabel]="ente.name"></nz-option>
                            </nz-select>
                        </div>
                        
                        <div class="col-4">
                            <nz-select [(ngModel)]="selectedNomeEnte" nzSize="large" [nzAllowClear]="true" (ngModelChange)="updateList() " nzPlaceHolder="Nome Ente ">
                                <nz-option *ngFor="let ente of listaEnti" [nzValue]="ente.code" [nzLabel]="ente.name"></nz-option>
                            </nz-select>
                        </div>
                        
                    </div>
                    <div class="row mb-3">
                        <div class="col-4 ">
                            <nz-select [(ngModel)]="orderBy" nzSize="large" (ngModelChange)="updateList() " nzPlaceHolder="Ordina per">
                                <nz-option *ngFor="let ordinamento of ordinamenti" [nzValue]="ordinamento" [nzLabel]="ordinamento"></nz-option>
                            </nz-select>
                        </div>
                        
                        <div class="col-4">
                            <nz-select [(ngModel)]="selectedDataScadenza" nzSize="large" (ngModelChange)="updateList()" [nzAllowClear]="true" nzPlaceHolder="Data Scadenza">
                                <nz-option *ngFor="let nome of listDataScadenza" [nzValue]="nome.code" [nzLabel]="nome.name" ></nz-option>
                            </nz-select>
                        </div>

                        <div class="col-4 ">
                            <nz-select [(ngModel)]="selectedStato" nzSize="large" [nzAllowClear]="true" (ngModelChange)="updateList() " nzPlaceHolder="Stato">
                                <nz-option *ngFor="let stato of listaStati" [nzValue]="stato.code" [nzLabel]="stato.name"></nz-option>
                            </nz-select>
                        </div>
                    </div>

                    <div class="row justify-content-end ">
                        <div class="col-2">
                            <button nz-button nzType="primary" nzSize="large"  (click)="search()">
                        <i nz-icon nzType="search" nzTheme="outline"></i>
                        Cerca
                      </button>
                        </div>
                        <div class="col-2">
                            <button nz-button nzType="default" nzSize="large" (click)="resetFiltri()">
                              Reset filtri
                                </button>
                        </div>
                    </div>
                </nz-collapse-panel>
            </nz-collapse>
        </div>
    </div>
</nz-affix>
<br>
<div *ngIf="!loading " class="row ">
    <div class="col-12 ">
        <nz-table 
            #avvisiFilter 
            [nzData]="avvisi "
            [nzSize]=" 'small' "
            [nzFrontPagination]="false"
            [nzTotal]="totalPage"
            [nzPageIndex]="index_page"
            [nzPageSize]="1"
            (nzQueryParams)="onQueryParamsChange($event)"
        >
            <thead>
                <tr>
                    <th>Tipologia Ente</th>
                    <th>Stato</th>
                    <th>Codice Avviso</th>
                    <th>Data Ricezione</th>
                    <th>Data Scadenza</th>
                    <th>Importo</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let avviso of avvisiFilter.data " (click)="goToDetail(avviso.idAvviso)">
                    <td>{{avviso.tipologiaEnte}}</td>
                    <td>{{avviso.stato}}</td>
                    <td>{{avviso.codice}}</td>
                    <td>{{avviso.dataRicezione}}</td>
                    <td>{{avviso.dataScadenza}} </td>
                    <td>{{avviso.importo}}</td>
                    <td>
                        <i class="p-1 fas fa-circle" 
                            [ngClass]="getIconProperties('ball', avviso).className"
                            [title]="getIconProperties('ball', avviso).title"></i>
                        <i class="p-1 far fa-calendar-alt" 
                            [ngClass]="getIconProperties('calendar', avviso).className"
                            [title]="getIconProperties('calendar', avviso).title"></i>
                        <i class="p-1 fas fa-dollar-sign" 
                            [ngClass]="getIconProperties('dollar', avviso).className"
                            [title]="getIconProperties('dollar', avviso).title"></i>
                    </td>
                </tr>
            </tbody>
        </nz-table>
    </div>
</div>