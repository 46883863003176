import { Component, OnInit, Input, OnDestroy, Inject, LOCALE_ID } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { PagamentoRata, Rateazioni, UpdateRata } from 'src/app/models/rateazioniModel';
import { RateazioniService } from 'src/app/_services/rateazioni.service';
import { DatePipe, Location, formatDate } from '@angular/common';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ToolsService } from 'src/app/_services/tools.service';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { Allegato } from 'src/app/models/commonModel';

@Component({
  selector: 'app-dettaglio-rateazione',
  templateUrl: './dettaglio-rateazione.component.html',
  styleUrls: ['./dettaglio-rateazione.component.scss']
})

export class DettaglioRateazioneComponent implements OnInit, OnDestroy {
  isVisible = false;
  isOkLoading = false;
  dataInizioModal:any;
  noteModal:any;

  @Input('idRateazione')
  idRateazione!: number;
  detailRateazione!: Rateazioni;
  location!: Location;
  isVisibleEdit = false;
  isVisibleRegistraPagamento = false;
  isVisibleRevoca = false;
  rataSelezionata!: any;
  dataPagamento!: any;
  dataScadenza!: any;
  dataRevoca!: any;
  note!: string;
  limitedView: boolean = false;
  ricevutaPagamento?: Allegato;
  distintaPagamento?: Allegato;
  modelFileRcv: any;
  modelFileDst: any;
  subConvertFile?: Subscription;
  saveEventoSub?: Subscription;
  pagamentoRata: PagamentoRata = {
    idRata: 0,
    dataPagamento: '',
    idTipoPagamento: 0,
    note: '',
    bollettini: [],
    distinte: [], 
  }

  updateRata: UpdateRata = {
    idRata: 0,
    dataScadenza: '',
    importo: 0,
    importoIpotetico: 0,
    note: '',
    bollettini: [], 
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private rateazioniService: RateazioniService,
    private datePipe: DatePipe,
    private toolsService: ToolsService,
    private message: NzMessageService,
    @Inject(LOCALE_ID) public locale: string
  ) { }

  ngOnInit(): void {
    if (!this.idRateazione) {
      this.idRateazione = this.route.snapshot.params['id'];
    } else {
      this.limitedView = true;
    }
    this.getRatabyId();
  }


  onBack() {
    this.location.back();
  }

  getRatabyId() {
    this.rateazioniService.rateazioneDetail(this.idRateazione).subscribe(detail => {
      this.detailRateazione = detail;
    })
  }

  registraPagamento(rata: any) {
    this.rataSelezionata = rata;
    this.isVisibleRegistraPagamento = true;
  }

  handleOkPagamento() {
    if (this.dataPagamento) {
      this.dataPagamento = this.datePipe.transform(this.dataPagamento, 'dd/MM/yyyy');
    }
    this.pagamentoRata.dataPagamento = this.dataPagamento;
    if(this.ricevutaPagamento){
      this.pagamentoRata.distinte.push(this.ricevutaPagamento);
    }
    if(this.distintaPagamento){
      this.pagamentoRata.bollettini.push(this.distintaPagamento);
    }
    this.pagamentoRata.idRata = this.rataSelezionata.idRata;
    this.pagamentoRata.idTipoPagamento = this.rataSelezionata.idTipoPagamento;
    this.rateazioniService.pagamento(this.pagamentoRata).subscribe(res => {
      this.isVisibleRegistraPagamento = false;
    }, (err) => {
      this.message.error('Errore nel pagamento', {
        nzDuration: 10000
      });
    },
      () => {
        this.message.success('Pagamento registrato con successo', {
          nzDuration: 10000
        });
      })
  }

  edit(rata: any) {
    this.rataSelezionata = rata;
    this.isVisibleEdit = true;

  }
  
  convertFile(file : File) : Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => 
    result.next(btoa(event.target!.result!.toString()));
    return result;
  }

  handleFileInput(event: Event, tipo: any) {
    const element = event.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;
    if (fileList) {
      this.subConvertFile = this.convertFile(fileList[0]).subscribe(base64 => {
        let allegato: Allegato = {
          content: base64,
          tipo: 'documento',
          fileName: fileList![0].name,
          nome: '',
        }
        if (tipo === 'ricevuta') {
          this.modelFileRcv = null;
          this.ricevutaPagamento = allegato;
        }
        if (tipo === 'distinta') {
          this.modelFileDst = null;
          this.distintaPagamento = allegato;
        }
      });
    }
  }

  deleteAllegato(fileName:any, tipo:any) {
    if(tipo==='ricevuta'){
      this.ricevutaPagamento = undefined;
    }
    if(tipo==='distinta'){
      this.distintaPagamento = undefined;
    }
  }

  handleOk(): void {
    console.log('Button ok clicked!');
    this.isVisibleEdit = false;
    this.updateRata.idRata = this.rataSelezionata.idRata;
    if (this.rataSelezionata.dataScadenza) {
      this.dataScadenza = this.datePipe.transform(this.rataSelezionata.dataScadenza, 'dd/MM/yyyy');
    }
    this.updateRata.dataScadenza = this.dataScadenza;
    this.updateRata.importo = this.rataSelezionata.importo;
    this.updateRata.importoIpotetico = this.rataSelezionata.importoIpotetico;
    this.updateRata.note = this.rataSelezionata.note;
    if(this.distintaPagamento){
      this.updateRata.bollettini.push(this.distintaPagamento);
    }
    this.rateazioniService.update(this.updateRata).subscribe(res => {
      this.isVisibleRegistraPagamento = false;
    }, (err) => {
      this.message.error('Errore nella modifica', {
        nzDuration: 10000
      });
    },
      () => {

        this.message.success('Modifica effettuata  con successo', {
          nzDuration: 10000
        });
        this.getRatabyId();
      })
  }

  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisibleEdit = false;
    this.isVisibleRevoca = false;
    this.isVisibleRegistraPagamento = false;
  }


  revocaRateazione() {
    this.isVisibleRevoca = true;
  }

  handleOkRevoca(idRateazione: any) {
    this.rateazioniService.revocaRateazione(idRateazione).subscribe(detail => {
      this.isVisibleRevoca = false;
    })
  }

  showEventModal(): void {
    this.isVisible = true;
  }

  handleEventOk(): void {
    this.isOkLoading = true;
    const selectedIdAzienda = Number(sessionStorage.getItem("selectedStore"));
    const formattedDataInizio = formatDate(this.dataInizioModal, 'dd/MM/yyyy' ,this.locale);
    this.saveEventoSub = this.toolsService.saveEventoCalendario({
      dataInizio: formattedDataInizio,
      idAzienda: selectedIdAzienda,
      note: this.noteModal ? this.noteModal : '',
      tipologia: 'AV'
    }).subscribe({
        next: (data) => {
          if (data.esito)
            this.message.success(data.message);
          else
            this.message.error('Errore durante il salvataggio dell\'evento:' + data?.message);
          this.isVisible = false;
          this.isOkLoading = false;
          this.dataInizioModal = null;
          this.noteModal = null;
        },
        error: (error) => { 
          this.isVisible = false;
          this.isOkLoading = false;
          this.dataInizioModal = null;
          this.noteModal = null;
        }
      })
  }

  handleEventCancel(): void {
    this.dataInizioModal = null;
    this.noteModal = null;
    this.isVisible = false;
  }
  
  ngOnDestroy(): void {
    this.subConvertFile?.unsubscribe();
    this.saveEventoSub?.unsubscribe();
  }

}
