import { Router } from "@angular/router";
import { Component, OnInit, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Location } from "@angular/common";
import { Save } from "src/app/models/entiModel";
import { EntiService } from "src/app/_services/enti.service";
import { AdempimentiService } from "src/app/_services/adempimenti.service";

@Component({
  selector: "app-nuovo-adempimento",
  templateUrl: "./nuovo-adempimento.component.html",
  styleUrls: ["./nuovo-adempimento.component.scss"],
})
export class NuovoAdempimentoComponent implements OnInit {
  nuovoAdempimentoForm!: FormGroup;
  location!: Location;
  isDisabled: boolean = false;
  submitForm(): void {
    if (this.nuovoAdempimentoForm.valid) {
      this.save();
    } else {
      Object.values(this.nuovoAdempimentoForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  constructor(
    private fb: FormBuilder,
    private adempimentiService: AdempimentiService,
    private router: Router
  ) {}
  public params: any = {
    codice: "",
    label: "",
    clonabile: "",
    idTipologiaAdempimenti: ""
  };
  ngOnInit(): void {
    
    if(history.state.codice){
      this.isDisabled=true;
    }
    this.nuovoAdempimentoForm = this.fb.group({
      formLayout: ["horizontal"],
      codice: [history.state.codice,  [Validators.required]],
      label: [history.state.label, [Validators.required]],
      clonabile: [history.state.clonabile],
    });
  }

  save() {
    this.params.codice = this.nuovoAdempimentoForm.value.codice;
    this.params.label = this.nuovoAdempimentoForm.value.label;
    this.params.clonabile = this.nuovoAdempimentoForm.value.clonabile ? true : false;
    if(this.isDisabled){
    this.params.idTipologiaAdempimenti = history.state.idTipologiaAdempimenti;
    }
    else this.params.idTipologiaAdempimenti = null;
    this.adempimentiService.getAdempimentoSave(this.params).subscribe(
      (data) => {
        if (data) {
          this.router.navigate(["tipo-adempimenti"]);
        }
      },
      (err) => console.error(err),
      () => {
        //this.loading = false;
      }
    );
  }

  onBack() {
    this.location.back();
  }
}
