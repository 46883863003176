<div class="row">
    <div class="col-7">
        <h3 class="mb-5">Main dashboard</h3>
    </div>
    <div class="col-5 d-flex justify-content-end">
        <ul id="numbersList" class="d-flex align-items-start justify-content-start list-unstyled">
            <li class="d-flex flex-column me-5 text-end" (click)="goToAmounts()">
                <span>{{importiScadenza}} €</span>
                <span>Importi in scadenza nel mese</span>
            </li>
            <li class="d-flex flex-column text-end" (click)="goToAmounts()">
                <span>{{importiScadenzaSucc}} €</span>
                <span>Importi in scadenza mese successivo</span>
            </li>
        </ul>
    </div>
    
</div>
<div class="row items mt-4">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
        <div class="row subRow">
            <div class="col-4">
                <nz-card [routerLink]="['/avvisi']">
                    <p class="title">Avvisi ricevuti (totali)</p>
                    <p class="content">{{ avvisi }}</p>
                </nz-card>
            </div>
            <div class="col-4">
                <nz-card [routerLink]="['/cartelle']">
                    <p class="title">Rateazioni richieste</p>
                    <p class="content">{{ rateazioniRichieste }}</p>
                </nz-card>
            </div>
            <div class="col-4">
                <nz-card [routerLink]="['/cartelle']">
                    <p class="title">Rateazioni approvate</p>
                    <p class="content">{{ rateazioniApprovate }}</p>
                </nz-card>
            </div>
            <div class="col-4 mt-2">
                <nz-card [routerLink]="['/rateazioni']">
                    <p class="title">Rate da pagare nel mese</p>
                    <p class="content">{{ rateDaPagare }}</p>
                </nz-card>
            </div>
            <div class="col-4 mt-2">
                <nz-card [routerLink]="['/rateazioni']">
                    <p class="title">Rate in ritardo</p>
                    <p class="content">{{ rateInRitardo }}</p>
                </nz-card>
            </div>
            <div class="col-4 mt-2">
                <nz-card [routerLink]="['/rateazioni']">
                    <p class="title">Rate a rischio revoca</p>
                    <p class="content">{{ rateRischioRevoca }}</p>
                </nz-card>
            </div>
        </div>

    </div>
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 mt-2 mt-xl-0">
        <div class="row subRow">
            <div class="col-12 col-sm-12 col-md-6">
                <nz-card style="height: 100%;" [routerLink]="['/budget']">
                    <p class="title mb-0">Budget / Pagato nel mese</p>
                    <canvas
                    baseChart
                    class="chart"
                    [data]="doughnutChartData"
                    [type]="doughnutChartType"
                    >
                    </canvas>
                </nz-card>
            </div>
            <div class="col-12 col-sm-12 col-md-6">
                <nz-card style="height: 100%">
                    <div id="mainDashboardCalendar" [ngStyle]="{ width: '100%', height: '100%', borderRadius: '4px' }">
                        <nz-calendar
                            [nzDateCell]="dateCellTpl"
                            [nzFullscreen]="false"
                            (nzSelectChange)="onValueChange($event)"
                            (nzPanelChange)="onPanelChange($event)">
                        </nz-calendar>
                        <ng-template #dateCellTpl let-date>
                            <span *ngIf="getCalendarPropertiesDate(date).ball"><i class="fas fa-circle"></i></span>
                            <div *ngIf="getCalendarPropertiesDate(date).tipo != 'undefined'" style="position: relative; height: 100%;">
                                <div style="position: absolute; bottom: 0; left: 15px;  white-space: nowrap;">
                                    <span>{{getCalendarPropertiesDate(date).tipo}}</span>
                                </div>
                            </div>
                        </ng-template>
                      </div>
                </nz-card>
                
            </div>
        </div>
    </div>
</div>