import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Budget, BudgetDetail } from "../models/rateazioniModel";
import { Filtro } from "../models/filtriModel";

@Injectable({
    providedIn: 'root'
})

export class BudgetService {
    constructor(private http: HttpClient) { }

    saveBudget(datiBudget: Budget) {
        console.log(datiBudget);
        return this.http.post(`${environment.apiPath}/budget/save`, datiBudget);
    }

    searchBudget(filtro: Filtro) {
        return this.http.post<any>(`${environment.apiPath}/budget/search`, filtro);
    }

    getBudgetById(idBudget: number) {
        return this.http.post<BudgetDetail>(`${environment.apiPath}/budget/detail`, 
            { },
            { params: new HttpParams().set('idBudget', idBudget) 
        });
    }

}