<nz-affix [nzOffsetTop]="60">
  <div class="row titleFilterHeader">
    <div class="col-12">
      <!--Page header: title, back, others actions-->
      <nz-page-header
        class="site-page-header px-2"
        (click)="onBack()"
        nzBackIcon
        nzTitle="Registrazione/ Modifica Ente"
      ></nz-page-header>
    </div>
  </div>
</nz-affix>
<div class="row customersList">
  <div class="col-12 mt-5">
    <div class="card">
      <form nz-form [formGroup]="nuovoEnteForm" (ngSubmit)="submitForm()" class="ant-advanced-search-form">
        <div nz-row [nzGutter]="24">
          <div nz-col [nzSpan]="24">
            <div class="row">
              <nz-form-item class="col-4" >
                <nz-form-label [nzNoColon]="true">Codice Ente</nz-form-label>
                <nz-form-control nzErrorTip="Inserisci codice ente!">
                  <input
                    nz-input
                    nzSize="large"
                    formControlName="fieldA"
                    placeholder="Inserisci Codice Ente"
                    [disabled]= "isDisabled" 
                  />
                </nz-form-control>
              </nz-form-item>
              <nz-form-item class="col-4">
                <nz-form-label [nzNoColon]="true">Nome Ente</nz-form-label>
                <nz-form-control nzErrorTip="Inserisci nome ente!">
                  <input
                    nz-input
                    nzSize="large"
                    formControlName="fieldB"
                    placeholder="Inserisci Nome Ente"
                  />
                </nz-form-control>
              </nz-form-item>
              <nz-form-item class="col-4">
                <nz-form-label [nzNoColon]="true" 
                >Sede Ente</nz-form-label>
                <nz-form-control nzErrorTip="Inserisci sede ente!">
                  <input
                    nz-input
                    nzSize="large"
                    formControlName="fieldC"
                    placeholder="Inserisci Sede Ente"
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="row">
              <nz-form-item class="col-4">
                <nz-form-label [nzNoColon]="true">Indirizzo Ente</nz-form-label>
                <nz-form-control nzErrorTip="Inserisci indirizzo ente!">
                  <input
                    nz-input
                    nzSize="large"
                    formControlName="fieldD"
                    placeholder="Inserisci Indirizzo Ente"
                  />
                </nz-form-control>
              </nz-form-item>
              <nz-form-item class="col-4">
                <nz-form-control>
                  <label nz-checkbox formControlName="fieldE">dettagli aggiuntivi</label>
                </nz-form-control>
              </nz-form-item>
            </div>
            <nz-form-item>
              <nz-form-control>
                <div nz-row>
                  <div nz-col [nzSpan]="24" class="search-area">
                    <button
                      nz-button
                      nzSize="large"
                      nzType="primary"
                      class="mt-3"
                      (submit)="submitForm()"
                    >
                      Salva
                    </button>
                  </div>
                </div>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
