import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { BudgetService } from 'src/app/_services/budget.service';
import { BudgetDetail } from 'src/app/models/rateazioniModel';

@Component({
  selector: 'app-dettaglio-budget',
  templateUrl: './dettaglio-budget.component.html',
  styleUrls: ['./dettaglio-budget.component.scss']
})
export class DettaglioBudgetComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private budgetService: BudgetService,
  ) { }

  loading = false;
  location!: Location;
  getBudgetSub?: Subscription;
  detail: BudgetDetail = {
    dataFine: '',
    dataInizio: '',
    idAzienda: 0,
    idBudget: 0,
    importoSelezionato: 0,
    importoStanziato: 0,
    rate: [],
    ratePagate: 0,
    rateinserite: 0
  };
  date = [new Date(), new Date()];
  dtFormat = 'dd/MM/yyyy';

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id')!;
    console.log('dettaglio budget', id);
    // if(id){
      this.getBudgetSub = this.budgetService.getBudgetById(+id).subscribe({
        next: (data) => {
          console.log('dettaglio budget =', data);
          this.detail = data;
          data.dataInizio;
          data.dataFine;

          const _dataInizio: string[] = this.detail.dataInizio?.split('/');
          const _dataFine: string[] = this.detail.dataFine?.split('/');
          let dataInizio = _dataInizio ? new Date(+_dataInizio[2], +_dataInizio[1], +_dataInizio[0]) : new Date();
          let dataFine = _dataFine ? new Date(+_dataFine[2], +_dataFine[1], +_dataFine[0]) : new Date();
          this.date = [dataInizio, dataFine];
          console.log('date caricate = ', this.date)
        },
        error: (err) => {},
        complete: () => {},
      })
    // }
  }

  onBack() {
    this.location.back();
  }

}
