<ng-container>
    <nz-affix [nzOffsetTop]="60">
        <div class="row titleFilterHeader">
            <div class="col-12">
                <!--Page header: title, back, others actions-->
                <nz-page-header class="site-page-header px-2" (click)="onBack()" nzBackIcon nzTitle="Calendar">
                </nz-page-header>
            </div>
        </div>
    </nz-affix>
    <app-calendar-pagamenti></app-calendar-pagamenti>
</ng-container>