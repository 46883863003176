    <div class="row login">
      <div class="col-12 d-flex align-items-center">
        <div class="row d-flex mx-auto w-50 login-form">
          <h2 class="mb-5">
              <img class="img-fluid" src="assets/images/logoIGF.jpg" alt="Igf business intelligence" />
          </h2>
          <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="col-12 flex flex-column mb-2 mt-4">
              <input nz-input placeholder="username" nzSize="large" type="text" class="w-100" formControlName="username"/>
              <div *ngIf="submitted && f['username'].errors" class="invalid-feedback">
                  <div *ngIf="f['username'].errors['required']">Username is required</div>
              </div>
            </div>
            <div class="col-12 flex flex-column mb-4">
              <nz-form-item>
                <nz-input-group nzSize="large"
                  [nzSuffix]="suffixTemplate" >
                  <input 
                  nz-input 
                  placeholder="password" 
                  [type]="passwordVisible ? 'text' : 'password'" 
                  class="w-100"
                  formControlName="password"
                  />
                </nz-input-group>
                <ng-template #suffixTemplate>
                  <span
                  nz-icon
                  [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                  (click)="passwordVisible = !passwordVisible"
                  ></span>
                </ng-template>
              </nz-form-item>
            <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
              <div *ngIf="f['password'].errors['required']">Password is required</div>
            </div>
            </div>
            <div class="action form-group d-flex flex-column">
              <button nz-button nzType="primary" [disabled]="loginForm.invalid" nzSize="large" nzBlock class="text-center loginButton w-100" [nzLoading]="loading">Login</button>
            </div>
          </form>
        </div>
      </div>
      <div class="col-12 text-center version">
       
        <img class="img-fluid" style="height: 30px;" src="assets/images/logo_orizzon.jpg" alt="Technode" />
      </div>
    </div>
  
