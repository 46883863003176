import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { EventoCalendario } from '../models/eventoModel';


@Injectable({
  providedIn: 'root'
})
export class ToolsService {

  constructor(private http: HttpClient) { }

  resetFilter(filtro:string) {
    return this.http.get<any>(environment.apiPath + '/tools/reset-filter?filter_name='+ filtro)
      .pipe(map(results => {
        return results;
      }));
  }
  
  download(idFile: any) {
    return this.http.get(`${environment.apiPath}/tools/download?idFile=${idFile}`, {
      responseType: 'blob',
    });
  }

  saveEventoCalendario(params: EventoCalendario) {
    return this.http.post<any>(environment.apiPath + '/tools/calendar/add', params)
    .pipe(map(results => {
      return results;
    }));
  }

}