import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Filtro } from '../models/filtriModel';
import { NuovaCartella, RateizzaAdempimento, RichiestaRateazione, UpdateCartella } from 'src/app/models/cartellaModel';


@Injectable({
  providedIn: 'root'
})
export class CartellaService {

  constructor(private http: HttpClient) { }

  
  getCartellaInit() {
    return this.http.get<any>(environment.apiPath + '/adempimento/init')
      .pipe(map(results => {
        return results;
      }));
  }

  getAggiuntiviEnte(id:any) {
    return this.http.get<any>(environment.apiPath + '/tools/aggiuntivi-ente?id=' + id)
      .pipe(map(results => {
        return results;
      }));
  }

  getPopulateDropDown(filtro:Filtro) {
    return this.http.post<any>(environment.apiPath + '/adempimento/populate', filtro)
      .pipe(map(results => {
        return results;
      }));
  }

  search(filtro:Filtro) {
    return this.http.post<any>(environment.apiPath + '/adempimento/search', filtro)
      .pipe(map(results => {
        return results;
      }));
  }

  detail(idAdempimento:any) {
    return this.http.post<any>(environment.apiPath + '/adempimento/detail?idAdempimento=' + idAdempimento, {})
      .pipe(map(results => {
        return results;
      }));
  }

  addDettaglio(idCartelle:any, dettaglio:any, importo:any) {
    return this.http.post<any>(environment.apiPath + '/adempimento/save-dettaglio?dettaglio='+ dettaglio +'&idAdempimento='+ idCartelle +'&importo='+ importo, {})
      .pipe(map(results => {
        return results;
      }));
  }

  deleteDettaglio(idAdempimento:any, idDettaglio:any) {
    return this.http.post<any>(environment.apiPath + '/adempimento/delete-dettaglio?idAdempimento='+ idAdempimento +'&idDettaglio='+ idDettaglio, {})
      .pipe(map(results => {
        return results;
      }));
  }

  deleteAllegato(idAdempimento:any, idAllegato:any) {
    return this.http.post<any>(environment.apiPath + '/adempimento/delete-allegato?idAdempimento='+ idAdempimento +'&idAllegato='+ idAllegato, {})
      .pipe(map(results => {
        return results;
      }));
  }

  uploadFile(idAdempimento:any, file:File) {
    const formData = new FormData();
    formData.append("file", file);
    return this.http.post<any>(environment.apiPath + '/adempimento/save-allegato?idAdempimento='+ idAdempimento, formData,
    {}).pipe(map(results => {
        return results;
      }));
  }

  saveCartella(cartella:NuovaCartella) {
    return this.http.post<any>(environment.apiPath + '/adempimento/save', 
      cartella
    )
      .pipe(map(results => {
        return results;
      }));
  }

  updateCartella(cartella:UpdateCartella) {
    return this.http.post<any>(environment.apiPath + '/adempimento/update', 
      cartella
    )
      .pipe(map(results => {
        return results;
      }));
  }

  richiesta(richiesta:RichiestaRateazione) {
    return this.http.post<any>(environment.apiPath + '/adempimento/richiesta', 
      richiesta
    )
      .pipe(map(results => {
        return results;
      }));
  }

  rateizza(richiesta:RateizzaAdempimento) {
    return this.http.post<any>(environment.apiPath + '/adempimento/rateizza', 
      richiesta
    )
      .pipe(map(results => {
        return results;
      }));
  }


  saveInit(idAvviso:any) {
    if(idAvviso === null ||  idAvviso === undefined) {
      return this.http.post<any>(environment.apiPath + '/adempimento/init-save', {})
      .pipe(map(results => {
        return results;
      }));
    } else {
    return this.http.post<any>(environment.apiPath + '/adempimento/init-save?idAvviso='+ idAvviso, {})
      .pipe(map(results => {
        return results;
      }));
    }
  }

}
