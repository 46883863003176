<app-loader *ngIf="loading"></app-loader>
<nz-page-header class="site-page-header px-2" (click)="onBack()" nzBackIcon nzTitle="Dettaglio Rateazione / Adempimento"></nz-page-header>
<div class="col-12 mb-3">
  <!--Dati Avviso-->
  <div class="row" *ngIf="viewAvviso">
    <nz-card nzTitle="Avviso">
      <div class="row">
        <div class="col">
            Tipologia
            <nz-select [(ngModel)]="avviso.tipologiaEnte" (ngModelChange)="onChangeTipologiaEnte()" [disabled]="true">
                <nz-option nzValue="1 - Ente Singolo" nzLabel="1 - Ente Singolo"></nz-option>
                <nz-option nzValue="2 - Agenzia Entrate" nzLabel="2 - Agenzia Entrate"></nz-option>
                <nz-option nzValue="3 - Agenzia Entrate e Riscossione" nzLabel="3 - Agenzia Entrate e Riscossione"></nz-option>
              </nz-select>
        </div>
        <div class="col">

        </div>
        <div class="col">

        </div>
        <div class="col">

        </div>
      </div>
      <br>
      <div class="row">
            <div class="col">
              Ente
              <nz-select [(ngModel)]="selectedEnte" [nzAllowClear]="true" nzPlaceHolder="Seleziona Ente " [disabled]="true">
                <nz-option *ngFor="let ente of enti" [nzValue]="ente.code" [nzLabel]="ente.name"></nz-option>
              </nz-select>
              </div>
              <div class="col">
                Sede Ente
                <input nz-input placeholder="Sede Ente" [(ngModel)]="sedeEnte" [disabled]="true"/>
              </div>
              <div class="col">
                Anno Riferimento
                <input nz-input placeholder="Anno Riferimento" [(ngModel)]="avviso.riferimentoAnno" [disabled]="true"/>
              </div>
              <div class="col">
                Codice Identificativo
                <input nz-input placeholder="Codice" [(ngModel)]="avviso.codice" [disabled]="true"/>
              </div>
      </div>
      <br>
      <div class="row">
          <div class="col">
            Data Ricezione <br>
            <nz-date-picker [(ngModel)]="avviso.dataRicezione" nzFormat="dd/MM/yyyy" [disabled]="true"></nz-date-picker>
            </div>
            <div class="col">
             Data Scadenza
              <nz-date-picker [(ngModel)]="avviso.dataScadenza" nzFormat="dd/MM/yyyy" [disabled]="true"></nz-date-picker>
            </div>
            <div class="col">
              Importo
              <input nz-input placeholder="Importo " [(ngModel)]="avviso.importo" [disabled]="true"/>
            </div>
            <div class="col">
              Note
              <input nz-input placeholder="Note" [(ngModel)]="avviso.note" />
            </div>
      </div>
    </nz-card>
</div>
<!-- Dati Rateazine se Avviso null -->
<div class="row" *ngIf="!viewAvviso">
  <nz-card nzTitle="Dati Rateazione / Adempimento">
    <div class="row">
      <div class="col">
          Tipologia
          <nz-select [(ngModel)]="adempimento.tipologiaEnte" (ngModelChange)="onChangeTipologiaEnte()" [disabled]="true">
              <nz-option nzValue="1 - Ente Singolo" nzLabel="1 - Ente Singolo"></nz-option>
              <nz-option nzValue="2 - Agenzia Entrate" nzLabel="2 - Agenzia Entrate"></nz-option>
              <nz-option nzValue="3 - Agenzia Entrate e Riscossione" nzLabel="3 - Agenzia Entrate e Riscossione"></nz-option>
            </nz-select>
      </div>
      <div class="col">

      </div>
      <div class="col">

      </div>
      <div class="col">

      </div>
    </div>
    <br>
    <div class="row">
          <div class="col">
            Ente
            <input nz-input placeholder="Nome Ente" [(ngModel)]="adempimento.nomeEnte" [disabled]="!checkTipo"/>
            </div>
            <div class="col">
              Sede Ente
              <input nz-input placeholder="Sede Ente" [(ngModel)]="adempimento.sedeEnte" [disabled]="!checkTipo"/>
            </div>
            <div class="col">
              Anno Riferimento
              <input nz-input placeholder="Anno Riferimento" [(ngModel)]="adempimento.riferimentoAnno" [disabled]="!checkTipo"/>
            </div>
            <div class="col">
              Codice Identificativo
              <input nz-input placeholder="Codice" [(ngModel)]="adempimento.codice" [disabled]="!checkTipo"/>
            </div>
    </div>
  </nz-card>
</div>
<!-- Dettagli Rateazione se avviso null -->
<div class="d-flex flex-row-reverse" *ngIf="!viewAvviso && dettaglioRateazione">
  <div class="p-2 bd-highlight">
    <button nz-button nzSize="large" nzType="primary" class="mt-3" (click)="dettaglio()">
      Aggiungi dettaglio
    </button> 
  </div>
</div>
  <br>
  <div class="row" *ngIf="!viewAvviso && dettaglioRateazione">
      <div class="col-12">
        <nz-table #basicTable [nzData]="adempimento.dettagli">
          <thead>
            <tr>
              <th>Ente</th>
              <th>Sede Ente</th>
              <th>Anno</th>
              <th>Importo</th>
              <th>Note</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of adempimento.dettagli">
              <td>{{ item.codiceEnte }}</td>
              <td>{{ item.sedeEnte }}</td>
              <td>{{ item.riferimentoAnno }}</td>
              <td>{{ item.importo }}</td>
              <td>{{ item.note }}</td>
            </tr>
          </tbody>
        </nz-table>
      </div>
  </div>
<!-- Dettagli da Avviso-->
<div class="d-flex flex-row-reverse" *ngIf="viewAvviso">
  <div class="p-2 bd-highlight">
     
  </div>
</div>
  <br>
  <div class="row" *ngIf="viewAvviso && avviso.dettagli">
      <div class="col-12">
        <nz-table #basicTable [nzData]="avviso.dettagli">
          <thead>
            <tr>
              <th>Ente</th>
              <th>Sede Ente</th>
              <th>Anno</th>
              <th>Importo</th>
              <th>Note</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of avviso.dettagli">
              <td>{{ item.codiceEnte }}</td>
              <td>{{ item.sedeEnte }}</td>
              <td>{{ item.riferimentoAnno }}</td>
              <td>{{ item.importo }}</td>
              <td>{{ item.note }}</td>
            </tr>
          </tbody>
        </nz-table>
      </div>
  </div>
 

<br>
    <div class="row">
        <div class="col">
          Tipologia
          <nz-select [(ngModel)]="adempimento.tipo" (ngModelChange)="onChangeTipologia()" nzPlaceHolder="Seleziona Tipologia" [disabled]="true">
            <nz-option nzValue="Rateazione" nzLabel="Rateazioni"></nz-option>
            <nz-option nzValue="Adempimento" nzLabel="Adempimenti"></nz-option>
          </nz-select>
          </div>
          <div class="col">
           
          </div>
          <div class="col">
            
          </div>
          <div class="col">
            <button nz-button nzType="primary" (click)="showEventModal()">
                <span>Nuova Rateazione in calandario</span>
            </button>
            <nz-modal
                [(nzVisible)]="isVisible"
                [nzOkLoading]="isOkLoading"
                [nzOkDisabled]="!dataInizioModal"
                nzTitle="Nuova evento rateazione"
                (nzOnCancel)="handleEventCancel()" 
                (nzOnOk)="handleEventOk()"
            >
            <ng-container *nzModalContent>
                <div class="row">
                  <div class="col-6">
                    Data inizio
                    <nz-date-picker nzFormat="dd/MM/yyyy"  [(ngModel)]="dataInizioModal" ></nz-date-picker>
                  </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        note
                        <textarea nz-input placeholder="Note" [(ngModel)]="noteModal" ></textarea>
                    </div>
                </div>
            </ng-container>
            </nz-modal>
          </div>
    </div>
    <br>
    <div class="row">
        <div class="col">
          Data Richiesta <br>
          <nz-date-picker *ngIf="editRichiesta" [(ngModel)]="dataRichiesta" nzFormat="dd/MM/yyyy" [disabled]="!editRichiesta"></nz-date-picker>
          <input *ngIf="!editRichiesta" nz-input placeholder="" [(ngModel)]="adempimento.dataRichiesta" [disabled]="true"/>
        </div>
        <div class="col">
         
        </div>
        <div class="col" *ngIf="viewProtocollo">
        Data Protocollo Accettazione <br>
        <nz-date-picker *ngIf="editProtocollo" [(ngModel)]="dataProtocollo" nzFormat="dd/MM/yyyy" [disabled]="!editProtocollo"></nz-date-picker>
        <input *ngIf="!editProtocollo" nz-input placeholder="" [(ngModel)]="adempimento.dataProtocollo" [disabled]="true"/>
        </div>
        <div class="col" *ngIf="!viewProtocollo">
          
        </div>
        <div class="col" *ngIf="viewProtocollo">
          Numero Protocollo
          <input nz-input placeholder="Numero Protocollo" [(ngModel)]="adempimento.numeroProtocollo" [disabled]="!editProtocollo"/>
        </div>
        <div class="col" *ngIf="!viewProtocollo">
          
        </div>
        
    </div>
    <br>
    <div class="row">
        
        <div class="col">
          Importo Effettivo
          <input nz-input placeholder="Importo Effettivo" [(ngModel)]="adempimento.importoEffettivo" [disabled]="!editRichiesta"/>
        </div>
        <div class="col">
          Importo Ipotetico
          <input nz-input placeholder="Importo Ipotetico" [(ngModel)]="adempimento.importoIpotetico" [disabled]="!editRichiesta"/>
        </div>
        <div class="col">
        
        </div>
        <div class="col">
          Note
          <input nz-input placeholder="Note" [(ngModel)]="note" [disabled]="!editRichiesta"/>
        </div>
    </div>
    <br>
    <div class="row" *ngIf="viewProtocollo">
      <div class="col-3">
        Numero Ruolo
        <input nz-input placeholder="Numero Ruolo" [(ngModel)]="adempimento.numeroRuolo" [disabled]="!editProtocollo"/>
        </div>
        <div class="col-3">
          Responsabile Procedimento
          <input nz-input placeholder="Responsabile Procedimento" [(ngModel)]="adempimento.responsabileProcedimento" [disabled]="!editProtocollo"/>
        </div>
        <div class="col-6">

        </div>
  </div>
<br>
<div  class="row">
    <div class="col-12">
      <div>
        <div nz-row [nzGutter]="8">
          <div nz-col [nzSpan]="6" *ngIf="!viewProtocollo">
            <nz-card nzTitle="Richieste">
              <p>Allega file
                <input id="file" type="file" [(ngModel)]="modelFileRichieste" (change)="handleFileInput($event, 'richiesta')" /></p>
                <br>
                <div class="row" *ngFor="let allegatoB of documentiRichiesta; let n=index">
                  <div class="col">
                      Nome allegato
                      <input nz-input placeholder="nota" [(ngModel)]="allegatoB.fileName" [disabled]="true" class="disabledBox" />
                  </div>
                  <div class="col">
                      <br>
                      <button nz-button nzType="primary" nzSize="small" style="background-color: brown; border-color: brown;" (click)="deleteAllegato(allegatoB.fileName, 'richieste')">
                        <i nz-icon nzType="minus" nzTheme="outline"></i>
                      </button>
                  </div>
                  <div class="col">
              
                  </div>
              </div>
            </nz-card>
          </div>
          <div nz-col [nzSpan]="6" *ngIf="viewProtocollo">
            <nz-card nzTitle="Notifiche">
              <p>Allega file
                <input id="file" type="file" [(ngModel)]="modelFileNotifica" (change)="handleFileInput($event, 'notifica')" /></p>
                <br>
                <div class="row" *ngFor="let allegato of documentiNotifica; let n=index">
                  <div class="col"  *ngIf="allegato.id == null">
                      Nome allegato
                      <input nz-input [(ngModel)]="allegato.fileName" [disabled]="true" class="disabledBox" />
                  </div>
                  <div class="col"  *ngIf="allegato.id != null">
                    Nome allegato
                    <input nz-input [(ngModel)]="allegato.nome" [disabled]="true" class="disabledBox" />
                  </div>
                  <div class="col"  *ngIf="allegato.id == null">
                      <br>
                      <button nz-button nzType="primary" nzSize="small" style="background-color: brown; border-color: brown;" (click)="deleteAllegato(allegato.fileName, 'notifica')">
                        <i nz-icon nzType="minus" nzTheme="outline"></i>
                      </button>
                  </div>
                  <div class="col" *ngIf="allegato.id != null">
                    <br>
                    <button nz-button (click)="download(allegato)" nzType="primary">
                        <i class="fas fa-download fa-2x"></i>
                    </button>
                  </div>
              </div>
            </nz-card>
          </div>
          <div nz-col [nzSpan]="6" *ngIf="viewProtocollo">
            <nz-card nzTitle="Rateazioni">
              <p>Allega file
                <input id="file" type="file" [(ngModel)]="modelFileRateazioni" (change)="handleFileInput($event, 'rateazione')" /></p>
                <br>
                <div class="row" *ngFor="let allegatoR of documentiRateazione; let n=index">
                  <div class="col"  *ngIf="allegatoR.id == null">
                    Nome allegato
                    <input nz-input [(ngModel)]="allegatoR.fileName" [disabled]="true" class="disabledBox" />
                  </div>
                  <div class="col"  *ngIf="allegatoR.id != null">
                    Nome allegato
                    <input nz-input [(ngModel)]="allegatoR.nome" [disabled]="true" class="disabledBox" />
                  </div>
                  
                  <div class="col" *ngIf="allegatoR.id == null">
                      <br>
                      <button nz-button nzType="primary" nzSize="small" style="background-color: brown; border-color: brown;" (click)="deleteAllegato(allegatoR.fileName, 'rateazioni')">
                        <i nz-icon nzType="minus" nzTheme="outline"></i>
                      </button>
                  </div>
                  <div class="col" *ngIf="allegatoR.id != null">
                    <br>
                    <button nz-button (click)="download(allegatoR)" nzType="primary">
                        <i class="fas fa-download fa-2x"></i>
                    </button>
                  </div>
                  
              </div>
            </nz-card>
          </div>
          <div nz-col [nzSpan]="6">
            <nz-card nzTitle="Bollettini" *ngIf="viewProtocollo">
              <p>Allega file
                <input id="file" type="file" [(ngModel)]="modelFileBollettini" (change)="handleFileInput($event, 'bollettini')" /></p>
                <br>
                <div class="row" *ngFor="let allegatoB of documentiBollettini; let n=index">
                  <div class="col"  *ngIf="allegatoB.id == null">
                    Nome allegato
                    <input nz-input [(ngModel)]="allegatoB.fileName" [disabled]="true" class="disabledBox" />
                </div>
                <div class="col"  *ngIf="allegatoB.id != null">
                  Nome allegato
                  <input nz-input [(ngModel)]="allegatoB.nome" [disabled]="true" class="disabledBox" />
                </div>
                <div class="col">
                  Nome allegato
                  <input nz-input [(ngModel)]="allegatoB.fileName" [disabled]="true" class="disabledBox" />
                </div>
                <div class="col"  *ngIf="allegatoB.id == null">
                    <br>
                    <button nz-button nzType="primary" nzSize="small" style="background-color: brown; border-color: brown;" (click)="deleteAllegato(allegatoB.fileName, 'notifica')">
                      <i nz-icon nzType="minus" nzTheme="outline"></i>
                    </button>
                </div>
                <div class="col" *ngIf="allegatoB.id != null">
                  <br>
                  <button nz-button (click)="download(allegatoB)" nzType="primary">
                      <i class="fas fa-download fa-2x"></i>
                  </button>
                </div>
              </div>
            </nz-card>
          </div>
        </div>
      </div>
    </div>
</div>
<br>
<div class="row">
  <div class="col">
      <button *ngIf="editRichiesta" nz-button nzSize="large" nzType="primary" class="mt-3" (click)="saveRichiesta()">
          Salva Richiesta
        </button> 
        <button *ngIf="!editRichiesta && !editProtocollo && !viewRateazioneLink" nz-button nzSize="large" nzType="primary" class="mt-3" (click)="save()">
          Salva
        </button> 
        <button *ngIf="!editRichiesta && editProtocollo" nz-button nzSize="large" nzType="primary" class="mt-3" (click)="rateizza()">
          Rateizza
        </button>
        
    </div>
    <div class="col">
    </div>
    <div class="col" *ngIf="!viewRateazioneLink">
      <button  nz-button nzSize="large" nzType="primary" class="mt-3" (click)="nuovaRateazione()">
        Crea Rateazzione
      </button>
    </div>
    <div class="col">
      <button *ngIf="viewRateazioneLink" nz-button nzSize="large" nzType="primary" class="mt-3" (click)="showRateazione()">
        Visualizza Rateazione
      </button>
    </div>
</div>





<!-- modale dettaglio rateazione-->
<ng-container>
  <nz-modal [(nzVisible)]="isVisibleEdit" nzTitle="Dettaglio Rateazione" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
      <ng-container *nzModalContent>
          <div class="row">
            <div class="col">
              ente
              <nz-select [(ngModel)]="selectedEnteModal" [nzAllowClear]="true" nzPlaceHolder="Seleziona Ente">
                <nz-option *ngFor="let ente of enti" [nzValue]="ente.code" [nzLabel]="ente.name"></nz-option>
              </nz-select>
              </div>
              <div class="col">
                Sede ente
                <input nz-input placeholder="Sede Ente" [(ngModel)]="sedeEnteModal" />
              </div>
          </div>
          <div class="row">
              <div class="col">
                Anno
               <input nz-input placeholder="Anno Riferimento" [(ngModel)]="annoRifModal" />
              </div>
              <div class="col">
                  Importo
                <input nz-input placeholder="Importo" [(ngModel)]="importoModal" />
              </div>
          </div>
          <div class="row">
            <div class="col-6">
               note
               <input nz-input placeholder="Note" [(ngModel)]="NoteModal" />
              </div>
              <div class="col-6">
              </div>
          </div>
      </ng-container>
  </nz-modal>
</ng-container>
