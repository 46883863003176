import { Component, OnInit } from '@angular/core';
import { CartellaRateizzata } from 'src/app/models/dashboard/cartellaRateizzataModel';
import { DashboardService } from "src/app/_services/dashboard.service"

@Component({
  selector: 'app-cartelle-rateizzate',
  templateUrl: './cartelle-rateizzate.component.html',
  styleUrls: ['./cartelle-rateizzate.component.scss']
})
export class CartelleRateizzateComponent implements OnInit {
  cartelleRateizzate: any[] = [];
  cartelle: CartellaRateizzata[] = [
    {
      "id": 287,
      "tipo": "DIFFERITA",
      "ente": {
      "codice": 533940,
      "sede": "Padova",
      "nome": "INPS"
      },
      "statoPagamenti": {
        "ratePagate": 12,
      "rateScadute": 5,
      "nTotale": 120,
      "colore": "red"
      },
      "pagatoComplessivo": {
        "importoPagato": 5000.0,
      "importoTotale": 50000.0,
      "importoScaduto": 2083.33,
      "colore": "red"
      }
    },
    {
      "id": 325,
      "tipo": "DIFFERITA",
      "ente": {
      "codice": 533940,
      "sede": "Padova",
      "nome": "INPS"
      },
      "statoPagamenti": {
        "ratePagate": 8,
      "rateScadute": 4,
      "nTotale": 20,
      "colore": "red"
      },
      "pagatoComplessivo": {
        "importoPagato": 8000.0,
      "importoTotale": 20000.0,
      "importoScaduto": 4000.0,
      "colore": "red"
      }
    },
    {
      "id": 398,
      "tipo": "DIFFERITA",
      "ente": {
      "codice": 533940,
      "sede": "Padova",
      "nome": "INPS"
      },
      "statoPagamenti": {
        "ratePagate": 2,
      "rateScadute": 2,
      "nTotale": 10,
      "colore": "orange"
      },
      "pagatoComplessivo": {
        "importoPagato": 200000.0,
      "importoTotale": 1000000.0,
      "importoScaduto": 200000.0,
      "colore": "orange"
      }
    },
    {
      "id": 402,
      "tipo": "DIFFERITA",
      "ente": {
      "codice": 533940,
      "sede": "Padova",
      "nome": "INPS"
      },
      "statoPagamenti": {
        "ratePagate": 20,
      "rateScadute": 1,
      "nTotale": 60,
      "colore": "green"
      },
      "pagatoComplessivo": {
        "importoPagato": 20000.0,
      "importoTotale": 60000.0,
      "importoScaduto": 1000.0,
      "colore": "green"
      }
    }
  ]
  constructor(
    private dashboardService: DashboardService
  ) { }

  ngOnInit(): void {
    this.dashboardService.getCartelleRateizzate().subscribe(
      (data) => {
        if (data) {
              this.cartelleRateizzate = data;
        }
      },
      (err) => console.error(err),
      () => {
    
      }
      );
  }


}
