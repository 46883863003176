
export const regexConfig = {
    email: '^[A-Za-z0-9._%+-]{2,}@[a-zA-Z-_.]{2,}[.]{1}[a-zA-Z]{2,}$',
    password: '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@$%:;<>,.?~_+-]).{8,16}$',
};

export const embedVideo = {
    embedUrl: 'https://www.youtube.com/embed/',
}

export const basic = {
    basic: 'Basic VmlzaXRhRHViYWk6RXhwb0R1YmFpMjAyMFBhZGlnbGlvbmUxdGFsaWE'
}

export interface DropdownList {
    name: string;
    code: string;
}
export function extract(items: any, field: any): DropdownList[] {
    return items.reduce((a: any, e: any) => {
        Object.keys(e).forEach(key => {
            if (e[key] && key === field) {
                const str = e[key].charAt(0).toUpperCase() + e[key].slice(1).toLowerCase();
                a.push({ code: str, name: str });
            }
        });
        return a;
    }, []).filter((el: any, i: any, a: any) => i === a.findIndex((fel: any) => fel.code === el.code)).sort((a: any, b: any) => {
        if (a.code < b.code) { return -1; }
        if (a.code > b.code) { return 1; }
        return 0;
    });
}