import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { Filtro } from "src/app/models/filtriModel";
import { EntiService } from "src/app/_services/enti.service";
import { ToolsService } from "src/app/_services/tools.service";
import { Location } from "@angular/common";
import { NzTableQueryParams } from "ng-zorro-antd/table";

@Component({
  selector: "app-lista-enti",
  templateUrl: "./lista-enti.component.html",
  styleUrls: ["./lista-enti.component.scss"],
})
export class ListaEntiComponent implements OnInit {
  constructor(private entiService: EntiService,
            private toolsService: ToolsService,
                  private router: Router) {}

  location!: Location;
  listaEnti: any = [];
  listaCodici: any = [];
  listaNomi: any = [];
  listaSede: any = [];
  selectedActiveCodiceEnte: any = null;
  selectedActiveNomeEnte: any = null;
  selectedActiveSedeEnte: any = null;
  totalPage = 0;
  index_page = 0;
  rowNumber = 10;
  selectedTipoVista = "enti";
  filterName = "ENTE_FILTER";
  tipoVista = [
    { id: 1, nome: "adempimenti" },
    { id: 2, nome: "rate" },
    { id: 3, nome: "enti" },
  ];

  public filtro: Filtro = {    
  cartellaFilter: {
    codiceCartella: null,
    codiceEnte: null,
    codiceRata: null,
    dataScadenza: null,
    idStatoCarella: 0,
    nomeEnte: null,
    order: null,
    page: 0,
    rowNumber: 10,
    tipoCartella: null,
    tipoRata: null
  },
    enteFilter: {
      codice: null,
      nome: null,
      sede: null,
      page: 0,
      rowNumber: 10,
    },
    idAzienda: 1,
    rateazioneFilter: {
      codiceCartella: null,
      codiceEnte: null,
      codiceRata: null,
      dataPagamento: null,
      nomeEnte: null,
      numeroRata: 0,
      order: null,
      page: 0,
      rowNumber: 10,
      sedeEnte: null,
      tipoCartella: null,
      tipoRata: null,
	    idStatoCartella: null,
			dataScadenza: null,
			pagato: null,
			scaduta: null,
    }, 
    avvisoFilter: {
      nomeEnte: null,
      codiceEnte: null,
      stato: null,
      dataScadenza: null,
      order: null,
      page: 0,
      rowNumber: 10,
    },
    budgetFilter: {
      dataInizio: null,
      dataFine: null,
      order: null,
      page: 0,
      rowNumber: 10,
    },
  };
  ngOnInit(): void {
    this.getEntiInit(); 
  }

  goTo() {
    this.router.navigate(["/" + this.selectedTipoVista]);
  }

  onBack() {
    this.location.back();
  }

  onChangeCodiceEnte() {
    this.selectedActiveNomeEnte = null;
    this.selectedActiveSedeEnte = null;
    this.populate();
  }
  onChangeNomeEnte() {
    this.selectedActiveSedeEnte = null;
    this.populate();
  }
  onChangeNomeSede() {
    this.populate();
  }
  cercaFiltri() {
    this.filtro.enteFilter.page = 0;
    this.filtro.enteFilter.codice = this.selectedActiveCodiceEnte;
    this.filtro.enteFilter.nome = this.selectedActiveNomeEnte;
    this.filtro.enteFilter.sede = this.selectedActiveSedeEnte;
    this.filtro.enteFilter.rowNumber = this.rowNumber;
    this.entiService.getEnteSearch(this.filtro).subscribe(
      (data) => {
        if (data) {
          this.listaEnti = data;
          this.selectedActiveCodiceEnte =
            this.listaEnti.filter.enteFilter.codice;
          this.selectedActiveNomeEnte = this.listaEnti.filter.enteFilter.nome;
          this.selectedActiveSedeEnte = this.listaEnti.filter.enteFilter.sede;                    
          this.filtro= this.listaEnti.filter;
          this.totalPage = this.listaEnti.numberPage;
          this.index_page = this.listaEnti.filter.enteFilter.page! + 1;
          this.rowNumber = this.listaEnti.filter.enteFilter.rowNumber;
        }
      },
      (err) => console.error(err),
      () => {
        //this.loading = false;
      }
    );
    this.populate();
  }

  populate() {
    this.filtro.enteFilter.page = 0;
    this.filtro.enteFilter.codice = this.selectedActiveCodiceEnte;
    this.filtro.enteFilter.nome = this.selectedActiveNomeEnte;
    this.filtro.enteFilter.sede = this.selectedActiveSedeEnte;
    this.entiService.getPopulateDropDown(this.filtro).subscribe(
      (data) => {
        if (data) {
          this.listaCodici = data.listaCodici;
          this.listaNomi = data.listaNomi;
          this.listaSede = data.listaSede;                    
          this.filtro= data.filter;
        }
      },
      (err) => console.error(err),
      () => {
        //this.loading = false;
      }
    );
  }

  edit(a: any, b: any, c: any, d: any, e: any, f: any) {
    this.router.navigateByUrl("enti/nuovo-ente", {
      state: { codice: a, nome: b, sede: c, indirizzo: d, idEnte: e, aggiuntivi: f },
    });
  }
  onQueryParamsChange(e: NzTableQueryParams) {
    this.filtro.enteFilter.page = e.pageIndex - 1;
    if (this.filtro.enteFilter.page == -1) {
      this.filtro.enteFilter.page = 0;
    }
    setTimeout (() => {
   }, 1000);
    this.entiService.getEnteSearch(this.filtro).subscribe(
      (data) => {
        if (data) {
          this.listaEnti = data;
          this.selectedActiveCodiceEnte =
            this.listaEnti.filter.enteFilter.codice;
          this.selectedActiveNomeEnte = this.listaEnti.filter.enteFilter.nome;
          this.selectedActiveSedeEnte = this.listaEnti.filter.enteFilter.sede;          
          this.filtro = this.listaEnti.filter;
          this.totalPage = this.listaEnti.numberPage;
          this.index_page = this.listaEnti.filter.enteFilter.page! + 1;
          this.rowNumber = this.listaEnti.filter.enteFilter.rowNumber;
        }
      },
      (err) => console.error(err),
      () => {
        //this.loading = false;
      }
    );
    this.populate();
  }

  resetFilter() {
    this.toolsService.resetFilter(this.filterName).subscribe(
      (data) => {
        if (data) {
          this.listaEnti = data;
          this.selectedActiveCodiceEnte = null;
          this.selectedActiveNomeEnte = null;
          this.selectedActiveSedeEnte = null;
          this.filtro = this.listaEnti.filter;
          this.getEntiInit();
          this.populate();
        }
      },
      (err) => console.error(err),
      () => {
        //this.loading = false;
      }
    );
  }

  getEntiInit() {
    this.entiService.getEnteInit().subscribe(
      (data) => {
        if (data) {
          this.listaEnti = data;
          this.selectedActiveCodiceEnte =
            this.listaEnti.filter.enteFilter.codice;
          this.selectedActiveNomeEnte = this.listaEnti.filter.enteFilter.nome;
          this.selectedActiveSedeEnte = this.listaEnti.filter.enteFilter.sede;
          this.filtro= this.listaEnti.filter;
          this.totalPage = this.listaEnti.numberPage;
          this.index_page = this.listaEnti.filter.enteFilter.page! + 1;
          this.rowNumber = this.listaEnti.filter.enteFilter.rowNumber;
          this.listaCodici = this.listaEnti.listaCodici;
          this.listaNomi = this.listaEnti.listaNomi;
          this.listaSede = this.listaEnti.listaSede;
         this.populate();
        }
      },
      (err) => console.error(err),
      () => {
        //this.loading = false;
      }
    );
  }

  nuovaCartella(codice:any, nome:any, sede:any, idEnte:any, aggiuntivi:any){
    this.router.navigateByUrl('/cartelle/nuova-cartella?codice='+codice+'&nome='+nome+'&sede='+sede+'&idEnte='+idEnte+'&aggiuntivi='+aggiuntivi);
  }
}
