<header>
    <!-- NAV MENU-->
    <div class="widget-nav">
        <div class="navi">
            <app-nav></app-nav>
        </div>
    </div>

    <!-- LOGO -->
    <div class="logo">
        <img class="img-fluid" src="assets/images/logoIGF.jpg" alt="Igf business intelligence" (click)="router.navigate(['/'])" />
    </div>

    <!-- USER, TOOLS -->
    <div class="user-tool d-none d-md-flex justify-content-xs-end justify-content-sm-end flex-xs-grow-1 flex-sm-grow-1">
        <div class="magazzino">
            <i class="far fa-warehouse"></i>
            <nz-select [(ngModel)]="selectedStore" (ngModelChange)="updateStore(true)" class="w-100">
                <nz-option *ngFor="let store of stores" [nzValue]="store" [nzLabel]="store.name"></nz-option>
            </nz-select>
        </div>
        <div class="user">
            <i class="fad fa-user"></i>
            <span>{{name}}</span>
        </div>
        <div class="logout" (click)="logout()">
            <i class="far fa-sign-out-alt"></i>
            <span>Logout</span>
        </div>
    </div>

</header>