import { Component, OnInit } from '@angular/core';
import { Customer } from 'src/app/models/customersModel';
import { DropdownList, extract } from 'src/utils/utils';
import { Location } from '@angular/common';
import { AnagraphicService } from 'src/app/_services/anagraphic/anagraphic.service';

@Component({
  selector: 'app-customers-list',
  templateUrl: './customers-list.component.html',
  styleUrls: ['./customers-list.component.scss']
})
export class CustomersListComponent implements OnInit {

  location!: Location;

  customers!: Customer[];
  loading = false;

  cities!: DropdownList[];
  provinces!: DropdownList[];
  agents!: DropdownList[];
  regions!: DropdownList[];
  activeOpt!: DropdownList[];

  selectedActiveOpt = 'A';
  selectedAgent!: DropdownList;
  selectedCity!: DropdownList;
  selectedProvince!: DropdownList;
  selectedRegion!: DropdownList;

  constructor(
    private anagraphicService: AnagraphicService
  ) { }

  async ngOnInit() {
    this.loading = true;
    await this.getCustomersList();
    await this.getCustomersNumbers();
  }

  private async getCustomersList() {
    this.anagraphicService.getCustomers().subscribe({
      next: (data) => {
          if(data !== null){
            this.customers = data;
            this.cities = extract(data, 'comune');
            this.provinces = extract(data, 'provincia');
            this.regions = extract(data, 'regione');
            this.agents = extract(data, 'nominativoAgente');
            this.loading = false;
          }
      },
      error: (err) => {
          console.log('err');
      },
      complete: () => {
      }
    })
  }

  private async getCustomersNumbers() {
    this.anagraphicService.getNumberClients()
    .subscribe({
      next: (data) => {
        console.log("getNumberClients Result:", data);
        this.activeOpt = [
            { name: 'Attivi: '+ data.attivi, code: 'A' },
            { name: 'Non Attivi: '+ data.nonAttivi, code: 'N' }
        ];
      },
      error: (err) => {
          console.log('err');
      },
      complete: () => {
      }
    })
  }

  async updateList() {

  }

  onBack() {
    this.location.back();
  }

}
