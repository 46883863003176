export class NavMenuItem {
    label?: string;
    styleClass?: string;
    icon?: string;
    routerLink?: string;
    status?: string;
    items?: NavMenuItem[];
    childrenVisible?: boolean;
}

export const side_menu: NavMenuItem[] = [
   
    {
        label: 'Elenchi',
        styleClass: 'anagraphic',
        items: [
            { label: 'ratezioni', icon: 'fas fa-folder',  routerLink: '/cartelle', status: 'show'},
            { label: 'rate', icon: 'fas fa-divide',  routerLink: '/rateazioni', status: 'show'},
            { label: 'avvisi', icon: 'fas fa-bell',  routerLink: '/avvisi', status: 'show'},
            { label: 'budget', icon: 'fas fa-wallet', routerLink: '/budget', status: 'show'},
        ]
    }, {
        label: 'Configurazione',
        styleClass: 'anagraphic',
        items: [
            { label: 'enti', icon: 'fas fa-home',  routerLink: '/enti', status: 'show'}
        ]
    }
];
