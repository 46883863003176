import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.scss']
})
export class CalendarioComponent implements OnInit {

  location!: Location;
  constructor() { }

  ngOnInit(): void {
  }

  onBack() {
    this.location.back();
  }

}
