import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Avviso, NuovoAvviso } from 'src/app/models/avvisoModel';
import { Filtro } from '../models/filtriModel';


@Injectable({
  providedIn: 'root'
})

export class AvvisoService {
  
  constructor(private http: HttpClient) { }

  
  getAvvisiInit() {
    return this.http.get<any>(environment.apiPath + '/avviso/init')
      .pipe(map(results => {
        return results;
      }));
  }

  // getPopulateDropDown(filtro: Filtro) {
  //   return this.http.post<any>(environment.apiPath + '/avviso/populate', filtro)
  //     .pipe(map(results => {
  //       return results;
  //     }));
  // }
  
  search(filtro:Filtro) {
    return this.http.post<any>(environment.apiPath + '/avviso/search', filtro)
      // .pipe(map(results => {
      //   return results;
      // }));
  }

  getAvvisoInitSave(idEnte: any) {
    return this.http.post<any>(environment.apiPath + '/avviso/init-save', {})
      .pipe(map(results => {
        return results;
      }));
  }

  save(params: NuovoAvviso) {
    return this.http.post<any>(environment.apiPath + '/avviso/save', params)
    .pipe(map(results => {
      return results;
    }));
  }

  update(avviso: Avviso) {
    return this.http.post(`${environment.apiPath}/avviso/update`, avviso);
  }

  detail(idAvviso: any) {
    return this.http.post<any>(`${environment.apiPath}/avviso/detail?idAvviso=${idAvviso}`, {})
  }
  
}