import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { User } from '../models/userModel';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  baseUrl: any = environment.apiPath;
  isLogged: boolean = false;
  token: any = null;
  username = '';

  constructor(private http: HttpClient ) {

  }

  login(username: string, password: string): Observable<User> {
    return this.http.post<any>(this.baseUrl + '/authentication/login', { username: username, password: password });
  }

  logout() {
    return this.http.post<any>(this.baseUrl + '/authentication/logout', {}).pipe(map(() => this.clearSession()));
  }

  setAzienda(idAzienda: number) {
    return this.http.post<any>(environment.apiPath + '/authentication/setAzienda?idAzienda='+idAzienda, {})
      .pipe(map(results => {
        return results;
      }));
  }

  public get allowedUrls() {
    const currentUser: any = this.getUser();
    if(currentUser){
      return currentUser['roles'].map(
          (item: any) => item.permissions.map((perm: any) => perm.routes.map((r: any) => r.url)).flat()
      ).flat().map((aUrl: string | string[]) => {
          if (aUrl.indexOf('/') !== 0) {
              return '/' + aUrl;
          } else {
              return null;
          }
      });
    }
  }

  public getUser(){
    if(sessionStorage.getItem('currentUser') && sessionStorage.getItem('currentUser') !== null){
      return JSON.parse(sessionStorage.getItem('currentUser')!);
    } else {
      return null;
    }
  }

  clearSession() {
    sessionStorage.removeItem('currentUser');
    sessionStorage.removeItem('selectedYear');
    sessionStorage.removeItem('selectedStore');
    sessionStorage.removeItem('userRoles');
  }
  
  // Error handling
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }
  
}
