import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { UserService } from '../../_services/user.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
    passwordVisible = false;
    loginForm!: FormGroup;
    loading = false;
    submitted = false;
    returnUrl!: string;
    error!: string;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService
    ) {
        // redirect to home if already logged in
        if (this.userService.getUser() && this.userService.getUser() !== null) {
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: new FormControl('', [Validators.required]),
            password: new FormControl('', [Validators.required]),
        });

        //get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    //convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;

        //stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this.userService.login(this.f['username'].value, this.f['password'].value)
            .subscribe({
                next: (data: any) => {
                    this.router.navigate([this.returnUrl]);
                    this.userService.username = data.username;
                    sessionStorage.setItem('userRoles', JSON.stringify(data.roles));
                    sessionStorage.setItem('currentUser', JSON.stringify(data));
                    this.loading = false; 
                },
                error: (err: any) => {
                    this.error = err;
                    this.loading = false; 
                },
                complete: () => {
                    console.log('complete');
                }
            })
    }
}
