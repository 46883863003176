import { Component, OnInit } from '@angular/core';
import { CartellaNonRateizzata } from 'src/app/models/dashboard/cartellaNonRateizzataModel';
import { DashboardService } from "src/app/_services/dashboard.service"



@Component({
  selector: 'app-cartelle-non-rateizzate',
  templateUrl: './cartelle-non-rateizzate.component.html',
  styleUrls: ['./cartelle-non-rateizzate.component.scss']
})
export class CartelleNonRateizzateComponent implements OnInit {
cartelleNonRateizzate: any[] = [];

  constructor(
	private dashboardService: DashboardService
  ) { }

  ngOnInit(): void {
    this.dashboardService.getCartelleNonRateizzate().subscribe(
		(data) => {
		  if (data) {
            this.cartelleNonRateizzate = data;
		  }
		},
		(err) => console.error(err),
		() => {
  
		}
	  );
  }

  getClass(cartella: CartellaNonRateizzata) {
	if(cartella.coloreRiga === '') {
		return cartella.notifica.colore
		
	} else {
		return ''
	}
  }
}
