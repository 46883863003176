<app-loader *ngIf="loading"></app-loader>
<nz-affix *ngIf="!loading" [nzOffsetTop]="60">
    <div class="row titleFilterHeader">
        <div class="col-12">
            <nz-page-header class="site-page-header px-2" (click)="onBack()" nzBackIcon nzTitle="Nuovo Budget">
                <nz-page-header-extra>
                </nz-page-header-extra>
            </nz-page-header>
        </div>

        <div class="row mb-3 d-flex justify-content-center">

            <div class="col-5 ">
                seleziona data inizio fine
                <br>
                <nz-range-picker [(ngModel)]="date" (ngModelChange)="onChange($event)"
                    [nzFormat]="dtFormat"></nz-range-picker>

            </div>
            <div class="col-3">
                Budget Stanziato
                <br>
                <input nz-input placeholder="Budget Stanziato" [(ngModel)]="budgetStanziato" />
            </div>
            <div class="col-3">
                Totale selezionate
                <br>
                <input nz-input placeholder="Totale selezionato" [(ngModel)]="totaleSelezionate" disabled />
            </div>
            <div class="col-1">
                <button nz-button nzSize="large" nzType="primary" class="mt-3" (click)="save()">Salva</button>
            </div>
        </div>

        <!-- <div class="col-12 mb-3">
            <nz-collapse nzGhost class="filtersPanel">
                <nz-collapse-panel [nzExpandedIcon]="expandedIcon" [nzActive]="true">
                    <ng-template #expandedIcon let-active>
                        <button nz-button nzType="primary" nzSize="large" class="filtersToggleButton">
                            <i nz-icon nzType="filter" nzTheme="outline"></i>
                            Filtra
                        </button>
                    </ng-template>
                    <div class="row">
                        <div class="col-3">
                            <nz-select [(ngModel)]="selectedCodiceRata" nzSize="large" [nzAllowClear]="true"
                                (ngModelChange)="onChangeCodiceRata()" nzPlaceHolder="Codice Rata ">
                                <nz-option *ngFor="let codice of listCodiceRata" [nzValue]="codice.code"
                                    [nzLabel]="codice.name"></nz-option>
                            </nz-select>
                        </div>
                        <div class="col-3">
                            <nz-select [(ngModel)]="selectedTipoRata" nzSize="large" [nzAllowClear]="true"
                                (ngModelChange)="onChangeTipoRata()" nzPlaceHolder="Tipo Rata ">
                                <nz-option *ngFor="let tipo of listTipoRata" [nzValue]="tipo.code"
                                    [nzLabel]="tipo.name"></nz-option>
                            </nz-select>
                        </div>
                        <div class="col-3">
                            <nz-select [(ngModel)]="selectedNumeroRata" nzSize="large" [nzAllowClear]="true"
                                (ngModelChange)="onChangeNumeroRata()" nzPlaceHolder="Numer Rata ">
                                <nz-option *ngFor="let codice of listNumeroRata" [nzValue]="codice.code"
                                    [nzLabel]="codice.name"></nz-option>
                            </nz-select>
                        </div>
                    </div>
                    <br />
                    <div class="row">
                        <div class="col-3">
                            <nz-select [(ngModel)]="selectedDataScadenza" nzSize="large" [nzAllowClear]="true"
                                nzPlaceHolder="Data Scadenza">
                                <nz-option *ngFor="let nome of listDataScadenza" [nzValue]="nome.code"
                                    [nzLabel]="nome.name"></nz-option>
                            </nz-select>
                        </div>
                        <div class="col-3">
                            <nz-select [(ngModel)]="selectedCodiceCartella" nzSize="large" [nzAllowClear]="true"
                                (ngModelChange)="onChangeCodiceCartella()" nzPlaceHolder="Codice Rateazione ">
                                <nz-option *ngFor="let codice of listCodiceCartella" [nzValue]="codice.code"
                                    [nzLabel]="codice.name"></nz-option>
                            </nz-select>
                        </div>
                        <div class="col-3">
                            <nz-select [(ngModel)]="selectedTipoCartella" nzSize="large" [nzAllowClear]="true"
                                (ngModelChange)="onChangeTipoCartella()" nzPlaceHolder="Tipo Rateazione">
                                <nz-option *ngFor="let tipoCartella of listTipoCartella" [nzValue]="tipoCartella.code"
                                    [nzLabel]="tipoCartella.name"></nz-option>
                            </nz-select>
                        </div>
                        <div class="col-3">
                            <nz-select [(ngModel)]="selectedStato" nzSize="large" [nzAllowClear]="true"
                                (ngModelChange)="onChangeStatoCartella()" nzPlaceHolder="Stato">
                                <nz-option *ngFor="let stato of listStatiCartella" [nzValue]="stato.code"
                                    [nzLabel]="stato.name"></nz-option>
                            </nz-select>
                        </div>
                    </div>
                    <br />
                    <div class="row">
                        <div class="col-3">
                            <nz-select [(ngModel)]="selectedDataEffettivoPagamento" nzSize="large" [nzAllowClear]="true"
                                nzPlaceHolder="Data Effettivo Pagamento">
                                <nz-option *ngFor="let nome of listDataEffettivoPagamento" [nzValue]="nome.code"
                                    [nzLabel]="nome.name"></nz-option>
                            </nz-select>
                        </div>
                        <div class="col-3">
                            <nz-select [(ngModel)]="selectedCodiceEnte" nzSize="large" [nzAllowClear]="true"
                                (ngModelChange)="onChangeCodiceEnte()" nzPlaceHolder="Codice Ente ">
                                <nz-option *ngFor="let cod of listCodiceEnte" [nzValue]="cod.code"
                                    [nzLabel]="cod.name"></nz-option>
                            </nz-select>
                        </div>
                        <div class="col-3">
                            <nz-select [(ngModel)]="selectedNomeEnte" nzSize="large" [nzAllowClear]="true"
                                (ngModelChange)="onChangeNomeEnte()" nzPlaceHolder="Nome Ente">
                                <nz-option *ngFor="let nome of listNomeEnte" [nzValue]="nome.code"
                                    [nzLabel]="nome.name"></nz-option>
                            </nz-select>
                        </div>
                        <div class="col-3">
                            <nz-select [(ngModel)]="selectedSedeEnte" nzSize="large" [nzAllowClear]="true"
                                (ngModelChange)="onChangeSedeEnte()" nzPlaceHolder="Sede Ente">
                                <nz-option *ngFor="let sede of listSedeEnte" [nzValue]="sede.code"
                                    [nzLabel]="sede.name"></nz-option>
                            </nz-select>
                        </div>
                    </div>
                    <br />
                    <div class="row">
                        <div class="col-3">
                            <nz-select [(ngModel)]="orderBy" nzSize="large" nzPlaceHolder="Ordina per">
                                <nz-option *ngFor="let ordinamento of orderBy" [nzValue]="ordinamento.code"
                                    [nzLabel]="ordinamento.name"></nz-option>
                            </nz-select>
                        </div>
                    </div>
                    <div class="row justify-content-end">
                        <div class="col-2">
                            <button nz-button nzType="primary" nzSize="large" (click)="cercaFiltri()">
                                <i nz-icon nzType="search" nzTheme="outline"></i>
                                Cerca
                            </button>
                        </div>
                        <div class="col-2">
                            <button nz-button nzType="default" nzSize="large" (click)="resetFiltri()">
                                Reset filtri
                            </button>
                        </div>
                    </div>
                </nz-collapse-panel>
            </nz-collapse>
        </div> -->
    </div>
</nz-affix>


<!-- <div class="d-flex justify-content-center mb-3">
        <app-loader *ngIf="loading"></app-loader>
        <nz-affix *ngIf="!loading" [nzOffsetTop]="60">
        </nz-affix> -->
<div *ngIf="!loading" class="row w-100">
    <div class="col-12">
        <nz-table #rateazioniFilter [nzData]="listaRateazioni.results" [nzSize]="'small'" [nzFrontPagination]="false"
            [nzTotal]="totalPage" [nzPageIndex]="index_page" [nzPageSize]="1"
            (nzQueryParams)="onQueryParamsChange($event)">
            <thead>
                <tr>
                    <th></th>
                    <th>N° RATA</th>
                    <th>Rata</th>
                    <th>Ente</th>
                    <th>SCADENZA</th>
                    <th>IMPORTO</th>
                    <th>IPOTETICO</th>
                    <th>SCADUTO</th>
                    <th>STATO</th>
                    <th>PAGATO</th>
                    <th>DATA EFFETTIVO PAGAMENTO</th>
                    <th>NOTE</th>
                </tr>
            </thead>
            <tbody>
                <tr></tr>
                <tr *ngFor="let rata of rateazioniFilter.data let i = index" class="{{ rata?.colorRow }}">
                    <td>
                        <label nz-checkbox [ngModel]="isSelected(rata)" (change)="selectRow(rata)"></label>
                    </td>
                    <td>{{ rata.numeroRata }} di {{ rata.numeroRate }}</td>
                    <td>
                        {{ rata.codiceCartella }} <br />
                        {{ rata.tipologiaAdempimento }}
                    </td>
                    <td>
                        {{ rata.codiceEnte }} <br />
                        {{ rata.nomeEnte }} <br />
                        {{ rata.sedeEnte }}
                    </td>
                    <td>
                        {{ rata.scadenza }} <br />
                        <div class="{{ rata.colorScadenza }} dettaglioScadenza">
                            {{ rata.scadenzaLabel }}
                        </div>
                    </td>
                    <td>{{ rata.importo }} €</td>
                    <td>{{ rata.importoIpotetico }} €</td>
                    <td>{{ rata.scadute }} {{ rata.scaduteLabel }}</td>
                    <td>
                        {{ rata.stato }}
                    </td>
                    <td>
                        {{ rata.pagamento }}
                    </td>
                    <td>
                        {{ rata.dataPagamento }}
                    </td>
                    <td>
                        {{ rata.note }}
                    </td>
                </tr>
            </tbody>
        </nz-table>
    </div>
</div>