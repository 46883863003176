import { Component, OnInit } from '@angular/core';
import { DashboardService } from "src/app/_services/dashboard.service"

@Component({
  selector: 'app-rate-prioritarie',
  templateUrl: './rate-prioritarie.component.html',
  styleUrls: ['./rate-prioritarie.component.scss']
})
export class RatePrioritarieComponent implements OnInit {

  rate: any = [];

  constructor(
    private dashboardService: DashboardService
  ) { }

  ngOnInit(): void {
    this.dashboardService.getListaRatePrioritarie().subscribe(
      (data) => {
        if (data) {
          this.rate = data;
        }
      },
      (err) => console.error(err),
      () => {
    
      }
      );
  }

}
