<div class="d-flex mb-3">
    <div class="p-2 bd-highlight">
      <button nz-button nzType="primary" (click)="nuovaCartella()">
        <i nz-icon nzType="plus" nzTheme="outline"></i>
        Nuova Rateazione / Adempimento
      </button>
    </div>
    <div class="me-auto p-2 bd-highlight">
      <button nz-button nzType="primary" (click)="nuovoAvviso()">
        <i nz-icon nzType="plus" nzTheme="outline"></i>
        Nuovo Avviso
      </button>
    </div>
    <div class="p-2 bd-highlight">
      <button nz-button nzType="primary" (click)="apriCalendario()">
        <span nz-icon nzType="calendar" nzTheme="outline"></span>
      </button>
    </div>
    <div class="p-2 bd-highlight">
      <button nz-button nzType="primary" (click)="apriRateazioni()">
        <span nz-icon nzType="search" nzTheme="outline"></span>
      </button>
    </div>
</div>
<br>
<!--
<div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-12">
        <app-lista-rate></app-lista-rate>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-12">
        <app-rate-prioritarie></app-rate-prioritarie>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-12">
        <app-cartelle-rateizzate></app-cartelle-rateizzate>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-12">
        <app-calendar-pagamenti></app-calendar-pagamenti>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-12">
        <app-cartelle-non-rateizzate></app-cartelle-non-rateizzate>
    </div>

</div>

<div class="row">
    
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-10 col-12">
        <app-calendar-pagamenti></app-calendar-pagamenti>
    </div>

</div>-->
<div class="d-flex justify-content-center mb-3">
    <ng-container>
        <app-loader *ngIf="loading"></app-loader>
        <nz-affix *ngIf="!loading" [nzOffsetTop]="60">
      </nz-affix>
      <div *ngIf="!loading" class="row w-100">
        <div class="col-12">
          <nz-table
            #rateazioniFilter
            [nzData]="listaRateazioni.results"
            [nzSize]="'small'"
            [nzFrontPagination]="false"
            [nzTotal]="totalPage"
            [nzPageIndex]="index_page"
            [nzPageSize]="1"
            (nzQueryParams)="onQueryParamsChange($event)"
          >
            <thead>
              <tr>
                <th>N° RATA</th>
                <th>Rata</th>
                <th>Ente</th>
                <th>SCADENZA</th>
                <th>IMPORTO</th>
                <th>IPOTETICO</th>
                <th>SCADUTO</th>
                <th>STATO</th>
                <th>PAGATO</th>
                <th>DATA EFFETTIVO PAGAMENTO</th>
                <th>NOTE</th>
              </tr>
            </thead>
            <tbody>
              <tr></tr>
              <tr
                *ngFor="let rata of rateazioniFilter.data"
                class="{{ rata?.colorRow }}"
                [routerLink]="['/rateazioni/dettaglio', { id: rata.idRateazione }]"
              >
              <td>{{ rata.numeroRata }} di {{ rata.numeroRate }}</td>
                <td>
                  {{ rata.codiceCartella }} <br />
                  {{ rata.tipologiaAdempimento }}
                </td>
                <td>
                  {{ rata.codiceEnte }} <br />
                  {{ rata.nomeEnte }} <br />
                  {{ rata.sedeEnte }}
                </td>
                <td>
                  {{ rata.scadenza }} <br />
                  <div class="{{ rata.colorScadenza }} dettaglioScadenza">
                    {{ rata.scadenzaLabel }}
                  </div>
                </td>
                <td>{{ rata.importo }} €</td>
                <td>{{ rata.importoIpotetico }} €</td>
                <td>{{ rata.scadute }} {{ rata.scaduteLabel }}</td>
                <td>
                  {{ rata.stato }}
                </td>
                <td>
                  {{ rata.pagamento }}
                </td>
                <td>
                  {{ rata.dataPagamento }}
                </td>
                <td>
                  {{ rata.note }}
                </td>
              </tr>
            </tbody>
          </nz-table>
        </div>
      </div>
    </ng-container>


</div>