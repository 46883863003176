<ng-container>
    <nz-collapse class="panel" [nzExpandIconPosition]="'right'">
        <nz-collapse-panel nzHeader="Rateazioni non rateizzati">
            <nz-table #cartelle [nzData]="cartelleNonRateizzate" [nzSize]="'small'">
                <thead>
                    <tr>
                        <th>Rateazione</th>
                        <th>Ente</th>
                        <th [nzWidth]="'160px'">Data di notifica</th>
                        <th>Importo</th>
                        <th>Note</th>

                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let cartella of cartelle.data" class="{{cartella?.colorRow}}" [routerLink]="['/cartelle/dettaglio', {id:cartella.idAdempimento}]">
                        <td>{{cartella.idCartella}} <br> {{cartella.tipo}}</td>
                        <td>{{cartella.codiceEnte}} <br> {{cartella.nomeEnte}} <br> Sede </td>
                        <td class="text-capitalize">Notificata il {{cartella.dataNotifica}}
                            <div *ngIf="cartella.labelStato" [ngClass]="getClass(cartella)" class="dettaglioScadenza mt-1 "> {{cartella.labelStato}} </div>
                        </td>
                        <td class="text-center text-capitalize "><span *ngIf="cartella.importoPagato !=cartella.importoTotale ">Pagati {{cartella.importoPagato}} € su  {{cartella.importoTotale}}</span>
                            <span *ngIf="cartella.importoPagato===cartella.importoTotale "> {{cartella.importoPagato}} € </span>
                        </td>
                        <td id="note" class="text-center">{{cartella.note}} </td>
                    </tr>
                </tbody>
            </nz-table>


        </nz-collapse-panel>
    </nz-collapse>
</ng-container>