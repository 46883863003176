<nz-affix *ngIf="!loading" [nzOffsetTop]="60">
    <div class="row titleFilterHeader">
        <div class="col-12">
            <nz-page-header class="site-page-header px-2" (click)="onBack()" nzBackIcon nzTitle="Dettaglio Budget">
                <nz-page-header-extra>
                </nz-page-header-extra>
            </nz-page-header>
        </div>

        <div class="row mb-3 d-flex justify-content-center">

            <div class="col-5 ">
                data inizio fine
                <br>
                <nz-range-picker [(ngModel)]="date" disabled [nzFormat]="dtFormat"></nz-range-picker>

            </div>
            <div class="col-3">
                Budget Stanziato
                <br>
                <input nz-input placeholder="Budget Stanziato" [(ngModel)]="detail.importoStanziato" disabled />
            </div>
            <div class="col-3">
                Totale selezionate
                <br>
                <input nz-input placeholder="Totale selezionato" [(ngModel)]="detail.importoSelezionato" disabled />
            </div>
        </div>
    </div>
</nz-affix>
<div *ngIf="!loading" class="row w-100">
    <div class="col-12">
        <nz-table #rateazioniFilter [nzData]="detail.rate" [nzSize]="'small'" [nzFrontPagination]="false">
            <thead>
                <tr>
                    <th>N° RATA</th>
                    <th>Rata</th>
                    <th>Ente</th>
                    <th>SCADENZA</th>
                    <th>IMPORTO</th>
                    <th>IPOTETICO</th>
                    <th>SCADUTO</th>
                    <th>STATO</th>
                    <th>PAGATO</th>
                    <th>DATA EFFETTIVO PAGAMENTO</th>
                </tr>
            </thead>
            <tbody>
                <tr></tr>
                <tr *ngFor="let rata of rateazioniFilter.data let i = index" class="{{ rata?.colorRow }}">
                    <td>{{ rata.numeroRata }} di {{ rata.numeroRate }}</td>
                    <td>
                        {{ rata.codiceCartella }}
                    </td>
                    <td>
                        {{ rata.codiceEnte }} <br />
                        {{ rata.nomeEnte }} <br />
                        {{ rata.sedeEnte }}
                    </td>
                    <td>
                        {{ rata.scadenza }} <br />
                    </td>
                    <td>{{ rata.importoEffettivo }} €</td>
                    <td>{{ rata.importoIpotetico }} €</td>
                    <td>{{ rata.scadute }} {{ rata.scaduteLabel }}</td>
                    <td>
                        {{ rata.stato }}
                    </td>
                    <td>
                        {{ rata.pagamento }}
                    </td>
                    <td>
                        {{ rata.dataPagamento }}
                    </td>
                </tr>
            </tbody>
        </nz-table>
    </div>
</div>