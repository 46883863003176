import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class MainDashboardService {

  constructor(private http: HttpClient) { }

  
  getAvvisi(ricevute: boolean, scadenza: boolean) {
    return this.http.get<any>(environment.apiPath + `/dashboard/card/count/avvisi?ricevute=${ricevute}&scadenza=${scadenza}`)
  }

  getRateazioni(mese_corrente: boolean, rateizzate: boolean, richieste: boolean) {
    return this.http.get<any>(environment.apiPath + `/dashboard/card/count/rateazioni?mese_corrente=${mese_corrente}&rateizzate=${rateizzate}&richieste=${richieste}`)
  }
  
  getRate(mese_corrente: boolean, pagate: boolean, ritardo: boolean) {
    return this.http.get<any>(environment.apiPath + `/dashboard/card/count/rata?mese_corrente=${mese_corrente}&pagate=${pagate}&ritardo=${ritardo}`)
  }
  
  getRischio() {
    return this.http.get<any>(environment.apiPath + `/dashboard/card/count/rischio`)
  }

  getBudget() {
    return this.http.get<any>(environment.apiPath + `/dashboard/card/sum/budget`)
  }

  getCalendar() {
    return this.http.get<any>(environment.apiPath + '/dashboard/calendar')
  }

  getCalendarV2() {
    return this.http.get<any>(environment.apiPath + '/dashboard/v2/calendar')
  }

  getScadenzaMese() {
    return this.http.get<any>(environment.apiPath + '/dashboard/card/scadenze-mese')
  }

  getScadenzaMeseSucc() {
    return this.http.get<any>(environment.apiPath + '/dashboard/card/scadenze-mese-succesivo')
  }
  

}
