<nz-page-header class="site-page-header px-2" (click)="onBack()" nzBackIcon nzTitle="Nuovo Avviso"></nz-page-header>
<div class="col-12 mb-3">
    <div class="row">
        <div class="col">
            Tipologia
            <nz-select [(ngModel)]="tipologia" (ngModelChange)="onChangeTipologia()">
                <nz-option nzValue="1 - Ente Singolo" nzLabel="1 - Ente Singolo"></nz-option>
                <nz-option nzValue="2 - Agenzia Entrate" nzLabel="2 - Agenzia Entrate"></nz-option>
                <nz-option nzValue="3 - Agenzia Entrate e Riscossione" nzLabel="3 - Agenzia Entrate e Riscossione"></nz-option>
              </nz-select>
        </div>
        <div class="col">

        </div>
        <div class="col">

        </div>
        <div class="col">

        </div>
    </div>
    <br>
    <div class="row">
        <div class="col">
            Ente
            <nz-select [(ngModel)]="selectedEnte" [nzAllowClear]="true" nzPlaceHolder="Seleziona Ente " [disabled]="!checkTipo">
                <nz-option *ngFor="let ente of enti" [nzValue]="ente.code" [nzLabel]="ente.name"></nz-option>
            </nz-select>
        </div>
        <div class="col">
            Sede Ente
            <input nz-input placeholder="Sede Ente" [(ngModel)]="sedeEnte" [disabled]="!checkTipo" maxlength="100"/>
        </div>
        <div class="col">
            Anno Riferimento
            <input nz-input placeholder="Anno Riferimento" [(ngModel)]="riferimentoAnno" [disabled]="!checkTipo"/>
        </div>
        <div class="col">
            Codice Identificativo
            <input nz-input placeholder="Codice" [(ngModel)]="codice" maxlength="50" />
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col">
            Data Ricezione <br>
            <nz-date-picker [(ngModel)]="dataRicezione" nzFormat="dd/MM/yyyy"></nz-date-picker>
        </div>
        <div class="col">
            Giorni alla Scadenza
            <input nz-input placeholder="Sede Ente" [(ngModel)]="giorniScadenza" (keypress)="numberOnly($event)" />
        </div>
        <div class="col">
            Importo
            <nz-input-number-group nzPrefix="€" style="width: 100%">
                <nz-input-number nzPlaceHolder="Importo" [(ngModel)]="importo" [nzPrecision]="2" nzPrecisionMode="toFixed" [disabled]="dettaglioAvviso"></nz-input-number>
            </nz-input-number-group>
        </div>
        <div class="col">
            Note
            <input nz-input placeholder="Note" [(ngModel)]="note" />
        </div>
    </div>
    <br>
    <br>
    <div class="row">
        <div class="col-12">
                <div nz-row [nzGutter]="8">
                    <div nz-col [nzSpan]="8">
                        <nz-card nzTitle="Avviso">
                            <p>Allega file
                                <input id="file" type="file" [(ngModel)]="modelFile"
                                    (change)="handleFileInput($event, 'documento')" />
                            </p>
                            <br>
                            <div class="row" *ngFor="let allegato of nuovoAvviso.documenti; let n=index">
                                <div class="col">
                                    Nome allegato
                                    <input nz-input [(ngModel)]="allegato.fileName" [disabled]="true"
                                        class="disabledBox" />
                                </div>
                                <div class="col">
                                    <br>
                                    <button nz-button nzType="primary" nzSize="small"
                                        style="background-color: brown; border-color: brown;"
                                        (click)="deleteAllegato(allegato.fileName, 'documento')">
                                        <i nz-icon nzType="minus" nzTheme="outline"></i>
                                    </button>
                                </div>
                                <div class="col">

                                </div>
                            </div>
                        </nz-card>
                    </div>
                </div>
        </div>
    </div>
    <br>
    <div class="d-flex flex-row-reverse" *ngIf="dettaglioAvviso">
        <div class="p-2 bd-highlight">
          <button nz-button nzSize="large" nzType="primary" class="mt-3" (click)="dettaglio()">
            Aggiungi dettaglio
          </button> 
        </div>
    </div>
        <br>
        <div class="row" *ngIf="dettaglioAvviso">
            <div class="col-12">
              <nz-table #basicTable [nzData]="nuovoAvviso.dettagli">
                <thead>
                  <tr>
                    <th>Ente</th>
                    <th>Anno</th>
                    <th>Importo</th>
                    <th>Note</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of nuovoAvviso.dettagli">
                    <td>{{ item.sedeEnte }}</td>
                    <td>{{ item.riferimentoAnno }}</td>
                    <td>{{ item.importo }}</td>
                    <td>{{ item.note }}</td>
                  </tr>
                </tbody>
              </nz-table>
            </div>
        </div>
        <br>
    <div class="row">
        <div class="col">
            <button nz-button nzSize="large" nzType="primary" class="mt-3" (click)="save()">
                Salva
            </button>
        </div>
        <div class="col">
            <button nz-button nzSize="large" nzType="primary" class="mt-3" (click)="saveRateazione()" >
                Salva e Nuova Rateazione
            </button>
        </div>
        <div class="col">
        </div>
        <div class="col">
        </div>
    </div>

</div>

<!-- modale dettaglio rateazione-->
<ng-container>
    <nz-modal [(nzVisible)]="isVisibleEdit" nzTitle="Dettaglio Rateazione" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
        <ng-container *nzModalContent>
            <div class="row">
              <div class="col">
                ente
                <nz-select [(ngModel)]="selectedEnteModal" [nzAllowClear]="true" nzPlaceHolder="Seleziona Ente">
                  <nz-option *ngFor="let ente of enti" [nzValue]="ente.code" [nzLabel]="ente.name"></nz-option>
                </nz-select>
                </div>
                <div class="col">
                  Sede ente
                  <input nz-input placeholder="Sede Ente" [(ngModel)]="sedeEnteModal" />
                </div>
            </div>
            <div class="row">
                  <div class="col">
                    Anno
                   <input nz-input placeholder="Anno Riferimento" [(ngModel)]="annoRifModal" />
                  </div>
                  <div class="col">
                      Importo
                    <nz-input-number-group nzPrefix="€" style="width: 100%">
                        <nz-input-number nzPlaceHolder="Importo" [(ngModel)]="importoModal" [nzPrecision]="2" nzPrecisionMode="toFixed"></nz-input-number>
                    </nz-input-number-group>
                  </div>
              </div>
            <div class="row">
              <div class="col-6">
                 note
                 <input nz-input placeholder="Note" [(ngModel)]="NoteModal" />
                </div>
                <div class="col-6">
                </div>
            </div>
        </ng-container>
    </nz-modal>
  </ng-container>