import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Save } from '../models/entiModel';
import { Filtro, } from '../models/filtriModel';

@Injectable({
  providedIn: 'root'
})
export class AdempimentiService {

  constructor(private http: HttpClient) { }

  
  getAdempimentoInit() {
    return this.http.get<any>(environment.apiPath + '/tools/lista-tipologia-adempimenti')
      .pipe(map(results => {
        return results;
      }));
  }

  getAdempimentoSave(params:Save) {
    return this.http.post<any>(environment.apiPath + '/tools/save-tipologia-adempimenti', params)
      .pipe(map(results => {
        return results;
      }));
  }

  delete(idTipologiaAdempimento: number) {
    return this.http.post<any>(environment.apiPath + '/tools/delete-tipologia-adempimenti?idTipologiaAdempimento=' + idTipologiaAdempimento.toString(), {})
      .pipe(map(results => {
        return results;
      }));
  }
}
