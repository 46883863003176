<nz-page-header class="site-page-header px-2" (click)="onBack()" nzBackIcon nzTitle="Dettaglio Avviso"></nz-page-header>
<div class="col-12 mb-3">
    <div class="row">
        <div class="col">
            Tipologia
            <input nz-input placeholder="Codice ente" [(ngModel)]="avviso.tipologiaEnte" disabled class="disabledBox" />
        </div>
        <div class="col">

        </div>
        <div class="col">

        </div>
        <div class="col">
            <button nz-button nzType="primary" (click)="showModal()">
                <span>Nuovo Avviso in calandario</span>
            </button>
            <nz-modal
                [(nzVisible)]="isVisible"
                [nzOkLoading]="isOkLoading"
                [nzOkDisabled]="!dataInizioModal"
                nzTitle="Nuovo evento avviso"
                (nzOnCancel)="handleCancel()" 
                (nzOnOk)="handleOk()"
            >
            <ng-container *nzModalContent>
                <div class="row">
                  <div class="col-6">
                    Data inizio
                    <nz-date-picker nzFormat="dd/MM/yyyy"  [(ngModel)]="dataInizioModal" ></nz-date-picker>
                  </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        note
                        <textarea nz-input placeholder="Note" [(ngModel)]="noteModal" ></textarea>
                    </div>
                </div>
            </ng-container>
            </nz-modal>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-3" *ngIf="checkTipo" >
            Ente
            <input nz-input placeholder="Codice ente" [(ngModel)]="avviso.nomeEnte" disabled class="disabledBox" />
            <!-- <nz-select [(ngModel)]="avviso.codiceEnte" disabled nzPlaceHolder="Seleziona Ente " [disabled]="!checkTipo"  class="disabledBox">
                <nz-option *ngFor="let ente of enti" [nzValue]="ente.code" [nzLabel]="ente.name"></nz-option>
            </nz-select> -->
        </div>
        <div class="col-3" *ngIf="checkTipo" >
            Sede Ente
            <input nz-input placeholder="Sede Ente" [(ngModel)]="avviso.sedeEnte" [disabled]="!avviso.editable" maxlength="100" />
        </div>
        <div class="col-3" *ngIf="checkTipo" >
            Anno Riferimento
            <input nz-input placeholder="Anno Riferimento" [(ngModel)]="avviso.riferimentoAnno" [disabled]="!avviso.editable" />
        </div>
        <div class="col-9" *ngIf="!checkTipo"></div>
        <div class="col-3">
            Codice Identificativo
            <input nz-input placeholder="Codice" [(ngModel)]="avviso.codice" maxlength="50" [disabled]="!avviso.editable" />
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col">
            Data Ricezione <br>
            <nz-date-picker [(ngModel)]="avviso.dataRicezione" nzFormat="dd/MM/yyyy" disabled></nz-date-picker>
        </div>
        <div class="col">
            Giorni alla Scadenza
            <input nz-input placeholder="Giorni alla scadenza" [(ngModel)]="avviso.giorniScadenza" [disabled]="!avviso.editable" />
        </div>
        <div class="col">
            Importo
            <nz-input-number-group nzPrefix="€" style="width: 100%">
                <nz-input-number nzPlaceHolder="Importo" [(ngModel)]="avviso.importo" [nzPrecision]="2" nzPrecisionMode="toFixed" [disabled]="!avviso.editable"></nz-input-number>
            </nz-input-number-group>
        </div>
        <div class="col">
            Note
            <input nz-input placeholder="Note" [(ngModel)]="avviso.note" [disabled]="!avviso.editable" />
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-3">
            Responsabile Procedimento
            <input nz-input placeholder="Responsabile Procedimento" [(ngModel)]="avviso.responsabileProcedimento" [disabled]="!avviso.editable" />
        </div>
        <div class="col-3">
            Data Scadenza
            <input nz-input placeholder="Data Scadenza" [(ngModel)]="avviso.dataScadenza" disabled />
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col">
            Pagato <br>
            <label nz-checkbox [(ngModel)]="avviso.flagPagato" [disabled]="!avviso.editable"></label>
        </div>
        <div class="col">
            Data Pagamento <br>
            <nz-date-picker [(ngModel)]="avviso.dataPagamento" nzFormat="dd/MM/yyyy" [disabled]="!avviso.editable"></nz-date-picker>
        </div>
        <div class="col">

        </div>
        <div class="col">

        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-12">
                <div nz-row [nzGutter]="8">
                    <div nz-col [nzSpan]="8">
                        <nz-card nzTitle="Avviso">
                            <p *ngIf="avviso.editable">Allega file
                                <input id="file" type="file" [(ngModel)]="modelFile"
                                    (change)="handleFileInput($event, 'documento')" />
                            </p>
                            <br>
                            <div class="row" *ngFor="let allegato of avviso.documenti; let n=index">
                                <div class="col">
                                    Nome allegato
                                    <input nz-input [(ngModel)]="allegato.id == null ? allegato.fileName : allegato.nome" [disabled]="true"
                                        class="disabledBox" />
                                </div>
                                <div class="col">
                                    <br>
                                    <button nz-button nzType="primary" nzSize="small" style="background-color: brown; border-color: brown;"
                                        (click)="deleteAllegato(allegato.fileName, 'documento')" *ngIf="allegato.id == null">
                                        <i nz-icon nzType="minus" nzTheme="outline"></i>
                                    </button>
                                    <button nz-button (click)="download(allegato)" nzType="primary" *ngIf="allegato.id != null">
                                        <i class="fas fa-download fa-2x"></i>
                                    </button>
                                </div>
                            </div>
                        </nz-card>
                    </div>
                    <div nz-col [nzSpan]="8" *ngIf="avviso.flagPagato">
                        <nz-card nzTitle="Pagamenti">
                            <p *ngIf="avviso.editable">Allega file
                                <input id="file" type="file" [(ngModel)]="modelFilePag"
                                    (change)="handleFileInput($event, 'ricevuta')" />
                            </p>
                            <br>
                            <div class="row" *ngFor="let allegato of avviso.documentiPagamento; let n=index">
                                <div class="col">
                                    Nome allegato
                                    <input nz-input [(ngModel)]="allegato.id == null ? allegato.fileName : allegato.nome" [disabled]="true"
                                        class="disabledBox" />
                                </div>
                                <div class="col">
                                    <br>
                                    <button nz-button nzType="primary" nzSize="small" style="background-color: brown; border-color: brown;"
                                        (click)="deleteAllegato(allegato.fileName, 'ricevuta')" *ngIf="allegato.id == null">
                                        <i nz-icon nzType="minus" nzTheme="outline"></i>
                                    </button>
                                    <button nz-button (click)="download(allegato)" nzType="primary" *ngIf="allegato.id != null">
                                        <i class="fas fa-download fa-2x"></i>
                                    </button>
                                </div>
                            </div>
                        </nz-card>
                    </div>
                </div>
        </div>
    </div>
    <br>
        <div class="row" *ngIf="dettaglioAvviso">
            <div class="col-12">
              <nz-table #basicTable [nzData]="avviso.dettagli">
                <thead>
                  <tr>
                    <th>Ente</th>
                    <th>Anno</th>
                    <th>Importo</th>
                    <th>Note</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of avviso.dettagli">
                    <td>{{ item.sedeEnte }}</td>
                    <td>{{ item.riferimentoAnno }}</td>
                    <td>{{ item.importo }}</td>
                    <td>{{ item.note }}</td>
                  </tr>
                </tbody>
              </nz-table>
            </div>
        </div>
        <br>
    <div class="row">
        <div class="col-11">
            <button nz-button nzSize="large" nzType="primary" class="mt-3" (click)="saveRateazione()"  *ngIf="avviso.stato === 'APERTO' && !avviso.flagPagato">
                Richiedi Rateazione
            </button>
            <button nz-button nzSize="large" nzType="primary" class="mt-3" (click)="showRateazione()"  *ngIf="avviso.stato === 'RICHIESTA RATEAZIONE'">
                Visualizza Rateazione
            </button>
        </div>
        <div class="col-1">
            <button nz-button nzSize="large" nzType="primary" class="mt-3" (click)="aggiornaAvviso()"  *ngIf="avviso.editable">
                Aggiorna
            </button>
        </div>
    </div>
</div>