import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChartData, ChartEvent, ChartType } from 'chart.js';
import { MainDashboardService } from 'src/app/_services/main-dashboard.service';
import { Pagamento } from 'src/app/models/dashboard/pagamentoModel';

interface G{
  count: number;
  tipo: string;
}
interface B{
  daPagare: number;
  pagate: number;
  tipo: string;
  totali: number;
}

interface I{
  dataScadenzaA: string;
  dataScadenzaDa: string; 
  importo: number; 
  numeroRate: number;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  
  //CHART
  public doughnutChartLabels: string[] = [
    'Da pagare',
    'Pagato'
  ];
  public doughnutChartData!: ChartData<'doughnut'>;
  public doughnutChartType: ChartType = 'doughnut';
  // events
  public chartClicked({
    event,
    active,
  }: {
    event: ChartEvent;
    active: object[];
  }): void {
    console.log(event, active);
  }
  public chartHovered({
    event,
    active,
  }: {
    event: ChartEvent;
    active: object[];
  }): void {
    console.log(event, active);
  }

  avvisi!: number;
  rateazioniRichieste!: number;
  rateazioniApprovate!: number;
  rateDaPagare!: number;
  rateInRitardo!: number;
  rateRischioRevoca!: number;
  calendarPagamenti: Pagamento[] = [];
  showCalendar = false;
  importiScadenza!: number;
  importiScadenzaSucc!: number;

  constructor(private mainDashboardService: MainDashboardService, private router: Router) { }

  async ngOnInit() {
    await this.getAvvisi();
    await this.rateazioni(true, false, true);
    await this.rateazioni(true, true, false);
    await this.rate(true, false, false);
    await this.rate(true, false, true);
    await this.rischio();
    await this.budget();
    await this.calendar();
    await this.importi();
  }

  onValueChange(value: any): void {
    this.router.navigate(['/calendarioPagamenti', { id: value }]);
  }

  onPanelChange(event: any): void {
    console.log(event);
  }

  async getAvvisi() {
    this.mainDashboardService.getAvvisi(false, false).subscribe(
      (response: G) => {
        this.avvisi = response.count;
      });
  }

  async rateazioni(me: boolean, ra: boolean, ri: boolean) {
    this.mainDashboardService.getRateazioni(me, ra, ri).subscribe(
      (response: G) => {
        if(!ra)
          this.rateazioniRichieste = response.count;
        else
          this.rateazioniApprovate = response.count;
      });
  }

  async rate(me: boolean, pa: boolean, ri: boolean) {
    this.mainDashboardService.getRate(me, pa, ri).subscribe(
      (response: G) => {
        if(!ri)
          this.rateDaPagare = response.count;
        else
          this.rateInRitardo = response.count;
      });
  }

  async rischio() {
    this.mainDashboardService.getRischio().subscribe(
      (response: G) => {
        this.rateRischioRevoca = response.count;
      });
  }

  async budget() {
    this.mainDashboardService.getBudget().subscribe(
      (response: B) => {
        this.doughnutChartData = {
          labels: this.doughnutChartLabels,
          datasets: [
            { 
              data: [response.daPagare, response.pagate],
              backgroundColor: [
                '#40a9ff',
                'orange'
              ],
            }
          ],
        };
      });
  }

  async calendar() {
    this.mainDashboardService.getCalendarV2().subscribe(
      (response: Pagamento[]) => {
        this.calendarPagamenti = response;
        this.showCalendar = true;
    });
  }

  getCalendarPropertiesDate(calendarDate: Date): any{
    const cd = calendarDate.toDateString();
    for (const pagamento of this.calendarPagamenti) {
      const [day, month, year] = pagamento.data.split("/");
      const pd = new Date(year, month - 1, day).toDateString();
      if(cd === pd){
        return {
          ball: true,
          tipo: pagamento.tipo
        };
      }
    }
    return {
      ball: false,
      tipo: undefined
    };
  }

  async importi() {
    this.mainDashboardService.getScadenzaMese().subscribe(
      (response: I) => {
        this.importiScadenza = response.importo;
    });
    this.mainDashboardService.getScadenzaMeseSucc().subscribe(
      (response: I) => {
        this.importiScadenzaSucc = response.importo;
    });
  }

  goToAmounts(): void {
    this.router.navigate(['/rateazioni', { id: 0 }]);
  }

}
