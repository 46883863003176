import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListaAdempimentiComponent } from './components/adempimenti/lista-adempimenti/lista-adempimenti.component';
import { NuovoAdempimentoComponent } from './components/adempimenti/nuovo-adempimento/nuovo-adempimento.component';
import { DettaglioAdempimentoComponent } from './components/cartelle/dettaglio/dettaglio.component';
import { ElencoComponent } from './components/cartelle/elenco/elenco.component';
import { NonRateizzateComponent } from './components/cartelle/elenco/non-rateizzate/non-rateizzate.component';
import { RateizzateComponent } from './components/cartelle/elenco/rateizzate/rateizzate.component';
import { NuovaCartellaComponent } from './components/cartelle/nuova-cartella/nuova-cartella.component';
import { CustomersListComponent } from './components/customers/customers-list/customers-list.component';
import { NewCustomerComponent } from './components/customers/new-customer/new-customer.component';
import { ListaEntiComponent } from './components/enti/lista-enti/lista-enti.component';
import { NuovoEnteComponent } from './components/enti/nuovo-ente/nuovo-ente.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { DettaglioRateazioneComponent } from './components/rateazioni/dettaglio-rateazione/dettaglio-rateazione.component';
import { ElencoRateazioneComponent } from './components/rateazioni/elenco-rateazione/elenco-rateazione.component';
import { NuovaRateazioneComponent } from './components/rateazioni/nuova-rateazione/nuova-rateazione.component';
import { AuthGuard } from './_helpers/auth.guard';
import { CalendarioComponent } from './components/calendario/calendario.component';
import { AvvisiComponent } from './components/avvisi/avvisi.component';
import { DettaglioAvvisoComponent } from './components/avvisi/dettaglio-avviso/dettaglio-avviso.component';
import { NuovoAvvisoComponent } from './components/avvisi/nuovo-avviso/nuovo-avviso.component';
import { BudgetComponent } from './components/budget/budget.component';
import { NuovoBudgetComponent } from './components/budget/nuovo-budget/nuovo-budget.component';
import { DettaglioBudgetComponent } from './components/budget/dettaglio-budget/dettaglio-budget.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
      { path: 'home', canActivate: [AuthGuard], component: HomeComponent },
      { path: 'dashboard', canActivate: [AuthGuard], component: DashboardComponent },
      { path: 'cartelle', canActivate: [AuthGuard], component: ElencoComponent },
      { path: 'cartelle/rateizzate', canActivate: [AuthGuard], component: RateizzateComponent },
      { path: 'cartelle/non-rateizzate', canActivate: [AuthGuard], component: NonRateizzateComponent },
      { path: 'cartelle/dettaglio', canActivate: [AuthGuard], component: DettaglioAdempimentoComponent },
      { path: 'cartelle/nuova-cartella', canActivate: [AuthGuard], component: NuovaCartellaComponent },
      { path: 'rateazioni', canActivate: [AuthGuard], component: ElencoRateazioneComponent },
      { path: 'rateazioni/dettaglio', canActivate: [AuthGuard], component: DettaglioRateazioneComponent },
      { path: 'rateazioni/nuova-rateazione', canActivate: [AuthGuard], component: NuovaRateazioneComponent },
      { path: 'amministrazione/ricerca-ente', component: ListaEntiComponent },
      { path: 'enti', component: ListaEntiComponent },
      { path: 'enti/nuovo-ente', component: NuovoEnteComponent },
      { path: 'tipo-adempimenti', component: ListaAdempimentiComponent },
      { path: 'tipo-adempimenti/nuovo-tipo-adempimento', component: NuovoAdempimentoComponent },
      { path: 'amministrazione/ricerca-anagrafica', component: CustomersListComponent },
      { path: 'amministrazione/inserisci-cliente', component: NewCustomerComponent },
      { path: 'calendarioPagamenti', canActivate: [AuthGuard], component: CalendarioComponent},
      { path: 'avvisi', canActivate: [AuthGuard], component: AvvisiComponent },
      { path: 'avvisi/dettaglio', canActivate: [AuthGuard], component: DettaglioAvvisoComponent },
      { path: 'avvisi/nuovo-avviso', canActivate: [AuthGuard], component: NuovoAvvisoComponent },
      { path: 'budget', canActivate: [AuthGuard], component: BudgetComponent },
      { path: 'budget/nuovo-budget', canActivate: [AuthGuard], component: NuovoBudgetComponent },
      { path: 'budget/dettaglio', canActivate: [AuthGuard], component: DettaglioBudgetComponent },
    ]
  },
  { path: 'login', component: LoginComponent },

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
