import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe, Location } from "@angular/common";
import { RateazioniService } from 'src/app/_services/rateazioni.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Rateazioni } from 'src/app/models/rateazioniModel';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-nuova-rateazione',
  templateUrl: './nuova-rateazione.component.html',
  styleUrls: ['./nuova-rateazione.component.scss']
})
export class NuovaRateazioneComponent implements OnInit {

  constructor(
    private rateazioniService: RateazioniService,
    private route: ActivatedRoute,
    private message: NzMessageService,
    private datePipe: DatePipe,
    private router: Router
  ) { }

  location!: Location;
  nuovaRateazioneForm!: FormGroup;
  isDisabled: boolean = false;
  tipiRata!: any;
  idAdempimento!: number;
  tipiCadenzaRata!: any;
  rateazione!: Rateazioni;
  idRateazione!: number;

  ngOnInit(): void {
    this.nuovaRateazioneForm = new FormGroup({
      codiceRateazione: new FormControl('', ),
      tipoRata: new FormControl('', ),
      numeroRate: new FormControl('', [Validators.required]),
      dataScadenza: new FormControl('', [Validators.required]),
      cadenzaRata: new FormControl('', [Validators.required]),
      importo: new FormControl('', ),
      importoIpotetico: new FormControl('', )
    });

    this.idAdempimento = this.route.snapshot.params['idAdempimento'];
    //this.getRateazioniInit();
    this.getRateazioniInitSave();
  }

  getRateazioniInit() {
    this.rateazioniService.getRateazioneInit().subscribe(
      (data) => {
        if (data) {
          this.tipiRata = data.tipoRata;
        }
      },
      (err) => console.error(err),
     
    );
  }

  getRateazioniInitSave() {
    this.rateazioniService.getRateazioneInitSave(this.idAdempimento).subscribe(
      (data) => {
        if (data) {
          this.rateazione = data.rateazine;
          this.tipiCadenzaRata = data.cadenzeRata;
          this.tipiRata = data.tipiRata;
        }
      },
      (err) => console.error(err),
    );
  }
  submitForm() {
    this.rateazioniService.rateazioneSave({
      codiceCartella: this.rateazione.codiceCartella,
      codiceEnte: this.rateazione.codiceEnte,
      codiceRateazione: this.nuovaRateazioneForm.value.codiceRateazione,
      dataPrimaScadenza: this.datePipe.transform(this.nuovaRateazioneForm.value.dataScadenza, 'dd/MM/yyyy'),
      idAzienda: this.rateazione.idAzienda,
      idCadenzaRata: this.nuovaRateazioneForm.value.cadenzaRata.code,
      idAdempimento: this.rateazione.idAdempimento,
      idEnte: this.rateazione.idEnte,
      idTipoRata: this.nuovaRateazioneForm.value.tipoRata.code,
      importo: this.nuovaRateazioneForm.value.importo,
      importoIpotetico : this.nuovaRateazioneForm.value.importoIpotetico,
      maxScadute: this.nuovaRateazioneForm.value.maxScadute,
      nomeAzienda: this.rateazione.nomeAzienda,
      nomeEnte: this.rateazione.nomeEnte,
      numeroRate: this.nuovaRateazioneForm.value.numeroRate,
      sedeEnte: this.rateazione.sedeEnte,
      tipoCartella: this.rateazione.tipoCartella,
      tipoRata: this.nuovaRateazioneForm.value.tipoRata.code,
    }).subscribe((data) => {
      if (data) {
        this.idRateazione = data.idRateazione
      }
    },
      (err) => console.error(err),
      () => {
        this.message.success('Rateazione creata con successo', {
          nzDuration: 10000
        });
        this.router.navigate(['/rateazioni/dettaglio', { id: this.idRateazione }])
      }
    );
  }

  onBack() {
    this.location.back();
  }
}
