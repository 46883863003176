import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Filtro, } from '../models/filtriModel';
import { PagamentoRata, SaveRateazione, UpdateRata } from '../models/rateazioniModel';
import { Allegato } from '../models/commonModel';

@Injectable({
  providedIn: 'root'
})
export class RateazioniService {

  constructor(private http: HttpClient) { }

  
  getRateazioneInit() {
    return this.http.get<any>(environment.apiPath + '/rateazione/init')
      .pipe(map(results => {
        return results;
      }));
  }

  getRateazioneInitSave(idCartella: number) {
    return this.http.post<any>(environment.apiPath + '/rateazione/init-save?idAdempimento='+idCartella, {})
      .pipe(map(results => {
        return results;
      }));
  }

  rateazioneDetail(idRateazione: number) {
    return this.http.post<any>(environment.apiPath + '/rateazione/detail?idRateazione='+idRateazione, {})
    .pipe(map(results => {
      return results;
    }));
  }

  rateazioneGenerate(idRateazione: number) {
    return this.http.post<any>(environment.apiPath + '/rateazione/rata/generate?idRateazione='+idRateazione, {})
    .pipe(map(results => {
      return results;
    }));
  }
  
  rateazioneSave(params: SaveRateazione) {
    return this.http.post<any>(environment.apiPath + '/rateazione/save', params)
    .pipe(map(results => {
      return results;
    }));
  }

  updateImporto(idRata: number, importo:number) {
    return this.http.post<any>(environment.apiPath + '/rateazione/rata/update-importo?idRata='+idRata+'&importo='+importo, {})
    .pipe(map(results => {
      return results;
    }));
  }

  
  pagamento(pagamentoRata : PagamentoRata ) {
    var path: string = '/rateazione/rata/pagamento';
    
    return this.http.post<any>(environment.apiPath + path, pagamentoRata)
    .pipe(map(results => {
      return results;
    }));
  }

  update(updateRata : UpdateRata ) {
    var path: string = '/rateazione/rata/update';
    
    return this.http.post<any>(environment.apiPath + path, updateRata)
    .pipe(map(results => {
      return results;
    }));
  }

  updateDataScadenza(dataScadenza:string, idRata:number) {
    return this.http.post<any>(environment.apiPath + '/rateazione/rata/update-scadenza?data-scadenza='+dataScadenza+'&idRata='+idRata, {})
    .pipe(map(results => {
      return results;
    }));
  }

  getPopulateDropDown(filtro:Filtro) {
    return this.http.post<any>(environment.apiPath + '/rateazione/populate', filtro)
      .pipe(map(results => {
        return results;
      }));
  }
  
  getRateazioneSearch(filtro:Filtro) {
    return this.http.post<any>(environment.apiPath + '/rateazione/search', filtro)
      .pipe(map(results => {
        return results;
      }));
  }

  revocaRateazione(idRateazione : number) {
    return this.http.post<any>(environment.apiPath + '/rateazione/revoca?idRateazione='+idRateazione , {})
      .pipe(map(results => {
        return results;
      }));
  }
}
