<nz-affix [nzOffsetTop]="60">
  <div class="row titleFilterHeader">
    <div class="col-12">
      <!--Page header: title, back, others actions-->
      <nz-page-header
        class="site-page-header px-2"
        (click)="onBack()"
        nzBackIcon
        nzTitle="Inserisci un nuovo cliente"
      ></nz-page-header>
    </div>
  </div>
</nz-affix>
<div class="row customersList">
    <div class="col-12 mt-5">
        <div class="card">
            <form
            nz-form
            [formGroup]="newCustomerForm"
            (ngSubmit)="submitForm()"
        >
            <nz-form-item>
                <nz-form-label [nzNoColon]="true">Field A</nz-form-label>
                <nz-form-control nzErrorTip="Please input your username!">
                    <input nz-input nzSize="large" formControlName="fieldA" placeholder="input placeholder" />
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzNoColon]="true">Field B</nz-form-label>
                <nz-form-control nzErrorTip="Please input your Password!">
                    <input nz-input nzSize="large" formControlName="filedB" placeholder="input placeholder" />
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-control>
                    <button nz-button nzSize="large" nzType="primary" class="mt-3">Salva</button>
                </nz-form-control>
            </nz-form-item>
        </form>
        </div>
        
    </div>
</div>