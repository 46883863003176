import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/_services/user.service';
import { RateazioniService } from "src/app/_services/rateazioni.service";
import { NzTableQueryParams } from "ng-zorro-antd/table";
import { Filtro } from "src/app/models/filtriModel";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {

  currentUserRole = '';
  loading = false;
  totalPage = 0;
  index_page = 0;
  rowNumber = 10;
  public filtro: Filtro = {
    cartellaFilter: {
      codiceCartella: null,
      codiceEnte: null,
      codiceRata: null,
      dataScadenza: null,
      idStatoCarella: 0,
      nomeEnte: null,
      order: null,
      page: 0,
      rowNumber: 10,
      tipoCartella: null,
      tipoRata: null,
    },
    enteFilter: {
      codice: null,
      nome: null,
      sede: null,
      page: 0,
      rowNumber: 10,
    },
    idAzienda: 1,
    rateazioneFilter: {
      codiceCartella: null,
      codiceEnte: null,
      codiceRata: null,
      dataPagamento: null,
      nomeEnte: null,
      numeroRata: 0,
      order: null,
      page: 0,
      rowNumber: 10,
      sedeEnte: null,
      tipoCartella: null,
      tipoRata: null,
      idStatoCartella: null,
      dataScadenza: null,
      pagato: null,
      scaduta: null,
    },
    avvisoFilter: {
      nomeEnte: null,
      codiceEnte: null,
      stato: null,
      dataScadenza: null,
      order: null,
      page: 0,
      rowNumber: 10,
    },
    budgetFilter: {
      dataInizio: null,
      dataFine: null,
      order: null,
      page: 0,
      rowNumber: 10,
    },
  };

  listaRateazioni: any = [];
  
  constructor(
    private rateazioniService: RateazioniService,
    private userService: UserService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.getRateazioniInit();
    this.currentUserRole = 
      this.userService.getUser() !== null ? this.userService.getUser().roles[0].name : null
  }

  nuovaCartella(){
    this.router.navigateByUrl('/cartelle/nuova-cartella');
  }

  nuovoAvviso(){
    this.router.navigate(['/avvisi/nuovo-avviso']);
  }

  apriRateazioni() {
    this.router.navigate(['/rateazioni']);    
  }

  apriCalendario() {
    this.router.navigate(['/calendarioPagamenti']);
  }

  getRateazioniInit() {
    this.rateazioniService.getRateazioneInit().subscribe(
      (data) => {
        if (data) {
          this.loading = false;
          this.listaRateazioni = data;  
          this.totalPage = this.listaRateazioni.numberPage;
          this.index_page =
            this.listaRateazioni.filter.rateazioneFilter.page! + 1;
          this.rowNumber =
            this.listaRateazioni.filter.rateazioneFilter.rowNumber;
        }
      },
      (err) => console.error(err),
      () => {
        //this.loading = false;
      }
    );
  }

  onQueryParamsChange(e: NzTableQueryParams) {
    //this.loading = true;
    this.filtro.rateazioneFilter.page = e.pageIndex - 1;
    if (this.filtro.rateazioneFilter.page == -1) {
      this.filtro.rateazioneFilter.page = 0;
    }
    setTimeout (() => {
   }, 1000);
   this.rateazioniService.getRateazioneSearch(this.filtro).subscribe(
     (data) => {
       if (data) {
        this.loading = false;
         this.listaRateazioni = data;        
         this.totalPage = this.listaRateazioni.numberPage;
         this.index_page =
           this.listaRateazioni.filter.rateazioneFilter.page! + 1;
         this.rowNumber =
           this.listaRateazioni.filter.rateazioneFilter.rowNumber;
       }
     },
     (err) => console.error(err),
     () => {
       //this.loading = false;
     }
   );
  }

}
