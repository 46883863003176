import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';

@Component({
  selector: 'app-new-customer',
  templateUrl: './new-customer.component.html',
  styleUrls: ['./new-customer.component.scss']
})
export class NewCustomerComponent implements OnInit {

  newCustomerForm!: FormGroup;
  location!: Location;

  submitForm(): void {
    if (this.newCustomerForm.valid) {
      console.log('submit', this.newCustomerForm.value);
    } else {
      Object.values(this.newCustomerForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.newCustomerForm = this.fb.group({
      formLayout: ['horizontal'],
      fieldA: [null, [Validators.required]],
      filedB: [null, [Validators.required]]
    });
  }

  onBack() {
    this.location.back();
  }

}
