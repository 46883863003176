import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NuovaCartella, SaveDettaglio } from 'src/app/models/cartellaModel';
import { CartellaService } from "src/app/_services/cartella.service";
import { DatePipe } from '@angular/common';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable, ReplaySubject } from 'rxjs';
import { Allegato } from 'src/app/models/commonModel';
import { Avviso} from "src/app/models/avvisoModel";
import { CommonService } from 'src/app/_services/commons.service';
import { el } from '@fullcalendar/core/internal-common';

@Component({
  selector: 'app-nuova-cartella',
  templateUrl: './nuova-cartella.component.html',
  styleUrls: ['./nuova-cartella.component.scss']
})
export class NuovaCartellaComponent implements OnInit {
  tipoScelta:any;
  modelFileRichieste: any;
  modelFileNotifica: any;
  modelFileRateazioni: any;
  modelFileBollettini: any;
  fileToUpload: File | null = null;
  isVisibleEdit = false;
  location!: Location;
  codiceEnte:any;
  nomeEnte:any;
  sedeEnte:any;
  sedeEnteModal:any;
  annoRifModal:any;
  importoModal:any;
  NoteModal:any;
  idAvviso: any;
  codice:any;
  dataProtocollo:any;
  dataResoEsecutivo:any;
  dataScadenza:any;
  dataRichiesta:any;
  protocollo:any;
  numeroRuolo:any;
  responsabileProcedimento:any;
  importoIpotetico:any;
  importoEffettivo:any;
  note:any
  annoRiferimento:any;
  selectedTipoCartella:any;
  selectedEnteModal:any;
  giorniScadenza:any;
  tipologie: any = [];
  campiObbligatori: boolean = false;
  sceltaTipo: boolean = true;
  rataUnica:boolean = false;
  checkTipo:boolean = false;
  tipologiaEnte:any;
  selectedEnte : any;
  enti : any =[];
  content: any;
  fileName: any;
  dettaglioRateazione: boolean = false;
  visualizzaAllegati: boolean = false;
  viewAvviso: boolean = false;
  viewProtocollo: boolean = true;
  viewNotRientro: boolean = true;
  saveAllegato:Allegato={
    content: '',
    tipo: '',
    fileName: '',
    nome:'',
}
 dettaglioModal:SaveDettaglio = {
  idEnte: 0,
  codiceEnte: '',
  nomeEnte: '',
  sedeEnte: '',
  codice: '',
  idAzienda: 0,
  importo:0,
  riferimentoAnno: '',
  note: '',
}


avviso : Avviso = {
    idAvviso: 0,
    idEnte: 0,
    codiceEnte: '',
    nomeEnte: '',
    sedeEnte: '',
    codice: '',
    dataRicezione: '',
    giorniScadenza: 0,
    importo: 0,
    responsabileProcedimento: '',
    note: '',
    riferimentoAnno: '',
    tipologiaEnte: '',
    flagPagato: false,
    dataPagamento: '',
    dataScadenza: '',
    stato: '',
    idAdempimento: 0,
    dettagli :[],
    documenti : [],
    documentiPagamento: [],
    editable:true,
}

  nuovaCartella:NuovaCartella= {
    idAvviso: null,
    bollettini: [],
    codice: '',
    codiceEnte: '',
    dataProtocollo: '',
    dataResoEsecutivo: '',
    dataScadenza: '',
    dettagli: [],
    documentiNotifica: [],
    documentiRateazioni: [],
    documentiRichiesta: [],
    idAzienda: 0,
    idEnte: 0,
    importoIpotetico:0,
    importoEffettivo:0,
    note: '',
    numeroRuolo: '',
    rataUnica: false,
    responsabileProcedimento: '',
    riferimentoAnno: '',
    sedeEnte: '',
    protocollo:'',
    tipologiaEnte: '',
    dataRichiesta: '',
    tipo: '',
    societaPianoRientro: '',
    notePianoRientro: '',
  }



  constructor(private router: Router,
              private cartellaService: CartellaService,
              private route: ActivatedRoute,
              private datePipe: DatePipe,
              private commonsService: CommonService,
              private nzModalService: NzModalService) { }

  ngOnInit(): void {
    this.idAvviso = this.route.snapshot.paramMap.get('id');
    
    this.cartellaService.saveInit(this.idAvviso).subscribe(
      (data) => {
        if (data) {
          this.enti = data.enti;
          this.avviso = data.avviso;
          this.populateFromAvviso();
          this.verificaTipologiaEnte();
        }
      },
      (err) => console.error(err),
      () => {

      }
    );
    
  }

  populateFromAvviso() {
    if(this.avviso) {
      this.viewAvviso = true;
      this.selectedEnte = this.avviso.idEnte;
      this.sedeEnte = this.avviso.sedeEnte;
      this.annoRiferimento = this.avviso.riferimentoAnno ;
      this.codice = this.avviso.codice ;
      this.importoEffettivo = this.avviso.importo ;
      this.tipologiaEnte = this.avviso.tipologiaEnte;
      this.idAvviso = this.avviso.idAvviso;
      this.viewProtocollo = false;
    } else {
      this.viewAvviso = false;
      this.visualizzaAllegati = true;
    }
  }

  onBack() {
    this.location.back();
  }

  save() {
    let dNot = this.datePipe.transform(this.dataProtocollo,"dd/MM/yyyy");
    let dSca = this.datePipe.transform(this.dataScadenza,"dd/MM/yyyy");
    let dRe = this.datePipe.transform(this.dataResoEsecutivo,"dd/MM/yyyy");
    //this.nuovoAvviso.idEnte = this.selectedEnte;
    //this.nuovoAvviso.idAvviso = 
    //this.nuovoAvviso.codice =this.codice;
    //this.nuovoAvviso.codiceEnte = this.codiceEnte;
    //this.nuovoAvviso.dataRicezione = dNot!;
    //this.nuovoAvviso.giorniScadenza = this.giorniScadenza;
    //this.nuovoAvviso.idAzienda = Number(sessionStorage.getItem('selectedStore')!);
    //this.nuovoAvviso.sedeEnte = this.sedeEnte;
    //this.nuovoAvviso.importo = Number(this.importoEffettivo);
    //this.nuovoAvviso.note = this.note;
    //this.nuovoAvviso.responsabileProcedimento = this.responsabileProcedimento;
    //this.nuovoAvviso.riferimentoAnno = this.annoRiferimento;
    //this.nuovaCartella.avviso = this.nuovoAvviso;
    this.nuovaCartella.codice =this.codice;
    this.nuovaCartella.codiceEnte = this.codiceEnte;
    this.nuovaCartella.dataResoEsecutivo = dRe!;
    //this.nuovaCartella.dataScadenza = dSca!;
    this.nuovaCartella.dataProtocollo = dNot!;
    this.nuovaCartella.idAzienda = Number(sessionStorage.getItem('selectedStore')!);
    this.nuovaCartella.idEnte = this.selectedEnte;
    this.nuovaCartella.importoIpotetico = Number(this.importoIpotetico);
    this.nuovaCartella.importoEffettivo = Number(this.importoEffettivo);
    this.nuovaCartella.note = this.note;
    this.nuovaCartella.numeroRuolo = this.numeroRuolo;
    this.nuovaCartella.responsabileProcedimento = this.responsabileProcedimento;
    this.nuovaCartella.sedeEnte = this.sedeEnte;
    this.nuovaCartella.riferimentoAnno = this.annoRiferimento;
    this.nuovaCartella.dataRichiesta = this.dataRichiesta;
    this.nuovaCartella.tipo = this.tipoScelta;
    this.nuovaCartella.idAvviso = (this.idAvviso) ? this.idAvviso : null;
    //TODO da verificare le condizioni per il salvataggio in caso di avviso != null e avviso == null
    // inoltre in caso di ente singolo o di dettagli
    if (!this.tipoScelta) {
      this.nzModalService.warning({
        nzTitle: 'Attenzione',
        nzContent: 'Non è possibile salvare senza scegliere una tipologia o un Ente.',
        nzOkText: 'Ok',
        nzOkType: 'primary',
        nzOkDanger: true,
        nzOnOk: () => {},
        nzCancelText: 'Annulla',
        nzOnCancel: () => {}
      });
      return;
    }
    this.nuovaCartella.rataUnica = this.rataUnica;
    this.cartellaService.saveCartella(this.nuovaCartella).subscribe(
      (data) => {
        if (data) {
          console.log(data);
          this.router.navigate(['/cartelle'])
        }
      },
      (err) => console.error(err),
      () => {

      }
    );
  }

 
  onChangeTipologiaEnte(){
    if(this.tipologiaEnte === '1 - Ente Singolo'){
      this.checkTipo = true;
      this.dettaglioRateazione = false;
    }else{
      this.checkTipo = false;
      this.dettaglioRateazione = true;
    }
    this.nuovaCartella.tipologiaEnte =  this.tipologiaEnte;
  }

  verificaTipologiaEnte(){
    if(this.tipologiaEnte === '1 - Ente Singolo'){
      this.checkTipo = true;
      this.dettaglioRateazione = false;
    } else if(this.tipologiaEnte === '2 - Agenzia Entrate' || this.tipologiaEnte === '3 - Agenzia Entrate e Riscossione'){
      this.checkTipo = false;
      this.dettaglioRateazione = true;
    }else{
      this.checkTipo = true;
      this.dettaglioRateazione = false;
    }
  }

  onChangeTipologia(){
    this.sceltaTipo = false;
    if(this.tipoScelta === 'Adempimento'){
      this.rataUnica = true;
    }
    if (this.tipoScelta === 'Rientro'){
      this.viewNotRientro = false
    } else {
      this.viewNotRientro = true;
    }
  }

  dettaglio() {
    this.isVisibleEdit = true;
  }

  handleCancel(){
    this.clearModels();
    this.isVisibleEdit = false;
  }

  numberOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  handleOk(){
    this.enti.forEach((element:any,index:any)=>{
      if(element.code===this.selectedEnteModal) this.nomeEnte = element.name;
   });
    let det: SaveDettaglio= {
      idEnte: 0,
      codiceEnte: '',
      nomeEnte: '',
      sedeEnte: '',
      codice: '',
      idAzienda: 0,
      importo:0,
      riferimentoAnno: '',
      note: '',
    };
    det.idEnte = this.selectedEnteModal;
    det.nomeEnte = this.nomeEnte;
    det.sedeEnte = this.sedeEnteModal;
    det.riferimentoAnno = this.annoRifModal;
    det.importo = this.importoModal;
    det.note = this.NoteModal;
    this.isVisibleEdit = false;
    this.nuovaCartella.dettagli.push(det);
    this.clearModels();
    this.calcolaImporto();
  }

  calcolaImporto() {
    this.importoEffettivo = this.nuovaCartella.dettagli
      .map((cartella: SaveDettaglio) => { return cartella.importo; } )
      .reduce( (tot: number, importo: number) => tot += importo
      , 0);
  }

  clearModels(){
    this.selectedEnteModal = null;
    this.sedeEnteModal = null;
    this.annoRifModal= null;
    this.importoModal= null;
    this.NoteModal=null;
  }

  deleteAllegato(fileName:any, tipo:any) {
    if(tipo==='richiesta'){
      this.nuovaCartella.documentiRichiesta.forEach((element,index)=>{
        if(element.fileName==fileName) this.nuovaCartella.documentiRichiesta.splice(index, 1);
     });
    }
    if(tipo==='notifica'){
      this.nuovaCartella.documentiNotifica.forEach((element,index)=>{
        if(element.fileName==fileName) this.nuovaCartella.documentiNotifica.splice(index, 1);
     });
    }
    if(tipo==='rateazione'){
      this.nuovaCartella.documentiRateazioni.forEach((element,index)=>{
        if(element.fileName==fileName) this.nuovaCartella.documentiRateazioni.splice(index, 1);
     });
    }
    if(tipo==='bollettini'){
      this.nuovaCartella.bollettini.forEach((element,index)=>{
        if(element.fileName==fileName) this.nuovaCartella.bollettini.splice(index, 1);
     });
    }
  }

  handleFileInput(event: Event, tipo:any) {
    const element = event.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;
    if (fileList) {
      this.convertFile(fileList[0]).subscribe(base64 => {
        let allegato:Allegato={
          content: base64,
          tipo: tipo,
          fileName: fileList![0].name,
          nome:''
      }
      if(tipo==='richiesta'){
        this.modelFileRichieste = null;
         this.nuovaCartella.documentiRichiesta.push(allegato);
      }
      if(tipo==='notifica'){
        this.modelFileNotifica = null;
         this.nuovaCartella.documentiNotifica.push(allegato);
      }
      if(tipo==='rateazione'){
        this.modelFileRateazioni = null;
         this.nuovaCartella.documentiRateazioni.push(allegato);
      }
      if(tipo==='bollettini'){
        this.modelFileBollettini= null;
         this.nuovaCartella.bollettini.push(allegato);
      }
      });

    }
}

convertFile(file : File) : Observable<string> {
  const result = new ReplaySubject<string>(1);
  const reader = new FileReader();
  reader.readAsBinaryString(file);
  reader.onload = (event) => 
  result.next(btoa(event.target!.result!.toString()));
  return result;
}

}
