<div class="main-nav">
  <div (click)="toggle()" class="button-nav" [class.active]="hide">
    <i class="fas fa-bars"></i>
  </div>
  <div class="panel-nav" [class.open]="hide">
    <nav class="pt-4">
      <div class="section">
        <h3>Scorciatoie</h3>
        <ul class="ps-0">
          <li>
            <button nz-button nzType="primary" (click)="toggle(); nuovaCartella()">
              <i nz-icon nzType="plus" nzTheme="outline"></i>
              Nuova Rateazione
            </button>
          </li>
          <li>
            <button nz-button nzType="primary" (click)="toggle(); nuovoAvviso()">
              <i nz-icon nzType="plus" nzTheme="outline"></i>
              Nuovo Avviso
            </button>
          </li>
          <li>
            <button nz-button nzType="primary" (click)="toggle(); nuovoBudget()">
              <i nz-icon nzType="plus" nzTheme="outline"></i>
              Nuovo Budget
            </button>
          </li>
        </ul>
      </div>
      <ng-container *ngFor="let item of items">
        <div class="section {{item.styleClass}}">
          <h3>{{item.label}}</h3>
          <ul *ngIf="item.items" class="ps-0">
            <li *ngFor="let linkitem of item.items">
                <span *ngIf="linkitem.status == 'hide'">
                  <i class="{{linkitem.icon}}"></i>
                  {{linkitem.label}}
                </span>
                <ng-container *ngIf="linkitem.items">
                  <a *ngIf="linkitem.status == 'show'" (click)="toggleSubMenu(linkitem);">
                    <i class="{{linkitem.icon}}"></i>
                    {{linkitem.label}}
                  </a>
                  <ul *ngIf="linkitem.childrenVisible" class="ps-0">
                    <li *ngFor="let subitem of linkitem.items">
                      <span *ngIf="subitem.status == 'hide'">
                        <i class="{{subitem.icon}}"></i>
                        {{subitem.label}}
                      </span>
                      <a *ngIf="subitem.status == 'show'" [routerLink]="[subitem.routerLink]" (click)="toggle()">
                        <i class="{{subitem.icon}}"></i>
                        {{subitem.label}}
                      </a>
                    </li>
                  </ul>
                </ng-container>
                <ng-container *ngIf="!linkitem.items">
                  <a *ngIf="linkitem.status == 'show'" [routerLink]="[linkitem.routerLink]" (click)="toggle()">
                    <i class="{{linkitem.icon}}"></i>
                    {{linkitem.label}}
                  </a>
                </ng-container>
            </li>
          </ul>
        </div>
      </ng-container>
    </nav>
  </div>
</div>
