<nz-page-header *ngIf="!limitedView" class="site-page-header px-2" (click)="onBack()" nzBackIcon nzTitle="Dettaglio rata">
</nz-page-header>
<nz-page-header *ngIf="limitedView" class="site-page-header px-2" nzTitle="Dettaglio rata">
</nz-page-header><div class="row justify-content-end">
    <div class="col-3 p-4" >
        <button nz-button nzType="primary"  nzSize="large"  (click)="showEventModal()">
            <span>Nuova Rata in calandario</span>
        </button>
        <nz-modal
            [(nzVisible)]="isVisible"
            [nzOkLoading]="isOkLoading"
            [nzOkDisabled]="!dataInizioModal"
            nzTitle="Nuova evento rata"
            (nzOnCancel)="handleEventCancel()" 
            (nzOnOk)="handleEventOk()"
        >
        <ng-container *nzModalContent>
            <div class="row">
              <div class="col-6">
                Data inizio
                <nz-date-picker nzFormat="dd/MM/yyyy"  [(ngModel)]="dataInizioModal" ></nz-date-picker>
              </div>
            </div>
            <div class="row">
                <div class="col-12">
                    note
                    <textarea nz-input placeholder="Note" [(ngModel)]="noteModal" ></textarea>
                </div>
            </div>
        </ng-container>
        </nz-modal>
    </div>
    <div class="col-2 p-4" *ngIf="!limitedView">
        <button nz-button nzType="default" nzDanger nzSize="large" (click)="revocaRateazione()">
        Revoca Rata
        </button>
    </div>
    <div class="card p-5">
        <ng-container *ngIf="detailRateazione">
            <div class="row">
                <div class="col">
                    Codice ente
                    <input nz-input placeholder="Codice ente" [(ngModel)]="detailRateazione.codiceEnte" [disabled]="true" />
                </div>
                <div class="col">
                    Nome ente
                    <input nz-input placeholder="Nome ente" [(ngModel)]="detailRateazione.nomeEnte" [disabled]="true" />
                </div>
                <div class="col">
                    Sede ente
                    <input nz-input placeholder="Sede ente" [(ngModel)]="detailRateazione.sedeEnte" [disabled]="true" />
                </div>
                <div class="col">
                    Tipo Rateazione
                    <input nz-input placeholder="Tipo Rateazione" [(ngModel)]="detailRateazione.tipoCartella" [disabled]="true" />
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col">
                    Codice Rateazione
                    <input nz-input placeholder="Codice Rateazione" [(ngModel)]="detailRateazione.codiceCartella" [disabled]="true" />
                </div>
                <div class="col">
                    Codice Rata
                    <input nz-input placeholder="Responsabile Procedimento" [(ngModel)]="detailRateazione.codiceRateazione" [disabled]="true" />
                </div>
                <div class="col">
                    Tipo Rata
                    <input nz-input placeholder="Telefono Responsabile" [(ngModel)]="detailRateazione.tipoRata" [disabled]="true" />
                </div>
                <div class="col">
                    Numero rate ritardabili
                    <input nz-input placeholder="Telefono Responsabile" [(ngModel)]="detailRateazione.maxScadute" [disabled]="true" />
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col">
                    Numero Rate
                    <input nz-input placeholder="Codice Rateazione" [(ngModel)]="detailRateazione.numeroRate" [disabled]="true" />
                </div>
                <div class="col">
                    Data prima scadenza
                    <input nz-input placeholder="Tipo Rateazione" [(ngModel)]="detailRateazione.dataPrimaScadenza" [disabled]="true" />
                </div>
                <div class="col">
                    Cadenza Rata
                    <input nz-input placeholder="Responsabile Procedimento" [(ngModel)]="detailRateazione.cadenzaRata" [disabled]="true" />
                </div>
                <div class="col">
                    Importo effettivo
                    <input nz-input placeholder="Telefono Responsabile" [(ngModel)]="detailRateazione.importoEffettivo" [disabled]="true" />
                </div>
                <div class="col">
                    Importo ipotetico
                    <input nz-input placeholder="Telefono Responsabile" [(ngModel)]="detailRateazione.importoIpotetico" [disabled]="true" />
                </div>
            </div>
        </ng-container>
        <nz-divider></nz-divider>
        <ng-container *ngIf="detailRateazione">
            <div class="row pb-4" *ngFor="let rata of detailRateazione.rate">
                <div class="col">
                    Rata {{rata.numeroRata}} di {{detailRateazione.numeroRate}}
                </div>
                <div class="col">
                    Data Scadenza
                    <input nz-input placeholder="Data Scadenza" [ngModel]="rata.dataScadenza | date:'shortDate'" (ngModelChange)="rata.dataScadenza=$event" [disabled]="true" />
                </div>
                <div class="col col align-self-end">
                    Giorni alla scadenza <br> {{rata.giorniMancanti}}
                </div>
                <div class="col">
                    Importo
                    <input nz-input placeholder="Importo" [(ngModel)]="rata.importo" [disabled]="true" />
                </div>
                <div class="col">
                    Importo Ipotetico
                    <input nz-input placeholder="Importo" [(ngModel)]="rata.importoIpotetico" [disabled]="true" />
                </div>
                <div class="col align-self-end">
                    <button *ngIf="rata.dataPagamento==null" nz-button nzType="primary" (click)="registraPagamento(rata)">
              Registra Pagamento
                  </button>
                    <div *ngIf="rata.dataPagamento!=null">Pagamento effettuato in data: {{rata.dataPagamento | date:'shortDate'}}</div>
                </div>

                <div class="col align-self-end">
                    <button *ngIf="rata.dataPagamento==null" nz-button nzType="link" (click)="edit(rata)">
                         <span nz-icon nzType="edit"></span>
                    </button>
                    <div *ngIf="rata.dataPagamento!=null">{{rata.tipoPagamento}} {{rata.note}}</div>
          
                </div>
            </div>
        </ng-container>
    </div>
</div>

<!-- modale modifica Importo-->
<ng-container *ngIf="detailRateazione && rataSelezionata">
    <nz-modal [(nzVisible)]="isVisibleEdit" nzTitle="Modifica Rateazione {{idRateazione}} - 
        RATA {{rataSelezionata.numeroRata}} di {{detailRateazione.numeroRate}}" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
        <ng-container *nzModalContent>
            <div class="row">
                <div class="col">
                    Data Scadenza
                    <!--<input nz-input placeholder="Data Scadenza" [ngModel]="rataSelezionata.dataScadenza | date:'shortDate'" (ngModelChange)="rataSelezionata.dataScadenza=$event"  /> -->
                    <nz-date-picker [(ngModel)]="rataSelezionata.dataScadenza" (ngModelChange)="rataSelezionata.dataScadenza=$event" [disabled]="rataSelezionata.fromFile"></nz-date-picker>
                </div>
                <div class="col">
                    Importo
                    <input nz-input placeholder="Importo" [(ngModel)]="rataSelezionata.importo" [disabled]="rataSelezionata.fromFile"/>
                </div>
                <div class="col">
                    Importo ipotetico
                    <input nz-input placeholder="Importo ipotetico" [(ngModel)]="rataSelezionata.importoIpotetico" [disabled]="rataSelezionata.fromFile"/>
                </div>
            </div>
            <div nz-col nzSpan="12">
                <nz-card nzTitle="bollettino pagamento" nzSize="small">
                    <p  *ngIf="!distintaPagamento">
                        Allega file
                        <br>
                        <input id="file" type="file" [(ngModel)]="modelFileRcv"
                            (change)="handleFileInput($event, 'distinta')" />
                    </p>
                    <br>
                    <div class="row" *ngIf="distintaPagamento">
                        <div class="col">
                            Nome allegato
                            <input nz-input [(ngModel)]="distintaPagamento.fileName" [disabled]="true"
                                class="disabledBox" />
                        </div>
                        <div class="col">
                            <button nz-button nzType="primary" nzSize="small"
                                style="background-color: brown; border-color: brown;"
                                (click)="deleteAllegato(distintaPagamento.fileName, 'distinta')">
                                <i nz-icon nzType="minus" nzTheme="outline"></i>
                            </button>
                        </div>
                    </div>
                </nz-card>
            </div>
        </ng-container>
    </nz-modal>
</ng-container>


<!-- modale registra pagamneto -->
<ng-container *ngIf="detailRateazione && rataSelezionata">
    <nz-modal [(nzVisible)]="isVisibleRegistraPagamento" nzTitle="Registra pagamento" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOkPagamento()">
        <ng-container *nzModalContent>
            <div class="row mb-3">
                <div class="col">
                    Data Pagamento
                    <nz-date-picker [ngModel]="dataPagamento | date:'shortDate'" (ngModelChange)="dataPagamento=$event"></nz-date-picker>
                </div>
                <div class="col">
                    Importo
                    <input nz-input placeholder="Importo" [(ngModel)]="rataSelezionata.importo" [disabled]="rataSelezionata.fromFile"/>
                </div>
                <div class="col">
                    Tipo Pagamento<br>
                    <nz-select [(ngModel)]="rataSelezionata.idTipoPagamento" [nzAllowClear]="true" nzPlaceHolder="Tipo " >
                      <nz-option *ngFor="let tipi of detailRateazione.tipiPagamento" [nzValue]="tipi.code" [nzLabel]="tipi.name"></nz-option>
                    </nz-select>
                  </div>
            </div>
            <div class="row mb-3">
                
                <div class="col">
                    Note
                    <input nz-input placeholder="note pagamento" [(ngModel)]="rataSelezionata.note" />
                </div>
                
            </div>
            <div nz-row nzGutter="8">
                
                <div nz-col nzSpan="12">
                    <nz-card nzTitle="ricevuta pagamento" nzSize="small">
                        <p  *ngIf="!ricevutaPagamento">
                            Allega file
                            <br>
                            <input id="file" type="file" [(ngModel)]="modelFileRcv"
                                (change)="handleFileInput($event, 'ricevuta')" />
                        </p>
                        <br>
                        <div class="row" *ngIf="ricevutaPagamento">
                            <div class="col">
                                Nome allegato
                                <input nz-input [(ngModel)]="ricevutaPagamento.fileName" [disabled]="true"
                                    class="disabledBox" />
                            </div>
                            <div class="col">
                                <button nz-button nzType="primary" nzSize="small"
                                    style="background-color: brown; border-color: brown;"
                                    (click)="deleteAllegato(ricevutaPagamento.fileName, 'ricevuta')">
                                    <i nz-icon nzType="minus" nzTheme="outline"></i>
                                </button>
                            </div>
                        </div>
                    </nz-card>
                </div>    
                <div nz-col nzSpan="12">
                    <nz-card nzTitle="bollettino pagamento" nzSize="small">
                        <p  *ngIf="!distintaPagamento">
                            Allega file
                            <br>
                            <input id="file" type="file" [(ngModel)]="modelFileRcv"
                                (change)="handleFileInput($event, 'distinta')" />
                        </p>
                        <br>
                        <div class="row" *ngIf="distintaPagamento">
                            <div class="col">
                                Nome allegato
                                <input nz-input [(ngModel)]="distintaPagamento.fileName" [disabled]="true"
                                    class="disabledBox" />
                            </div>
                            <div class="col">
                                <button nz-button nzType="primary" nzSize="small"
                                    style="background-color: brown; border-color: brown;"
                                    (click)="deleteAllegato(distintaPagamento.fileName, 'distinta')">
                                    <i nz-icon nzType="minus" nzTheme="outline"></i>
                                </button>
                            </div>
                        </div>
                    </nz-card>
                </div>

            </div>
        </ng-container>
    </nz-modal>
</ng-container>


<!-- modale revoca pagamento -->
<ng-container *ngIf="detailRateazione">
    <nz-modal [(nzVisible)]="isVisibleRevoca" nzTitle="Revoca Rata {{idRateazione}}" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOkRevoca(detailRateazione.idRateazione)">
        <ng-container *nzModalContent>
            <div class="row">
                <div class="col">
                    Totale rate scadute:
                </div>
                <div class="col">
                    Giorni ritardo totali
                </div><!--
                <div class="col">
                    Data Revoca
                    <nz-date-picker [ngModel]="dataRevoca | date:'shortDate'" (ngModelChange)="dataRevoca=$event"></nz-date-picker>
                </div>-->
            </div>
            <!--
            <div class="row">
                <div class="col">
                    Note
                    <input nz-input placeholder="Note" [(ngModel)]="note" />
                </div>

            </div>-->
        </ng-container>
    </nz-modal>
</ng-container>