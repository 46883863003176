import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { AvvisoService } from 'src/app/_services/avviso.service';
import { CommonService } from 'src/app/_services/commons.service';
import { NuovoAvviso, DettaglioAvviso } from 'src/app/models/avvisoModel';
import { Allegato } from 'src/app/models/commonModel';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nuovo-avviso',
  templateUrl: './nuovo-avviso.component.html',
  styleUrls: ['./nuovo-avviso.component.scss']
})
export class NuovoAvvisoComponent implements OnInit, OnDestroy {
  tipologia:any;
  codice: any;
  codiceEnte: any;
  dataRicezione: any;
  dataPagamento:any;
  giorniScadenza: any;
  idAzienda: any;
  idEnte: any;
  sedeEnte: any;
  importo: any;
  note:any;
  riferimentoAnno: any;
  selectedEnte : any;
  enti : any =[];
  modelFilePag:any;
  location!: Location;
  checkTipo:boolean = false;
  dettaglioAvviso: boolean = false;
  isVisibleEdit = false;
  sedeEnteModal:any;
  annoRifModal:any;
  importoModal:any;
  NoteModal:any;
  selectedEnteModal:any;
  dettaglioModal:DettaglioAvviso = {
    idEnte: 0,
    codiceEnte: '',
    nomeEnte: '',
    sedeEnte: '',
    codice: '',
    idAzienda: 0,
    importo:0,
    riferimentoAnno: '',
    note: '',
  }
  nuovoAvviso: NuovoAvviso = {
    codice: '',
    codiceEnte: '',
    dataRicezione: '',
    giorniScadenza: 0,
    idAzienda: 0,
    idEnte: 0,
    sedeEnte: '',
    importo: 0,
    note: '',
    flagPagato: false,
    dataPagamento: '',
    responsabileProcedimento: '',
    riferimentoAnno: '',
    tipologiaEnte:'',
    documenti: [],
    pagamenti: [],
    dettagli:[]
  }
  modelFile: any;

  avvisoInitSub: Subscription | undefined;
  subSalva: Subscription | undefined;
  subConvertFile: Subscription | undefined;

  constructor(
    private datePipe: DatePipe,
    private avvisoService: AvvisoService,
    private commonsService: CommonService,
    private route: ActivatedRoute,
    private router: Router
    ) { }

  ngOnInit(): void {
    this.avvisoInitSub = this.avvisoService.getAvvisoInitSave(0).subscribe(
      (data) => {
        if (data) {
          this.enti = data.enti;
        }
      },
      (err) => console.error(err),
      () => {

      }
    );
  }

  save() {
    let dric = this.datePipe.transform(this.dataRicezione,"dd/MM/yyyy");
    let dpag = this.datePipe.transform(this.dataPagamento,"dd/MM/yyyy");
    this.nuovoAvviso.codice = this.codice;
    this.nuovoAvviso.idEnte = this.selectedEnte;
    this.nuovoAvviso.codiceEnte = this.codiceEnte;
    this.nuovoAvviso.sedeEnte = this.sedeEnte;
    this.nuovoAvviso.dataRicezione = dric!;
    this.nuovoAvviso.giorniScadenza = this.giorniScadenza;
    this.nuovoAvviso.importo = this.importo;
    this.nuovoAvviso.idAzienda = Number(sessionStorage.getItem("selectedStore"));
    this.nuovoAvviso.note = this.note;
    this.nuovoAvviso.riferimentoAnno = this.riferimentoAnno;
    this.nuovoAvviso.dataPagamento = dpag!;
    this.subSalva = this.avvisoService.save(this.nuovoAvviso).subscribe(
      (data) => {
        if (data) {
          this.router.navigate(['/avvisi']);
        }
      },
      (err) => console.error(err),
      () => {

      }
    );
  }

  saveRateazione() {
    let dric = this.datePipe.transform(this.dataRicezione,"dd/MM/yyyy");
    this.nuovoAvviso.codice = this.codice;
    this.nuovoAvviso.idEnte = this.selectedEnte;
    this.nuovoAvviso.codiceEnte = this.codiceEnte;
    this.nuovoAvviso.dataRicezione = dric!;
    this.nuovoAvviso.giorniScadenza = this.giorniScadenza;
    this.nuovoAvviso.importo = this.importo;
    this.nuovoAvviso.idAzienda = Number(sessionStorage.getItem("selectedStore"));
    this.nuovoAvviso.note = this.note;
    this.nuovoAvviso.riferimentoAnno = this.riferimentoAnno;
    this.subSalva = this.avvisoService.save(this.nuovoAvviso).subscribe(
      (data) => {
        if (data) {
           //this.router.navigateByUrl('/cartelle/nuova-cartella');
           this.router.navigate(['/cartelle/nuova-cartella', {id:data.id}]);
        }
      },
      (err) => console.error(err),
      () => {

      }
    );
  }

  onBack(){
    this.location.back();
  }

  dettaglio() {
    this.isVisibleEdit = true;
  }

  numberOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  onChangeTipologia(){
    if(this.tipologia === '1 - Ente Singolo'){
      this.checkTipo = true;
      this.dettaglioAvviso = false;
    }else{
      this.checkTipo = false;
      this.dettaglioAvviso = true;
    }
    this.nuovoAvviso.tipologiaEnte =  this.tipologia;
  }

  handleFileInput(event: Event, tipo:any) {
    const element = event.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;
    if (fileList) {
      this.subConvertFile = this.convertFile(fileList[0]).subscribe(base64 => {
        let allegato:Allegato={
          content: base64,
          tipo: tipo,
          fileName: fileList![0].name,
          nome:'',
        }
        if(tipo==='documento'){
          this.modelFile = null;
          this.nuovoAvviso.documenti.push(allegato);
        }
      });
    }
}

  deleteAllegato(fileName:any, tipo:any) {
    if(tipo==='documento'){
      this.nuovoAvviso.documenti.forEach((element,index)=>{
        if(element.fileName==fileName) this.nuovoAvviso.documenti.splice(index, 1);
     });
    }
  }
  
  convertFile(file : File) : Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => 
    result.next(btoa(event.target!.result!.toString()));
    return result;
  }

  handleCancel(){
    this.clearModels();
    this.isVisibleEdit = false;
  }

  clearModels(){
    this.selectedEnteModal = null;
    this.sedeEnteModal = null;
    this.annoRifModal= null;
    this.importoModal= null;
    this.NoteModal=null;
  }

  handleOk(){
    let det: DettaglioAvviso= {
      idEnte: 0,
      codiceEnte: '',
      nomeEnte: '',
      sedeEnte: '',
      codice: '',
      idAzienda: 0,
      importo:0,
      riferimentoAnno: '',
      note: '',
    };
    det.idEnte = this.selectedEnteModal;
    //det.nomeEnte = this.nomeEnte;
    det.sedeEnte = this.sedeEnteModal;
    det.riferimentoAnno = this.annoRifModal;
    det.importo = this.importoModal;
    det.note = this.NoteModal;
    this.isVisibleEdit = false;
    this.nuovoAvviso.dettagli.push(det);
    this.clearModels();
    this.calcolaImporto();
  }

  calcolaImporto() {
    this.importo = this.nuovoAvviso.dettagli
      .map((avviso: DettaglioAvviso) => { return avviso.importo; } )
      .reduce( (tot: number, importo: number) => tot += importo
      , 0);
  }

  ngOnDestroy(): void {
    this.avvisoInitSub?.unsubscribe();
    this.subSalva?.unsubscribe();
    this.subConvertFile?.unsubscribe();
  }
}
