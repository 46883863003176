import { Component, OnDestroy, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { Location, formatDate } from '@angular/common';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AdempimentoDetail, NuovaCartella, RateizzaAdempimento, RichiestaRateazione, SaveDettaglio } from 'src/app/models/cartellaModel';
import { CartellaService } from "src/app/_services/cartella.service";
import { DatePipe } from '@angular/common';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { Allegato } from 'src/app/models/commonModel';
import { Avviso} from "src/app/models/avvisoModel";
import { ToolsService } from 'src/app/_services/tools.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-dettaglio-adempimento',
  templateUrl: './dettaglio.component.html',
  styleUrls: ['./dettaglio.component.scss']
})
export class DettaglioAdempimentoComponent implements OnInit {
  isVisible = false;
  isOkLoading = false;
  
  dataInizioModal:any;
  noteModal:any;

  loading: boolean = false;
  tipoScelta:any;
  modelFileNotifica: any;
  modelFileRateazioni: any;
  modelFileBollettini: any;
  modelFileRichieste: any;
  fileToUpload: File | null = null;
  isVisibleEdit = false;
  location!: Location;
  codiceEnte:any;
  nomeEnte:any;
  sedeEnte:any;
  sedeEnteModal:any;
  annoRifModal:any;
  importoModal:any;
  NoteModal:any;
  idAdempimento: any;
  codice:any;
  dataProtocollo:any;
  dataResoEsecutivo:any;
  dataScadenza:any;
  dataRichiesta:any;
  note:any
  annoRiferimento:any;
  selectedTipoCartella:any;
  selectedEnteModal:any;
  tipologie: any = [];
  campiObbligatori: boolean = false;
  sceltaTipo: boolean = true;
  checkTipo:boolean = false;
  tipologiaEnte:any;
  selectedEnte : any;
  enti : any =[];
  content: any;
  fileName: any;
  dettaglioRateazione: boolean = false;
  visualizzaAllegati: boolean = false;
  viewAvviso: boolean = false;
  editRichiesta: boolean = true;
  viewProtocollo: boolean = true;
  editProtocollo: boolean = true;
  viewRateazioneLink: boolean = false;
  documentiRichiesta :Allegato[] = [];

  documentiNotifica :Allegato[] = [];

  documentiRateazione :Allegato[] = [];

  documentiBollettini :Allegato[] = [];

  saveAllegato:Allegato={
    content: '',
    tipo: '',
    fileName: '',
    nome: '',
  }
 dettaglioModal:SaveDettaglio = {
  idEnte: 0,
  codiceEnte: '',
  nomeEnte: '',
  sedeEnte: '',
  codice: '',
  idAzienda: 0,
  importo:0,
  riferimentoAnno: '',
  note: '',
}


avviso : Avviso = {
  idAvviso: 0,
  idEnte: 0,
  codiceEnte: '',
  nomeEnte: '',
  sedeEnte: '',
  codice: '',
  dataRicezione: '',
  giorniScadenza: 0,
  importo: 0,
  responsabileProcedimento: '',
  note: '',
  riferimentoAnno: '',
  tipologiaEnte: '',
  flagPagato: false,
  dataPagamento: '',
  dataScadenza: '',
  stato: '',
  idAdempimento: 0,
  dettagli :[],
  documenti : [],
  documentiPagamento: [],
  editable:true,
}

adempimento: AdempimentoDetail = {
    idAdempimento: 0,
    avviso: null,
    codice: '',
    codiceEnte: '',
    dataProtocollo: '',
    dataResoEsecutivo: '',
    dataScadenza: '',
    idAzienda: 0,
    idEnte: 0,
    idRateazione : 0,
    importoIpotetico:0,
    importoEffettivo:0,
    note: '',
    numeroRuolo: '',
    rataUnica: false,
    responsabileProcedimento: '',
    riferimentoAnno: '',
    sedeEnte: '',
    nomeEnte:'',
    numeroProtocollo: '',
    tipo: '',
    tipologiaEnte: '',
    dataRichiesta: '',
	  maxScadute: 0,
	  numeroRateazioni: 0,
	  numeroRateTotali: 0,
	  numeroRatePagate: 0,
  	numerRateScadute: 0,
  	colorStato: '',
  	labelStato: '',
  	importoTotale: 0,
	  importoPagato: 0,
	  importoScaduto: 0,
	  colorPagamenti: '',
	  labelPagamenti: '',
	  colorRow: '',
    idStato: 0,
    stato: '',
    dettagli: [],
    bollettini: [],
    documentiNotifica: [],
    documentiRateazioni: [],
    documentiRichiesta: [],
  }

  richiesta : RichiestaRateazione = {
    idAdempimento: 0,
    dataRichiesta: '',
    importoIpotetico:0,
    importoEffettivo:0,
    documentiRichiesta: [],
  }

  rateizzaAdempimento: RateizzaAdempimento ={
    idAdempimento: 0,
    dataProtocollo: '',
    numeroProtocollo: '',
    responsabileProcedimento: '',
    numeroRuolo: '',
    bollettini: [],
    documentiNotifica: [],
    documentiRateazioni: [],
  }
  
  private saveEventoSub?: Subscription;

  constructor(private router: Router,
              private cartellaService: CartellaService,
              private route: ActivatedRoute,
              private datePipe: DatePipe,
              private toolsService: ToolsService,
              private nzModalService: NzModalService,
              private message: NzMessageService,
              @Inject(LOCALE_ID) public locale: string) { }

  ngOnInit(): void {
    this.idAdempimento = this.route.snapshot.paramMap.get('id');
    this.init();
  }

  ngOnDestroy(): void {
    this.saveEventoSub?.unsubscribe();
  }

  init(){
    this.loading = true;
    this.cartellaService.detail(this.idAdempimento).subscribe(
      (data) => {
        if (data) {
          this.adempimento = data;
          this.documentiBollettini = this.adempimento.bollettini;
          this.documentiNotifica  = this.adempimento.documentiNotifica;
          this.documentiRateazione = this.adempimento.documentiRateazioni;
          this.documentiRichiesta = this.adempimento.documentiRichiesta;
          if(this.adempimento.avviso != null) {
            this.viewAvviso = true;
            this.avviso = this.adempimento.avviso;
          }else {
            this.viewAvviso = false;
          }
          if(this.adempimento.idRateazione) {
            this.viewRateazioneLink = true;
          } else{ 
            this.viewRateazioneLink = false;
          }
          switch(this.adempimento.idStato) {
            case 1 : {
              this.editRichiesta = true;
              this.viewProtocollo = false;
              this.editProtocollo = false;
              break;
            }
            case 2 : {
              this.editRichiesta = false;
              this.viewProtocollo = true;
              this.editProtocollo = true;
              break;
            }
            case 3 :
            case 5 : {
              this.editRichiesta = false;
              this.viewProtocollo = true;
              this.editProtocollo = false;
              break;
            }
            default: {
              break;
            }
          }
          console.log(data);
          
        }
      },
      (err) => console.error(err),
      () => {
        this.loading = false;
      }
    );
  }

  populateFromAvviso() {
    if(this.avviso) {
      this.viewAvviso = true;
      this.selectedEnte = this.avviso.idEnte;
      this.sedeEnte = this.avviso.sedeEnte;
      this.annoRiferimento = this.avviso.riferimentoAnno ;
      this.codice = this.avviso.codice ;
      this.tipologiaEnte = this.avviso.tipologiaEnte;
      this.viewProtocollo = false;
    } else {
      this.viewAvviso = false;
      this.visualizzaAllegati = true;
    }
  }

  onBack() {
    this.location.back();
  }
  
  save() {
   
  }

  saveRichiesta() {
    if (!this.dataRichiesta) {
      this.nzModalService.warning({
        nzTitle: 'Attenzione',
        nzContent: 'Non è possibile inviare la richiesta senza aver inserito la data',
        nzOkText: 'Ok',
        nzOkType: 'primary',
        nzOkDanger: true,
        nzOnOk: () => {},
        nzCancelText: 'Annulla',
        nzOnCancel: () => {}
      });
      return;
    }
    let dRichiesta = this.datePipe.transform(this.dataRichiesta,"dd/MM/yyyy");
    this.richiesta.idAdempimento = this.idAdempimento;
    this.richiesta.dataRichiesta = dRichiesta!;
    this.richiesta.importoEffettivo = this.adempimento.importoEffettivo;
    this.richiesta.importoIpotetico = this.adempimento.importoIpotetico;
    this.richiesta.documentiRichiesta = this.documentiRichiesta;
    this.cartellaService.richiesta(this.richiesta).subscribe(
      (data) => {
        if (data) {
          console.log(data);
          this.router.navigate(['/cartelle'])
        }
      },
      (err) => console.error(err),
      () => {

      }
    );
  }

  rateizza() {
    
    let dt = this.datePipe.transform(this.dataProtocollo,"dd/MM/yyyy");
    this.rateizzaAdempimento.idAdempimento = this.idAdempimento;
    this.rateizzaAdempimento.dataProtocollo = dt!;
    this.rateizzaAdempimento.numeroProtocollo = this.adempimento.numeroProtocollo;
    this.rateizzaAdempimento.responsabileProcedimento = this.adempimento.responsabileProcedimento;
    this.rateizzaAdempimento.numeroRuolo = this.adempimento.numeroRuolo
    this.rateizzaAdempimento.documentiNotifica = this.documentiNotifica;
    this.rateizzaAdempimento.documentiRateazioni = this.documentiRateazione;
    this.rateizzaAdempimento.bollettini = this.documentiBollettini;
    this.cartellaService.rateizza(this.rateizzaAdempimento).subscribe(
      (data) => {
        if (data) {
          console.log(data);
          this.router.navigate(['/cartelle'])
        }
      },
      (err) => console.error(err),
      () => {

      }
    );
  }

  nuovaRateazione() {
    this.router.navigate(['rateazioni/nuova-rateazione', { idAdempimento: this.adempimento.idAdempimento }])
  }

  showRateazione() {
    this.router.navigate(['/rateazioni/dettaglio', { id: this.adempimento.idRateazione }])
  }
 
  onChangeTipologiaEnte(){
    if(this.tipologiaEnte === '1 - Ente Singolo'){
      this.checkTipo = true;
      this.dettaglioRateazione = false;
    }else{
      this.checkTipo = false;
      this.dettaglioRateazione = true;
    }
    this.adempimento.tipologiaEnte =  this.tipologiaEnte;
  }

  verificaTipologiaEnte(){
    if(this.tipologiaEnte === '1 - Ente Singolo'){
      this.checkTipo = true;
      this.dettaglioRateazione = false;
    } else if(this.tipologiaEnte === '2 - Agenzia Entrate' || this.tipologiaEnte === '3 - Agenzia Entrate e Riscossione'){
      this.checkTipo = false;
      this.dettaglioRateazione = true;
    }else{
      this.checkTipo = true;
      this.dettaglioRateazione = false;
    }
  }

  onChangeTipologia(){
    this.sceltaTipo = false;
    if(this.tipoScelta === 'adempimenti'){
      this.adempimento.rataUnica = true;
    }
  }

  dettaglio() {
    this.isVisibleEdit = true;
  }

  handleCancel(){
    this.clearModels();
    this.isVisibleEdit = false;
  }

  numberOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  handleOk(){
    let det: SaveDettaglio= {
      idEnte: 0,
      codiceEnte: '',
      nomeEnte: '',
      sedeEnte: '',
      codice: '',
      idAzienda: 0,
      importo:0,
      riferimentoAnno: '',
      note: '',
    };
    det.idEnte = this.selectedEnteModal;
    det.nomeEnte = this.nomeEnte;
    det.sedeEnte = this.sedeEnteModal;
    det.riferimentoAnno = this.annoRifModal;
    det.importo = this.importoModal;
    det.note = this.NoteModal;
    this.isVisibleEdit = false;
    this.adempimento.dettagli.push(det);
    this.clearModels();
  }

  clearModels(){
    this.selectedEnteModal = null;
    this.sedeEnteModal = null;
    this.annoRifModal= null;
    this.importoModal= null;
    this.NoteModal=null;
  }

  deleteAllegato(fileName:any, tipo:any) {
    if(tipo==='notifica'){
      this.documentiNotifica.forEach((element,index)=>{
        if(element.fileName==fileName) this.documentiNotifica.splice(index, 1);
     });
    }
    if(tipo==='rateazioni'){
      this.documentiRateazione.forEach((element,index)=>{
        if(element.fileName==fileName) this.documentiRateazione.splice(index, 1);
     });
    }
    if(tipo==='bollettini'){
      this.documentiBollettini.forEach((element,index)=>{
        if(element.fileName==fileName) this.documentiBollettini.splice(index, 1);
     });
    }
    if(tipo==='richiesta'){
      this.documentiRichiesta.forEach((element,index)=>{
        if(element.fileName==fileName) this.documentiRichiesta.splice(index, 1);
     });
    }
  }

  handleFileInput(event: Event, tipo:any) {
    const element = event.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;
    if (fileList) {
      this.convertFile(fileList[0]).subscribe(base64 => {
        let allegato:Allegato={
          content: base64,
          tipo: tipo,
          fileName: fileList![0].name,
          nome:'',
      }
      if(tipo==='notifica'){
        this.modelFileNotifica = null;
         this.documentiNotifica.push(allegato);
      }
      if(tipo==='rateazione'){
        this.modelFileRateazioni = null;
         this.documentiRateazione.push(allegato);
      }
      if(tipo==='bollettini'){
        this.modelFileBollettini= null;
         this.documentiBollettini.push(allegato);
      }
      if(tipo==='richiesta'){
        this.modelFileRichieste= null;
         this.documentiRichiesta.push(allegato);
      }
      });

    }
}

convertFile(file : File) : Observable<string> {
  const result = new ReplaySubject<string>(1);
  const reader = new FileReader();
  reader.readAsBinaryString(file);
  reader.onload = (event) => 
  result.next(btoa(event.target!.result!.toString()));
  return result;
}

download(allegato: Allegato) {
  this.toolsService.download(allegato.id).subscribe({
    next: (data: Blob) => {
      const a = document.createElement('a');
      document.body.appendChild(a);
      var url = window.URL.createObjectURL(data);
      a.href = url;
      a.download = allegato.nome;
      a.click();
      window.URL.revokeObjectURL(url);
    },
    error: (err) => console.log(err),
    complete: () => { }
  })
}

  showEventModal(): void {
    this.isVisible = true;
  }

  handleEventOk(): void {
    this.isOkLoading = true;
    const selectedIdAzienda = Number(sessionStorage.getItem("selectedStore"));
    const formattedDataInizio = formatDate(this.dataInizioModal, 'dd/MM/yyyy' ,this.locale);
    this.saveEventoSub = this.toolsService.saveEventoCalendario({
      dataInizio: formattedDataInizio,
      idAzienda: selectedIdAzienda,
      note: this.noteModal ? this.noteModal : '',
      tipologia: 'AV'
    }).subscribe({
        next: (data) => {
          if (data.esito)
            this.message.success(data.message);
          else
            this.message.error('Errore durante il salvataggio dell\'evento:' + data?.message);
          this.isVisible = false;
          this.isOkLoading = false;
          this.dataInizioModal = null;
          this.noteModal = null;
        },
        error: (error) => { 
          this.isVisible = false;
          this.isOkLoading = false;
          this.dataInizioModal = null;
          this.noteModal = null;
        }
      })
  }

  handleEventCancel(): void {
    this.dataInizioModal = null;
    this.noteModal = null;
    this.isVisible = false;
  }

}
