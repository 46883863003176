import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/_services/user.service';
import { NavMenuItem, side_menu } from '../../../_menus';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})

export class NavComponent implements OnInit {

  public hide: boolean;
  public items!: NavMenuItem[];
  public tipoUtente!: string;

  constructor(private userService: UserService, private _eref: ElementRef, private router: Router) {
    this.hide = false;
  }

  toggle() {
    this.hide = !this.hide;
  }

  toggleSubMenu(item: NavMenuItem): void {
    item.childrenVisible = !item.childrenVisible;
  }

  ngOnInit() {
    this.items = side_menu;

  }

  nuovaCartella(){
    this.router.navigateByUrl('/cartelle/nuova-cartella');
  }

  nuovoAvviso(){
    this.router.navigateByUrl('/avvisi/nuovo-avviso');
  }

  nuovoBudget(){
    this.router.navigateByUrl('/budget/nuovo-budget');
  }
}
