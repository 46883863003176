import { Component, OnInit } from '@angular/core';
import { Cartella } from 'src/app/models/cartellaModel';
import { Configurations } from 'src/app/models/iconConfigModel';
import { DropdownList } from 'src/utils/utils';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { CartellaService } from "src/app/_services/cartella.service";
import { ToolsService } from "src/app/_services/tools.service";
import { Filtro } from "src/app/models/filtriModel";
import { NzTableQueryParams } from 'ng-zorro-antd/table';

@Component({
  selector: 'app-elenco',
  templateUrl: './elenco.component.html',
  styleUrls: ['./elenco.component.scss']
})
export class ElencoComponent implements OnInit {
  location!: Location;

  loading = false;

  tipoRata!: DropdownList[];
  tipoCartella!: DropdownList[];
  nomeEnte!: DropdownList[];
  date=null;
  tipoVista=[
    {id:1, link: 'cartelle', nome: 'rateazioni/adempimenti'}, 
    {id:2, link: 'rateazioni', nome: 'rate'},
    {id:3, link: 'avvisi', nome: 'avvisi'}
  ];
  tipiCartella: any = [];
  listaEnti: any = [];
  listaCodiciEnti: any = [];
  listaCodiciCartella: any = [];
  listaDateScadenza: any = [];
  tipiRata: any = [];
  selectedTipoVista= 'cartelle';
  selectedTipoRata: any;
  selectedTipoCartella: any;
  selectedNomeEnte: any;
  selectedCodiceEnte: any;
  selectedCodiceCartella: any;
  selectedDateScadenza: any;
  ordinamenti= ['codice adempimento', 'tipo adempimento', 'stato'];
  checkRateizzate: boolean = false;
  checkNonRateizzate: boolean = false;
  orderBy= '';
  filterName = "CARTELLA_FILTER";
  totalPage = 0;
  index_page = 0;

  cartelle: any = [];

  public filtro: Filtro = {    
    cartellaFilter: {
      codiceCartella: null,
      codiceEnte: null,
      codiceRata: null,
      dataScadenza: null,
      idStatoCarella: 0,
      nomeEnte: null,
      order: null,
      page: 0,
      rowNumber: 0,
      tipoCartella: null,
      tipoRata: null
    },
      enteFilter: {
        codice: null,
        nome: null,
        sede: null,
        page: 0,
        rowNumber: 10,
      },
      idAzienda: 1,
      
      rateazioneFilter: {
        codiceCartella: null,
        codiceEnte: null,
        codiceRata: null,
        dataPagamento: null,
        nomeEnte: null,
        numeroRata: 0,
        order: null,
        page: 0,
        rowNumber: 0,
        sedeEnte: null,
        tipoCartella: null,
        tipoRata: null,
        idStatoCartella: null,
        dataScadenza: null,
        pagato: null,
        scaduta: null,
      },
      avvisoFilter: {
        nomeEnte: null,
        codiceEnte: null,
        stato: null,
        dataScadenza: null,
        order: null,
        page: 0,
        rowNumber: 10,
      },
      budgetFilter: {
        dataInizio: null,
        dataFine: null,
        order: null,
        page: 0,
        rowNumber: 10,
      },
    };

  constructor(  
    private cartellaService: CartellaService,
    private toolsService: ToolsService,
    private router: Router,
  ) { }

  async ngOnInit() {
    console.log('tipovista init',this.selectedTipoVista)
    this.getListaCartelle();
  }
  

  getListaCartelle(){
    this.cartellaService.getCartellaInit().subscribe(
      (data) => {
        if (data) {
          data.results = data.results.map((item: any) => {
            // Add the "creditore" field to the item
            item.creditore = this.calculateCreditore(item);
            return item;
          });
          this.cartelle = data.results;
          this.listaEnti = data.entiLabels;
          this.tipiCartella = data.tipoCartella;
          this.tipiRata = data.listCodiceRata;
          this.listaCodiciEnti = data.listCodiceEnte;
          this.listaCodiciCartella = data.listCodiceCartella;
          this.listaDateScadenza = data.listScadenze;
          this.filtro = data.filter;
          this.selectedCodiceCartella = data.filter.cartellaFilter.codiceCartella;
          this.selectedCodiceEnte = data.filter.cartellaFilter.codiceEnte;
          this.selectedTipoRata= data.filter.cartellaFilter.codiceRata;
          this.selectedTipoCartella = data.filter.cartellaFilter.tipoCartella;
          this.selectedTipoRata = data.filter.cartellaFilter.tipoRata;
          this.selectedDateScadenza = data.filter.cartellaFilter.dataScadenza;
          this.totalPage = data.numberPage;
          this.index_page = (<Filtro>data.filter).cartellaFilter.page + 1;
        }
      },
      (err) => console.error(err),
      () => {

      }
    );
  }
  
  calculateCreditore(item: any): string {
    // Implement your rule for calculating the "creditore" field here
    if (item.tipo === 'Rientro') {
      return item.societaPianoRientro;
    } else {
      if (item.tipologiaEnte === '1 - Ente Singolo') {
        return item.nomeEnte;
      } else {
        return item.tipologiaEnte;
      }
    }
  }
 
  updateList() {
    this.filtro.cartellaFilter.codiceCartella = this.selectedCodiceCartella;
    this.filtro.cartellaFilter.codiceEnte = this.selectedCodiceEnte;
    this.filtro.cartellaFilter.codiceRata = this.selectedTipoRata;
    this.filtro.cartellaFilter.tipoCartella = this.selectedTipoCartella;
    this.filtro.cartellaFilter.tipoRata = this.selectedTipoRata;
    this.filtro.cartellaFilter.dataScadenza = this.selectedDateScadenza;
    this.cartellaService.getPopulateDropDown(this.filtro).subscribe(
      (data) => {
        if (data) {
          this.listaEnti = data.entiLabels;
          this.tipiCartella = data.listStatiCartella;
          this.tipiRata = data.listCodiceRata;
          this.listaCodiciEnti = data.listCodiceEnte;
          this.listaCodiciCartella = data.listCodiceCartella;
          this.listaDateScadenza = data.listScadenze;
        }
      },
      (err) => console.error(err),
      () => {

      }
    );
  }


  onChange(result: Date): void {
    console.log('onChange: ', result);
  }

  resetFiltri() {
    this.toolsService.resetFilter(this.filterName).subscribe(
      (data) => {
        if (data) {
          this.selectedTipoRata = '';
          this.selectedTipoCartella = '';
          this.selectedNomeEnte= '';
          this.selectedCodiceEnte= '';
          this.selectedCodiceCartella = '';
          this.selectedDateScadenza = '';
          this.getListaCartelle;
        }
      },
      (err) => console.error(err),
      () => {

      }
    );

  }

  search() {
    console.log(this.filtro)
    this.cartellaService.search(this.filtro).subscribe(
      (data) => {
        if (data) {
          data.results = data.results.map((item: any) => {
            // Add the "creditore" field to the item
            item.creditore = this.calculateCreditore(item);
            return item;
          });
          this.cartelle = data.results;
        }
      },
      (err) => console.error(err),
      () => {

      }
    );
  }

  onQueryParamsChange(e: NzTableQueryParams) {
    console.log(e.pageIndex);
    this.filtro.cartellaFilter.page = e.pageIndex - 1;
    if (this.filtro.cartellaFilter.page == -1) {
      this.filtro.cartellaFilter.page = 0;
    }
    setTimeout (() => { }, 1000);
    this.cartellaService.search(this.filtro).subscribe({
      next: data => this.cartelle = data.results.map((item: any) => {
        // Add the "creditore" field to the item
        item.creditore = this.calculateCreditore(item);
        return item;
      }),
      error: err => console.log(err),
      complete: () => { },
    })
  }

  goTo() {
   this.router.navigate(['/'+this.selectedTipoVista])
  }


   goToDetail(idCartella: number, tipoCartella: any) {
    this.router.navigate(['/cartelle/dettaglio', {id:idCartella}])
  }

  onBack() {
    this.location.back();
  }
  getIconProperties(type: string, cartella: any): any {
    const configurations: Configurations = {
      ball: {
        className: cartella.ballColor ? `icon-${cartella.ballColor}` : 'icon-grey',
        titleMapping: {
          grey: 'Richiesta rateazione o non ancora accettata',
          blue: 'Rateizzazione accettata'
        },
        defaultTitle: 'Stato sconosciuto'
      },
      dollar: {
        className: cartella.dollarColor ? `icon-${cartella.dollarColor}` : 'icon-grey',
        titleMapping: {
          orange: 'Rateazione in corso',
          green: 'Pagato iteramente'
        },
        defaultTitle: 'Stato sconosciuto'
      },
      calendar: {
        className: cartella.calendarColor ? `icon-${cartella.calendarColor}` : 'icon-grey',
        titleMapping: {
          grey: 'Non calendarizzato',
          blue: 'Pagamento programmato',
          green: 'Pagamento programmato'
        },
        defaultTitle: 'Non calendarizzato'
      }
    };
  
    const config = configurations[type];
  
    if (config) {
      const colorProperty = `${type}Color`; 
      const color = cartella[colorProperty];
      return {
        className: config.className,
        title: config.titleMapping[color] || config.defaultTitle
      };
    }
  
    return {
      className: 'icon-gray',
      title: 'Not specified'
    };
  }

}
