import { Component, OnInit, ViewChild } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Pagamento } from 'src/app/models/dashboard/pagamentoModel';
import { DashboardService } from "src/app/_services/dashboard.service"

import { Calendar } from '@fullcalendar/core'
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi, EventInput } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-calendar-pagamenti',
  templateUrl: './calendar-pagamenti.component.html',
  styleUrls: ['./calendar-pagamenti.component.scss']
})
export class CalendarPagamentiComponent implements OnInit {

  @ViewChild('fullcalendar') fullcalendar!: FullCalendarComponent;
  calendarVisible: boolean = true;
  calendarEvents: EventInput = [];
  calendarOptions: CalendarOptions = {
    plugins: [
      interactionPlugin,
      dayGridPlugin,
      timeGridPlugin,
      listPlugin,
    ],
    locale: 'it',
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridDay'
    },
    editable: true,
    selectable: true,
    dateClick: this.handleDateClick.bind(this),
  };
  isVisible: boolean = false;
  pagamenti: Pagamento[] = [];

  private sub: any;

  constructor(
    private modal: NzModalService,
    private dashboardService: DashboardService,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      if(params.get('id') && params.get('id') !== null){
        const date = new Date(params.get('id')!);
        this.calendarOptions.initialView = 'timeGridDay';
        this.calendarOptions.initialDate = date;
        this.getEvents();
      }
      else
      {
        this.calendarOptions.initialView = 'dayGridMonth';
        this.getEvents();
      }
    });
  }

  private getEvents() {
    this.dashboardService.getCalendar().subscribe(
      (data) => {
        if (data) {
          this.pagamenti = data;
          this.getCalendarActivities();
        }
      },
      (err) => console.error(err),
      () => {}
    );
  }
  private getCalendarActivities() {
    this.calendarEvents = this.listFormatting(this.pagamenti);
    this.setCalendarOptions(this.calendarEvents);
  }
  private parseColor(color: string) {
    if (color== 'red') return '#d82002';
    if (color== 'orange') return '#ff9900'
    if (color== 'pink') return '#fd7466'
    if (color== 'green') return '#058605'
    if (color== 'blue') return '#var(--primary-color)'
    else return;
  
  }
  private listFormatting(obj: string | any[]) {
    var options = [];
    for (let i = 0; i < obj.length; i++) {
      options.push(
        {
          title: obj[i].importo + '€',
          start: obj[i].data,
          allDay: true,
          textColor: this.parseColor(obj[i].colore),
          backgroundColor: '#fff',
          borderColor: '#fff'
          
        }
      )
    }
    return options;
  }

  private setCalendarOptions(events?: EventInput) {
    this.calendarOptions = {
      plugins: [
        interactionPlugin,
        dayGridPlugin,
        timeGridPlugin,
        listPlugin,
      ],
      locale: 'it',
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridDay'
      },
      editable: true,
      selectable: true,
      dayMaxEvents: false,
      events: events,
      eventsSet: this.handleEvents.bind(this)
    };
    
  }
  // handleDateClick(arg: any) {
  //   console.log('arg', arg);
  //   const modal = this.modal.create({
  //     nzTitle: 'Nuova attività',
  //     nzContent: NewActivityCalendarComponent,
  //     // nzViewContainerRef: this.viewContainerRef,
  //     nzClosable: false,
  //     nzComponentParams: {
  //       inputData: arg
  //     }

  //   })
  // }

  handleDateClick(obj: any) {
    this.fullcalendar.getApi().changeView('timeGridDay', obj.date);
  }

  handleEventClick(clickInfo: EventClickArg) {
    if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
      clickInfo.event.remove();
    }
  }

  handleEvents(events: EventApi[]) {
    //  this.currentEvents = events;
  }
}
