<nz-affix [nzOffsetTop]="60">
    <div class="row titleFilterHeader">
        <div class="col-12">
            <!--Page header: title, back, others actions-->
            <nz-page-header class="site-page-header px-2" (click)="onBack()" nzBackIcon nzTitle="Registrazione Nuova Rata"></nz-page-header>
        </div>
    </div>
</nz-affix>
<div class="row ">
    <div class="col-12 mt-5">
        <div class="card p-5">
            <form nz-form nzLayout='vertical' [formGroup]="nuovaRateazioneForm" (ngSubmit)="submitForm()" class="ant-advanced-search-form">
                <div nz-row [nzGutter]="24">
                    <div nz-col [nzSpan]="24">
                        <div class="row">
                            <nz-form-item class="col">
                                <nz-form-label [nzNoColon]="true" >Codice Rata</nz-form-label>
                                <nz-form-control nzErrorTip="Inserisci codice rateazione!">
                                    <input nz-input nzSize="large" formControlName="codiceRateazione" placeholder="Inserisci codice rata" />
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item class="col">

                                <nz-form-label [nzNoColon]="true" >Tipo Rata</nz-form-label>
                                <nz-form-control nzErrorTip="Inserisci tipo rata ente!">
                                    <nz-select nzSize="large" nzPlaceHolder="Seleziona tipo rata" formControlName="tipoRata" >
                                        <nz-option *ngFor="let tipo of tipiRata" [nzValue]="tipo" [nzLabel]="tipo.name"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item class="col">
                                <nz-form-label [nzNoColon]="true" nzRequired>Numero rate</nz-form-label>
                                <nz-form-control nzErrorTip="Inserisci numero rate">
                                    <input nz-input nzSize="large" formControlName="numeroRate" placeholder="Inserisci numero rate" required />
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item class="col">
                                <nz-form-label [nzNoColon]="true" nzRequired>N. max rate posticipabili</nz-form-label>
                                <nz-form-control nzErrorTip="Inserisci numero rate">
                                    <input nz-input nzSize="large" formControlName="maxScadute" placeholder="Inserisci numero rate" required />
                                </nz-form-control>
                            </nz-form-item>

                        </div>
                        <div class="row">
                            <nz-form-item class="col">
                                <nz-form-label [nzNoColon]="true" nzRequired>Data prima scadenza</nz-form-label>
                                <nz-form-control nzErrorTip="Inserisci Data prima scadenza!" required>
                                    <nz-date-picker nzSize="large" nzFormat="dd/MM/yyyy" formControlName="dataScadenza"></nz-date-picker>
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item class="col">
                                <nz-form-label [nzNoColon]="true">Cadenza rata</nz-form-label>
                                <nz-form-control nzErrorTip="Inserisci Cadenza rata!" required>
                                    <nz-select nzSize="large" nzPlaceHolder="Seleziona cadenza rata" formControlName="cadenzaRata">
                                        <nz-option *ngFor="let tipo of tipiCadenzaRata" [nzValue]="tipo" [nzLabel]="tipo.name"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item class="col">
                                <nz-form-label [nzNoColon]="true">Importo </nz-form-label>
                                <nz-form-control nzErrorTip="Inserisci Importo totale iniziale!" >
                                    <input nz-input nzSize="large" formControlName="importo" placeholder="Inserisci Importo " />
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item class="col">
                                <nz-form-label [nzNoColon]="true">Importo ipotetico</nz-form-label>
                                <nz-form-control nzErrorTip="Inserisci Importo ipotetico!" >
                                    <input nz-input nzSize="large" formControlName="importoIpotetico" placeholder="Inserisci Importo ipotetico" />
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <nz-form-item>
                            <nz-form-control>
                                <div nz-row>
                                    <div nz-col [nzSpan]="24" class="search-area">
                                        <button nz-button nzSize="large" [disabled]="nuovaRateazioneForm.invalid" nzType="primary" class="mt-3" (submit)="submitForm()">
                        Crea nuova rata
                      </button>
                                    </div>
                                </div>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>