<nz-page-header class="site-page-header px-2" (click)="onBack()" nzBackIcon nzTitle="Nuova Rateazione / Adempimento"></nz-page-header>
<div class="col-12 mb-3">

  <div class="row mb-3">
    <div class="col-3">
      Tipologia operazione
      <nz-select [(ngModel)]="tipoScelta" (ngModelChange)="onChangeTipologia()" nzPlaceHolder="Seleziona Tipologia">
        <nz-option nzValue="Rateazione" nzLabel="Rateazioni"></nz-option>
        <nz-option nzValue="Adempimento" nzLabel="Adempimenti"></nz-option>
        <nz-option nzValue="Rientro" nzLabel="Piano di rientro"></nz-option>
      </nz-select>
      </div>
  </div>

  <!--Dati Avviso-->
  <div class="row" *ngIf="viewAvviso">
    <nz-card nzTitle="Avviso">
      <div class="row">
        <div class="col">
            Tipologia
            <nz-select [(ngModel)]="avviso.tipologiaEnte" (ngModelChange)="onChangeTipologiaEnte()" [disabled]="true">
                <nz-option nzValue="1 - Ente Singolo" nzLabel="1 - Ente Singolo"></nz-option>
                <nz-option nzValue="2 - Agenzia Entrate" nzLabel="2 - Agenzia Entrate"></nz-option>
                <nz-option nzValue="3 - Agenzia Entrate e Riscossione" nzLabel="3 - Agenzia Entrate e Riscossione"></nz-option>
              </nz-select>
        </div>
        <div class="col">

        </div>
        <div class="col">

        </div>
        <div class="col">

        </div>
      </div>
      <br>
      <div class="row">
            <div class="col">
              Ente
              <nz-select [(ngModel)]="selectedEnte" [nzAllowClear]="true" nzPlaceHolder="Seleziona Ente " [disabled]="true">
                <nz-option *ngFor="let ente of enti" [nzValue]="ente.code" [nzLabel]="ente.name"></nz-option>
              </nz-select>
              </div>
              <div class="col">
                Sede Ente
                <input nz-input placeholder="Sede Ente" [(ngModel)]="sedeEnte" [disabled]="true"/>
              </div>
              <div class="col">
                Anno Riferimento
                <input nz-input placeholder="Anno Riferimento" [(ngModel)]="avviso.riferimentoAnno" [disabled]="true"/>
              </div>
              <div class="col">
                Codice Identificativo
                <input nz-input placeholder="Codice" [(ngModel)]="avviso.codice" [disabled]="true"/>
              </div>
      </div>
      <br>
      <div class="row">
          <div class="col">
            Data Ricezione <br>
            <nz-date-picker [(ngModel)]="avviso.dataRicezione" nzFormat="dd/MM/yyyy" [disabled]="true"></nz-date-picker>
            </div>
            <div class="col">
             Data Scadenza
              <nz-date-picker [(ngModel)]="avviso.dataScadenza" nzFormat="dd/MM/yyyy" [disabled]="true"></nz-date-picker>
            </div>
            <div class="col">
              Importo
              <input nz-input placeholder="Importo " [(ngModel)]="avviso.importo" [disabled]="true"/>
            </div>
            <div class="col">
              Note
              <input nz-input placeholder="Note" [(ngModel)]="note" />
            </div>
      </div>
    </nz-card>
</div>
<!-- Dati Rateazine se Avviso null -->
<div class="row" *ngIf="!viewAvviso">
  <nz-card *ngIf="tipoScelta !== 'Rientro'" nzTitle="Dati Rateazione / Adempimento">
    <div class="row">
      <div class="col">
          Tipologia
          <nz-select [(ngModel)]="tipologiaEnte" (ngModelChange)="onChangeTipologiaEnte()">
              <nz-option nzValue="1 - Ente Singolo" nzLabel="1 - Ente Singolo"></nz-option>
              <nz-option nzValue="2 - Agenzia Entrate" nzLabel="2 - Agenzia Entrate"></nz-option>
              <nz-option nzValue="3 - Agenzia Entrate e Riscossione" nzLabel="3 - Agenzia Entrate e Riscossione"></nz-option>
            </nz-select>
      </div>
      <div class="col">

      </div>
      <div class="col">

      </div>
      <div class="col">

      </div>
    </div>
    <br>
    <div class="row">
          <div class="col">
            Ente
            <nz-select [(ngModel)]="selectedEnte" [nzAllowClear]="true" nzPlaceHolder="Seleziona Ente " [disabled]="!checkTipo">
              <nz-option *ngFor="let ente of enti" [nzValue]="ente.code" [nzLabel]="ente.name"></nz-option>
            </nz-select>
            </div>
            <div class="col">
              Sede Ente
              <input nz-input placeholder="Sede Ente" [(ngModel)]="sedeEnte" [disabled]="!checkTipo"/>
            </div>
            <div class="col">
              Anno Riferimento
              <input nz-input placeholder="Anno Riferimento" [(ngModel)]="annoRiferimento" [disabled]="!checkTipo"/>
            </div>
            <div class="col">
              Codice Identificativo
              <input nz-input placeholder="Codice" [(ngModel)]="codice" />
            </div>
    </div>
  </nz-card>

  <nz-card *ngIf="tipoScelta === 'Rientro'" nzTitle="Dati Piano di rientro">
    <div class="row">
            <div class="col">
              Nome società
              <input nz-input placeholder="Nome società" [(ngModel)]="nuovaCartella.societaPianoRientro"/>
            </div>
            <div class="col">
              Note
              <input nz-input placeholder="Note" [(ngModel)]="nuovaCartella.notePianoRientro" />
            </div>
    </div>
  </nz-card>
</div>
<!-- Dettagli Rateazione se avviso null -->
<div class="d-flex flex-row-reverse" *ngIf="!viewAvviso && dettaglioRateazione">
  <div class="p-2 bd-highlight">
    <button nz-button nzSize="large" nzType="primary" class="mt-3" (click)="dettaglio()">
      Aggiungi dettaglio
    </button> 
  </div>
</div>
  <br>
  <div class="row" *ngIf="!viewAvviso && dettaglioRateazione">
      <div class="col-12">
        <nz-table #basicTable [nzData]="nuovaCartella.dettagli">
          <thead>
            <tr>
              <th>Ente</th>
              <th>Sede Ente</th>
              <th>Anno</th>
              <th>Importo</th>
              <th>Note</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of nuovaCartella.dettagli">
              <td>{{ item.nomeEnte }}</td>
              <td>{{ item.sedeEnte }}</td>
              <td>{{ item.riferimentoAnno }}</td>
              <td>{{ item.importo }}</td>
              <td>{{ item.note }}</td>
            </tr>
          </tbody>
        </nz-table>
      </div>
  </div>
<!-- Dettagli da Avviso-->
<div class="d-flex flex-row-reverse" *ngIf="viewAvviso">
  <div class="p-2 bd-highlight">
     
  </div>
</div>
  <br>
  <div class="row" *ngIf="viewAvviso && avviso.dettagli">
      <div class="col-12">
        <nz-table #basicTable [nzData]="avviso.dettagli">
          <thead>
            <tr>
              <th>Ente</th>
              <th>Sede Ente</th>
              <th>Anno</th>
              <th>Importo</th>
              <th>Note</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of avviso.dettagli">
              <td>{{ item.nomeEnte }}</td>
              <td>{{ item.sedeEnte }}</td>
              <td>{{ item.riferimentoAnno }}</td>
              <td>{{ item.importo }}</td>
              <td>{{ item.note }}</td>
            </tr>
          </tbody>
        </nz-table>
      </div>
  </div>
 

<br>
    <div class="row">
        <!-- <div class="col">
          Tipologia
          <nz-select [(ngModel)]="tipoScelta" (ngModelChange)="onChangeTipologia()" nzPlaceHolder="Seleziona Tipologia">
            <nz-option nzValue="Rateazione" nzLabel="Rateazioni"></nz-option>
            <nz-option nzValue="Adempimento" nzLabel="Adempimenti"></nz-option>
          </nz-select>
          </div> -->
          <div class="col">
            <!--
            Data Reso Esecutivo <br>
            <nz-date-picker [(ngModel)]="dataResoEsecutivo" nzFormat="dd/MM/yyyy" [disabled]="sceltaTipo" ></nz-date-picker>
            -->
          </div>
          <div class="col">
            
          </div>
          <div class="col">
            
          </div>
    </div>
    <br>
    <div class="row">
        <div class="col">
          Data Richiesta <br>
          <nz-date-picker [(ngModel)]="dataRichiesta" nzFormat="dd/MM/yyyy" [disabled]="sceltaTipo"></nz-date-picker>
        </div>
        <div class="col">
         
        </div>
        <div class="col" *ngIf="viewProtocollo">
        Data Protocollo Accettazione <br>
        <nz-date-picker [(ngModel)]="dataProtocollo" nzFormat="dd/MM/yyyy" [disabled]="sceltaTipo"></nz-date-picker>
        </div>
        <div class="col" *ngIf="!viewProtocollo">
          
        </div>
        <div class="col" *ngIf="viewProtocollo">
          Numero Protocollo
          <input nz-input placeholder="Numero Protocollo" [(ngModel)]="protocollo" [disabled]="sceltaTipo"/>
        </div>
        <div class="col" *ngIf="!viewProtocollo">
          
        </div>
        
    </div>
    <br>
    <div class="row">
        
        <div class="col">
          Importo Effettivo
          <input nz-input placeholder="Importo Effettivo" [(ngModel)]="importoEffettivo" [disabled]="sceltaTipo || (!viewAvviso && dettaglioRateazione)"/>
        </div>
        <div class="col">
          Importo Ipotetico
          <input nz-input placeholder="Importo Ipotetico" [(ngModel)]="importoIpotetico" [disabled]="sceltaTipo"/>
        </div>
        <div class="col">
        
        </div>
        <div class="col">
          Note
          <input nz-input placeholder="Note" [(ngModel)]="note" [disabled]="sceltaTipo"/>
        </div>
    </div>
    <br>
    <div class="row" *ngIf="viewProtocollo && viewNotRientro">
      <div class="col-3">
        Numero Ruolo
        <input nz-input placeholder="Numero Ruolo" [(ngModel)]="numeroRuolo" [disabled]="sceltaTipo"/>
        </div>
        <div class="col-3">
          Responsabile Procedimento
          <input nz-input placeholder="Responsabile Procedimento" [(ngModel)]="responsabileProcedimento" [disabled]="sceltaTipo"/>
        </div>
        <div class="col-6">

        </div>
  </div>
<br>
<div  class="row">
    <div class="col-12">
      <div>
        <div nz-row [nzGutter]="8">
          <div nz-col [nzSpan]="6">
            <nz-card nzTitle="Richieste">
              <p>Allega file
                <input id="file" type="file" [(ngModel)]="modelFileRichieste" (change)="handleFileInput($event, 'richiesta')" /></p>
                <br>
                <div class="row" *ngFor="let allegatoB of nuovaCartella.documentiRichiesta; let n=index">
                  <div class="col">
                      Nome allegato
                      <input nz-input placeholder="nota" [(ngModel)]="allegatoB.fileName" [disabled]="true" class="disabledBox" />
                  </div>
                  <div class="col">
                      <br>
                      <button nz-button nzType="primary" nzSize="small" style="background-color: brown; border-color: brown;" (click)="deleteAllegato(allegatoB.fileName, 'richieste')">
                        <i nz-icon nzType="minus" nzTheme="outline"></i>
                      </button>
                  </div>
                  <div class="col">
              
                  </div>
              </div>
            </nz-card>
          </div>
          <div nz-col [nzSpan]="6" *ngIf="visualizzaAllegati">
            <nz-card nzTitle="Notifiche">
              <p>Allega file
                <input id="file" type="file" [(ngModel)]="modelFileNotifica" (change)="handleFileInput($event, 'notifica')" /></p>
                <br>
                <div class="row" *ngFor="let allegato of nuovaCartella.documentiNotifica; let n=index">
                  <div class="col">
                      Nome allegato
                      <input nz-input [(ngModel)]="allegato.fileName" [disabled]="true" class="disabledBox" />
                  </div>
                  <div class="col">
                      <br>
                      <button nz-button nzType="primary" nzSize="small" style="background-color: brown; border-color: brown;" (click)="deleteAllegato(allegato.fileName, 'notifica')">
                        <i nz-icon nzType="minus" nzTheme="outline"></i>
                      </button>
                  </div>
                  <div class="col">
              
                  </div>
              </div>
            </nz-card>
          </div>
          <div nz-col [nzSpan]="6" *ngIf="visualizzaAllegati">
            <nz-card nzTitle="Rateazioni">
              <p>Allega file
                <input id="file" type="file" [(ngModel)]="modelFileRateazioni" (change)="handleFileInput($event, 'rateazione')" /></p>
                <br>
                <div class="row" *ngFor="let allegatoR of nuovaCartella.documentiRateazioni; let n=index">
                  <div class="col">
                      Nome allegato
                      <input nz-input placeholder="nota" [(ngModel)]="allegatoR.fileName" [disabled]="true" class="disabledBox" />
                  </div>
                  <div class="col">
                      <br>
                      <button nz-button nzType="primary" nzSize="small" style="background-color: brown; border-color: brown;" (click)="deleteAllegato(allegatoR.fileName, 'rateazioni')">
                        <i nz-icon nzType="minus" nzTheme="outline"></i>
                      </button>
                  </div>
                  <div class="col">
              
                  </div>
              </div>
            </nz-card>
          </div>
          <div nz-col [nzSpan]="6">
            <nz-card nzTitle="Bollettini" *ngIf="visualizzaAllegati">
              <p>Allega file
                <input id="file" type="file" [(ngModel)]="modelFileBollettini" (change)="handleFileInput($event, 'bollettini')" /></p>
                <br>
                <div class="row" *ngFor="let allegatoB of nuovaCartella.bollettini; let n=index">
                  <div class="col">
                      Nome allegato
                      <input nz-input placeholder="nota" [(ngModel)]="allegatoB.fileName" [disabled]="true" class="disabledBox" />
                  </div>
                  <div class="col">
                      <br>
                      <button nz-button nzType="primary" nzSize="small" style="background-color: brown; border-color: brown;" (click)="deleteAllegato(allegatoB.fileName, 'bollettini')">
                        <i nz-icon nzType="minus" nzTheme="outline"></i>
                      </button>
                  </div>
                  <div class="col">
              
                  </div>
              </div>
            </nz-card>
          </div>
        </div>
      </div>
    </div>
</div>
<br>
<div class="row">
  <div class="col">
      <button nz-button nzSize="large" nzType="primary" class="mt-3" (click)="save()">
          Salva
        </button> 
    </div>
    <div class="col">
    </div>
    <div class="col">
    </div>
    <div class="col">
    </div>
</div>





<!-- modale dettaglio rateazione-->
<ng-container>
  <nz-modal [(nzVisible)]="isVisibleEdit" nzTitle="Dettaglio Rateazione" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
      <ng-container *nzModalContent>
          <div class="row">
            <div class="col">
              ente
              <nz-select [(ngModel)]="selectedEnteModal" [nzAllowClear]="true" nzPlaceHolder="Seleziona Ente">
                <nz-option *ngFor="let ente of enti" [nzValue]="ente.code" [nzLabel]="ente.name"></nz-option>
              </nz-select>
              </div>
              <div class="col">
                Sede ente
                <input nz-input placeholder="Sede Ente" [(ngModel)]="sedeEnteModal" />
              </div>
          </div>
          <div class="row">
              <div class="col">
                Anno
               <input nz-input placeholder="Anno Riferimento" [(ngModel)]="annoRifModal" />
              </div>
              <div class="col">
                  Importo
                  <nz-input-number-group nzPrefix="€" style="width: 100%">
                      <nz-input-number nzPlaceHolder="Importo" [(ngModel)]="importoModal" [nzPrecision]="2" nzPrecisionMode="toFixed"></nz-input-number>
                  </nz-input-number-group>
              </div>
          </div>
          <div class="row">
            <div class="col-6">
               note
               <input nz-input placeholder="Note" [(ngModel)]="NoteModal" />
              </div>
              <div class="col-6">
              </div>
          </div>
      </ng-container>
  </nz-modal>
</ng-container>
