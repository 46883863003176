<app-loader *ngIf="loading"></app-loader>
<nz-affix *ngIf="!loading" [nzOffsetTop]="60">
  <div class="row titleFilterHeader">
    <div class="col-12">
      <!--Page header: title, back, others actions-->
      <nz-page-header
        class="site-page-header px-2"
        (click)="onBack()"
        nzBackIcon
        nzTitle="Clienti"
      >
        <nz-page-header-extra>
          <button nz-button nzType="primary" nzSize="large" [routerLink]="['/amministrazione/inserisci-cliente']">
            <i nz-icon nzType="plus" nzTheme="outline"></i>
            Nuovo cliente
          </button>
        </nz-page-header-extra>
      </nz-page-header>
    </div>
    
    <div class="col-12 mb-3">
      <!--Filters-->
      <nz-collapse nzGhost class="filtersPanel">
        <nz-collapse-panel [nzExpandedIcon]="expandedIcon">
          <ng-template #expandedIcon let-active>
            <button
                            nz-button
                            nzType="primary"
                            nzSize="large"
                            class="filtersToggleButton"
                          >
                            <i nz-icon nzType="filter" nzTheme="outline"></i>
                            Filtra
                          </button>       
          </ng-template>

          <div class="row filters">
            <div class="col-6">
              <nz-radio-group [(ngModel)]="selectedActiveOpt" nzSize="large" nzButtonStyle="solid">
                <label nz-radio-button *ngFor="let opt of activeOpt" [nzValue]="opt.code">{{ opt.name }}</label>
              </nz-radio-group>
            </div>
            <div class="col-6">
              <nz-input-group [nzSuffix]="suffixIconSearch" nzSize="large" class="mb-3">
                <input type="text" nz-input nzSize="large" placeholder="Cerca, inserisci Nome Azienda, Codice, partita Iva, etc." />
              </nz-input-group>
              <ng-template #suffixIconSearch>
                <i nz-icon nzType="search"></i>
              </ng-template>
            </div>
            <div class="col">
              <nz-select [(ngModel)]="selectedAgent" nzSize="large" (ngModelChange)="updateList()" nzPlaceHolder="Agente">
                <nz-option *ngFor="let ag of agents" [nzValue]="ag" [nzLabel]="ag.name"></nz-option>
              </nz-select>
            </div>
            <div class="col">
              <nz-select [(ngModel)]="selectedCity" nzSize="large" (ngModelChange)="updateList()" nzPlaceHolder="Città">
                <nz-option *ngFor="let c of cities" [nzValue]="c" [nzLabel]="c.name"></nz-option>
              </nz-select>
            </div>
            <div class="col">
              <nz-select [(ngModel)]="selectedProvince" nzSize="large" (ngModelChange)="updateList()" nzPlaceHolder="Provincia">
                <nz-option *ngFor="let p of provinces" [nzValue]="p" [nzLabel]="p.name"></nz-option>
              </nz-select>
            </div>
            <div class="col">
              <nz-select [(ngModel)]="selectedRegion" nzSize="large" (ngModelChange)="updateList()" nzPlaceHolder="Regione">
                <nz-option *ngFor="let r of regions" [nzValue]="r" [nzLabel]="r.name"></nz-option>
              </nz-select>
            </div>
          </div>
        </nz-collapse-panel>
      </nz-collapse> 
    </div>
  </div>
</nz-affix>
<div *ngIf="!loading" class="row customersList">
    <div class="col-12">
      <nz-table #customersList *ngIf="!loading" nzSize="middle" [nzData]="customers" class="dataView">
          <thead>
          </thead>
          <tbody>
            <tr *ngFor="let item of customersList.data">
              <td class="id text-center">{{ item.codice }}</td>
              <td>
                <div class="d-flex name">
                  {{ item.label }}
                </div>
                <div class="d-flex flex-column address">
                  <div class="d-block">{{item.indirizzo | lowercase }},&nbsp;{{item.cap}} {{item.comune | lowercase}},&nbsp;{{item.provincia | uppercase}}</div>
                  <div class="d-block">{{item.regione}}</div>
                  <div *ngIf="item.countSede > 1" class="selling-point">
                    <span class="number">{{item.countSede}}</span>
                    <span>punti vendita</span>
                  </div>
                </div>
              </td>
              <td>
                <div class="d-block" *ngIf="item.ultimoOrdine != null">
                  <span class="d-block">Ultimo ordine</span>
                  {{item.ultimoOrdine}}
                </div>
              </td>
              <td>
                <div>{{item.nominativoAgente}}</div>
              </td>
            </tr>
          </tbody>
        </nz-table>
    </div>
</div>
