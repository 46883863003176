import { Router } from "@angular/router";
import { Component, OnInit, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Location } from "@angular/common";
import { Save } from "src/app/models/entiModel";
import { EntiService } from "src/app/_services/enti.service";

@Component({
  selector: "app-nuovo-ente",
  templateUrl: "./nuovo-ente.component.html",
  styleUrls: ["./nuovo-ente.component.scss"],
})
export class NuovoEnteComponent implements OnInit {
  nuovoEnteForm!: FormGroup;
  location!: Location;
  isDisabled: boolean = false;
  submitForm(): void {
    if (this.nuovoEnteForm.valid) {
      this.save();
    } else {
      Object.values(this.nuovoEnteForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  constructor(
    private fb: FormBuilder,
    private entiService: EntiService,
    private router: Router
  ) {}
  public params: Save = {
    codice: "",
    idEnte: null,
    indirizzo: "",
    nome: "",
    sede: "",
    aggiuntivi: false,
  };
  ngOnInit(): void {
    
    if(history.state.codice){
      this.isDisabled=true;
    }
    this.nuovoEnteForm = this.fb.group({
      formLayout: ["horizontal"],
      fieldA: [{value: history.state.codice, disabled: this.isDisabled} ,  [Validators.required]],
      fieldB: [history.state.nome, [Validators.required]],
      fieldC: [history.state.sede, [Validators.required]],
      fieldD: [history.state.indirizzo],
      fieldE: [history.state.aggiuntivi],
    });
  }

  save() {
    if(this.isDisabled){
      this.params.codice = history.state.codice;
    }
    else{
    this.params.codice = this.nuovoEnteForm.value.fieldA;
    }
    this.params.nome = this.nuovoEnteForm.value.fieldB;
    this.params.sede = this.nuovoEnteForm.value.fieldC;
    this.params.indirizzo = this.nuovoEnteForm.value.fieldD;
    this.params.aggiuntivi = this.nuovoEnteForm.value.fieldE;
    if(this.isDisabled){
    this.params.idEnte = history.state.idEnte;
    }
    else this.params.idEnte = null;
    this.entiService.getEnteSave(this.params).subscribe(
      (data) => {
        if (data) {
          console.log("id:", data.id, " msg:", data.message);
          this.router.navigate(["enti"]);
        }
      },
      (err) => console.error(err),
      () => {
        //this.loading = false;
      }
    );
  }

  onBack() {
    this.location.back();
  }
}
