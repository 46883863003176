import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { JwtInterceptor } from './_helpers/jwt.interceptor'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/commons/header/header.component';
import { NavComponent } from './components/commons/nav/nav.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { NewCustomerComponent } from './components/customers/new-customer/new-customer.component';
import { UserService } from './_services/user.service';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { CustomersListComponent } from './components/customers/customers-list/customers-list.component';
import { LoaderComponent } from './components/commons/loader/loader.component';

//ZORRO
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule, NzTableLayout, NzTablePaginationPosition, NzTablePaginationType, NzTableSize } from 'ng-zorro-antd/table';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';


//FULLCALENDAR
import { FullCalendarModule } from '@fullcalendar/angular';

//ng2-charts 3.1.2
import { NgChartsModule } from 'ng2-charts';


//LOCALE
import { registerLocaleData } from '@angular/common';
import it from '@angular/common/locales/it';
registerLocaleData(it);
import { NZ_I18N, it_IT } from 'ng-zorro-antd/i18n';
import { CalendarPagamentiComponent } from './components/userwidgets/calendar-pagamenti/calendar-pagamenti.component';
import { CartelleRateizzateComponent } from './components/userwidgets/cartelle-rateizzate/cartelle-rateizzate.component';
import { CartelleNonRateizzateComponent } from './components/userwidgets/cartelle-non-rateizzate/cartelle-non-rateizzate.component';
import { ListaRateComponent } from './components/userwidgets/lista-rate/lista-rate.component';
import { RatePrioritarieComponent } from './components/userwidgets/rate-prioritarie/rate-prioritarie.component';
import { ListaEntiComponent } from './components/enti/lista-enti/lista-enti.component';
import { NuovoEnteComponent } from './components/enti/nuovo-ente/nuovo-ente.component';
import { ElencoRateazioneComponent } from './components/rateazioni/elenco-rateazione/elenco-rateazione.component';
import { DettaglioRateazioneComponent } from './components/rateazioni/dettaglio-rateazione/dettaglio-rateazione.component';
import { RateizzateComponent } from './components/cartelle/elenco/rateizzate/rateizzate.component';
import { NonRateizzateComponent } from './components/cartelle/elenco/non-rateizzate/non-rateizzate.component';
import { ElencoComponent } from './components/cartelle/elenco/elenco.component';
import { NuovaCartellaComponent } from './components/cartelle/nuova-cartella/nuova-cartella.component';
import { NuovaRateazioneComponent } from './components/rateazioni/nuova-rateazione/nuova-rateazione.component';
import { ListaAdempimentiComponent } from './components/adempimenti/lista-adempimenti/lista-adempimenti.component';
import { NuovoAdempimentoComponent } from './components/adempimenti/nuovo-adempimento/nuovo-adempimento.component';
import { AdempimentiService } from './_services/adempimenti.service';
import { DettaglioAdempimentoComponent } from './components/cartelle/dettaglio/dettaglio.component';
import { CalendarioComponent } from './components/calendario/calendario.component';
import { AvvisiComponent } from './components/avvisi/avvisi.component';
import { NuovoAvvisoComponent } from './components/avvisi/nuovo-avviso/nuovo-avviso.component';
import { DettaglioAvvisoComponent } from './components/avvisi/dettaglio-avviso/dettaglio-avviso.component';
import { BudgetComponent } from './components/budget/budget.component';
import { NuovoBudgetComponent } from './components/budget/nuovo-budget/nuovo-budget.component';
import { DettaglioBudgetComponent } from './components/budget/dettaglio-budget/dettaglio-budget.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key])

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    HeaderComponent,
    NavComponent,
    CustomersListComponent,
    LoaderComponent,
    NewCustomerComponent,
    CalendarPagamentiComponent,
    CartelleRateizzateComponent,
    CartelleNonRateizzateComponent,
    ListaRateComponent,
    RatePrioritarieComponent,
    ListaEntiComponent,
    NuovoEnteComponent,
    ElencoRateazioneComponent,
    DettaglioRateazioneComponent,
    RateizzateComponent,
    DettaglioAdempimentoComponent,
    NonRateizzateComponent,
    ElencoComponent,
    NuovaCartellaComponent,
    NuovaRateazioneComponent,
    ListaAdempimentiComponent,
    NuovoAdempimentoComponent,
    CalendarioComponent,
    AvvisiComponent,
    NuovoAvvisoComponent,
    DettaglioAvvisoComponent,
    BudgetComponent,
    NuovoBudgetComponent,
    DettaglioBudgetComponent,
    DashboardComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule ,
    ReactiveFormsModule,
    FormsModule,
    NzFormModule,
    NzSelectModule,
    NzCollapseModule,
    NzInputModule,
    NzButtonModule,
    NzTableModule,
    NzTypographyModule,
    NzIconModule,
    FullCalendarModule ,
    NzModalModule,
	  NzAlertModule,
    NzNotificationModule,
    NzMessageModule,
    NzCheckboxModule,
    NzTimePickerModule,
    NzSpinModule,
    NzPageHeaderModule,
    NzRadioModule,
    NzAffixModule,
    NzDatePickerModule,
    NzDescriptionsModule,
    NzDividerModule,
    NzListModule,
    NzCardModule,
    NzInputNumberModule,
    NzCalendarModule,
    NgChartsModule
  ],
  providers: [
    NzMessageService,
    UserService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: NZ_I18N, useValue: it_IT },
    { provide: NZ_ICONS, useValue: icons },
    DatePipe,
    AdempimentiService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
